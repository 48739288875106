import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { ProgramsState } from '../state/programs-state';

export const programsReducer: AppReducer<'Programs'> = (state = initialState.Programs, action): typeof state => {
  switch (action.type) {
    case 'Programs/loaded':
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        programs: action.payload.programs.reduce<ProgramsState['programs']>((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}),
      };
    case 'Programs/loading':
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};
