/*
CAMPAIGN CREATOR
 */

export enum MailDeliveryType {
  SCHEDULE = 'schedule',
  DELAY = 'delay',
}

export enum ProspectCountStepType {
  CALCULATING = 'calculating',
  INSTRUCTIONS = 'instructions',
  SELECT = 'select',
}

export enum MapInstructionsStepType {
  SELECT = 'select',
  ADJUST = 'adjust',
}
