import { AddressSuggestion, AddressResponse } from '../actions/address-lookup-actions';

export type AddressLookupState = {
  search: {
    suggestions: { [search: string]: AddressSuggestion[] };
    currentSearch: string;
    isLoaded: boolean;
    isLoading: boolean;
  };
  lookup: {
    address: AddressResponse | null;
    isLoaded: boolean;
    isLoading: boolean;
  };
};

export const initialAddressLookupState: AddressLookupState = {
  search: {
    isLoaded: false,
    isLoading: false,
    suggestions: {},
    currentSearch: 'no-cache',
  },
  lookup: {
    address: null,
    isLoaded: false,
    isLoading: false,
  },
};
