import { useCallback, useEffect } from 'react';
import { ActivityFeed, Drawer, ModalHeaderToolbar } from '@platform/ui';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppActions } from '../../redux/actions/app-actions';
import { PortalAPI } from '@platform/api';
import { getProspectActivity } from '../../redux/thunks';

const ActivityFeedModal = ({ getAccessTokenSilently }: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const modalProps = useSelector(Selectors.getActivityFeedModal);
  const dispatch = useTypedDispatch();
  const { data: activity, isLoading } = useSelector(Selectors.prospectActivity(modalProps?.externalTrackingId ?? ''));

  useEffect(() => {
    if (modalProps?.externalTrackingId) {
      dispatch(
        getProspectActivity(getAccessTokenSilently, {
          externalId: modalProps.externalTrackingId,
        })
      );
    }
  }, [modalProps?.externalTrackingId, dispatch, getAccessTokenSilently]);

  const onClose = useCallback(() => {
    dispatch(AppActions.setActivityFeedModal(null));
  }, [dispatch]);

  const onOpenActivityDetailModal = useCallback(
    (payload: PortalAPI.ProspectAPI.ActivityData<PortalAPI.ProspectAPI.ActivityDataType>) => {
      if (modalProps?.externalTrackingId) {
        dispatch(
          AppActions.setActivityDetailsModal({
            ...payload,
            prospectId: modalProps.externalTrackingId,
          })
        );
      }
    },
    [dispatch, modalProps?.externalTrackingId]
  );

  const onAddNote = useCallback(() => {
    dispatch(
      AppActions.setProspectsNotesModal({
        prospectId: modalProps?.externalTrackingId ?? '',
        content: '',
      })
    );
  }, [dispatch, modalProps?.externalTrackingId]);

  return (
    <Drawer isOpen={!!modalProps} onClose={onClose}>
      <ModalHeaderToolbar onBack={onClose} title="Activity Feed" isDisabled={false} isLoading={false} hasShadow />
      <div className="flex flex-col p-6 bg-white">
        <ActivityFeed
          isPortal
          onAddNote={onAddNote}
          data={activity}
          onOpenDetail={onOpenActivityDetailModal}
          isLoading={isLoading}
        />
      </div>
    </Drawer>
  );
};

export default ActivityFeedModal;
