import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { loadBirdseyeCompanies } from '../../redux/thunks';
import { GetAccessTokenFunction } from '../../types/auth0';
import { CompanySelection } from './CompanySelect';

export const CompanySelectList = (props: {
  companyName?: string;
  externalId?: string;
  getAccessToken: GetAccessTokenFunction;
}) => {
  const [query, setQuery] = useState('');
  const dispatch = useTypedDispatch();
  const companyList = useSelector(Selectors.birdseyeCompanies);

  useEffect(() => {
    if (!companyList.loading && !companyList.loaded) {
      dispatch(loadBirdseyeCompanies(props.getAccessToken));
    }
  }, [companyList.loaded, companyList.loading, dispatch, props.getAccessToken]);

  const filteredCompanies =
    query === ''
      ? companyList.list
      : companyList.list.filter(({ name }) =>
          name
            .toLowerCase()
            .replace(/[^A-Za-z0-9]+/g, '')
            .includes(query.toLowerCase().replace(/[^A-Za-z0-9]+/g, ''))
        );

  return (
    <CompanySelection
      companyName={props.companyName}
      externalId={props.externalId}
      onSearch={setQuery}
      companyList={filteredCompanies}
      searchText={query}
      isSearching={companyList.loading}
    />
  );
};
