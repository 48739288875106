import { useCallback, useEffect, useState } from 'react';
import { ContactForm, ModalHeaderToolbar } from '@platform/ui';
import { PortalAPI } from '@platform/api';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppActions } from '../../redux/actions/app-actions';
import { updateProspectContact } from '../../redux/thunks';

export const ProspectContactModal = ({
  getAccessTokenSilently,
  prospectId,
}: {
  getAccessTokenSilently: GetAccessTokenFunction;
  prospectId: string;
}) => {
  const prospect = useSelector(Selectors.prospectById(prospectId));
  const dispatch = useTypedDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, control, reset } = useForm<PortalAPI.ProspectAPI.UpdateProspectContactRequest>({
    defaultValues: { emailAddress: '', name: '', phoneNumber: '', secondaryPhoneNumber: '' },
  });

  const onClose = useCallback(() => {
    // Close the modal
    dispatch(AppActions.setProspectsContactModal(null));
  }, [dispatch]);

  const onSave = useCallback(
    async (values: PortalAPI.ProspectAPI.UpdateProspectContactRequest) => {
      if (prospectId) {
        setIsSubmitting(true);
        try {
          await dispatch(
            updateProspectContact(getAccessTokenSilently, {
              externalId: prospectId,
              ...values,
            })
          );

          onClose();
        } catch {
          console.error('error updating contact');
        } finally {
          setIsSubmitting(false);
        }
      }
    },
    [dispatch, getAccessTokenSilently, onClose, prospectId]
  );

  const onDelete = useCallback(async () => {
    // Clear the form
    reset({
      emailAddress: '',
      name: '',
      phoneNumber: '',
      secondaryPhoneNumber: '',
    });
    // Submit the form with empty values to delete the contact
    handleSubmit(onSave)();
  }, [reset, handleSubmit, onSave]);

  useEffect(() => {
    if (prospect) {
      reset({
        name: prospect.name ?? undefined,
        emailAddress: prospect.emailAddress ?? undefined,
        phoneNumber: prospect.phoneNumber ?? undefined,
        secondaryPhoneNumber: prospect.secondaryPhoneNumber ?? undefined,
      });
    }
  }, [prospect, reset]);

  return (
    <>
      <ModalHeaderToolbar
        onBack={onClose}
        onSave={handleSubmit(onSave)}
        title="Edit Contact"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        hasShadow
      />
      <div className="flex flex-col pt-6 bg-white">
        <ContactForm
          onDelete={onDelete}
          register={register}
          isSubmitting={isSubmitting}
          control={control}
          hasPaddingTop={false}
        />
      </div>
    </>
  );
};
