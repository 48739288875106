"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
exports.__esModule = true;
exports.Client = exports.UserAPI = exports.TagAPI = exports.SurveyAPI = exports.ReportAPI = exports.ProspectAPI = exports.ProgramAPI = exports.LeadStatusAPI = exports.DashboardAPI = exports.CompanyAPI = exports.CampaignAPI = exports.AuthAPI = exports.AddressAPI = void 0;
exports.AddressAPI = __importStar(require("./address-api.types"));
exports.AuthAPI = __importStar(require("./auth-api.types"));
exports.CampaignAPI = __importStar(require("./campaign-api.types"));
exports.CompanyAPI = __importStar(require("./company-api.types"));
exports.DashboardAPI = __importStar(require("./dashboard-api.types"));
exports.LeadStatusAPI = __importStar(require("./lead-status-api.types"));
exports.ProgramAPI = __importStar(require("./program-api.types"));
exports.ProspectAPI = __importStar(require("./prospect-api.types"));
exports.ReportAPI = __importStar(require("./report-api.types"));
exports.SurveyAPI = __importStar(require("./survey-api.types"));
exports.TagAPI = __importStar(require("./tag-api.types"));
exports.UserAPI = __importStar(require("./user-api.types"));
__exportStar(require("./portal-api.types"), exports);
exports.Client = __importStar(require("./client"));
