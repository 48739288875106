import { useCallback, useEffect } from 'react';
import { batch, useSelector } from 'react-redux';
import { AddressLookupActions, AddressResponse, AddressSuggestion } from '../../redux/actions/address-lookup-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { lookupStreetAddress } from '../../redux/thunks';
import { GetAccessTokenFunction } from '../../types/auth0';
import { Actions } from '../../redux/actions';

export type AddressLookupDataProps = {
  getAccessToken: GetAccessTokenFunction;
};

export type InjectedProps = {
  loading: boolean;
  loaded: boolean;
  address: AddressResponse;
  onSearch: (address: AddressSuggestion) => void;
  onClear: () => void;
  onStartCampaign: () => void;
};

export const withAddressResult = <T extends InjectedProps & AddressLookupDataProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  const Page: React.FunctionComponent<Omit<T, keyof InjectedProps>> = (props) => {
    const dispatch = useTypedDispatch();

    const { address, isLoaded, isLoading } = useSelector(Selectors.addressLookup);
    const onSearch = useCallback(
      (address: AddressSuggestion) => {
        batch(() => {
          dispatch(lookupStreetAddress(address, props.getAccessToken));
          dispatch(AddressLookupActions.clearSearchSuggestions());
        });
      },
      [dispatch, props.getAccessToken]
    );

    const onClear = useCallback(() => {
      batch(() => {
        dispatch(AddressLookupActions.clearSearchSuggestions());
        dispatch(AddressLookupActions.clearLookup());
        dispatch(Actions.CampaignCreator.setClearForm());
      });
    }, [dispatch]);

    // Clear out Redux state on unmount
    useEffect(() => onClear, [onClear]);

    return (
      <WrappedComponent
        {...(props as T)}
        loading={isLoading}
        loaded={isLoaded}
        address={address}
        onSearch={onSearch}
        onClear={onClear}
      />
    );
  };

  return Page;
};
