import { throttle } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { AddressSuggestion } from '../../../redux/actions/address-lookup-actions';
import { Selectors } from '../../../redux/selectors';
import { useTypedDispatch } from '../../../redux/state';
import { searchAddress } from '../../../redux/thunks';

export type AddressSearchBarDataProps = {
  onSelected: (address: AddressSuggestion) => void;
};

type InjectedProps = {
  loading: boolean;
  suggestions: AddressSuggestion[];
  onInputChanged: (input: string) => void;
};

export const withAddressSearch = <T extends InjectedProps & AddressSearchBarDataProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  const Page: React.FunctionComponent<Omit<T, keyof InjectedProps>> = (props) => {
    const { isLoading, suggestions } = useSelector(Selectors.addressSuggestionsForCurrentSearch);
    const dispatch = useTypedDispatch();

    const onInputChanged = React.useMemo(
      () =>
        throttle(
          (input: string) => {
            if (input && input.length > 2) {
              dispatch(searchAddress(input.trim()));
            }
          },
          1000,
          { leading: false }
        ),
      [dispatch]
    );

    React.useEffect(() => {
      return () => {
        onInputChanged.cancel();
      };
    }, [onInputChanged]);

    return (
      <WrappedComponent
        {...(props as T)}
        loading={isLoading}
        suggestions={suggestions}
        onInputChanged={onInputChanged}
      />
    );
  };

  return Page;
};
