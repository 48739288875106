import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';

export const userReducer: AppReducer<'User'> = (state = initialState.User, action): typeof state => {
  switch (action.type) {
    case 'User/loaded':
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        user: {
          ...action.payload,
          isSuperAdmin: !!action.payload.isSuperAdmin,
        },
      };
    case 'User/loading':
      return {
        ...state,
        isLoading: true,
        user: null,
      };
    case 'User/update':
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              ...action.payload,
            }
          : state.user,
      };
    case 'Company/addAssociation':
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              permissions: {
                ...state.user.permissions,
                canManageMultiple: true,
              },
            }
          : state.user,
      };
    case 'Team/transfer-ownership':
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              role: 'admin',
            }
          : state.user,
      };
    default:
      return state;
  }
};
