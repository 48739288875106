"use strict";
exports.__esModule = true;
exports.getAddressString = exports.getAddressLine2 = exports.getAddressLine1 = void 0;
var getAddressLine1 = function (address) {
    return address.address2 ? "".concat(address.address1, ", ").concat(address.address2) : address.address1;
};
exports.getAddressLine1 = getAddressLine1;
var getAddressLine2 = function (address) {
    return "".concat(address.city, ", ").concat(address.state, " ").concat(address.zip);
};
exports.getAddressLine2 = getAddressLine2;
var getAddressString = function (address) {
    return "".concat((0, exports.getAddressLine1)(address), ", ").concat((0, exports.getAddressLine2)(address));
};
exports.getAddressString = getAddressString;
