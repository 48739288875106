import { useCallback, useState } from 'react';
import { DialogModal, Button } from '@platform/ui';
import { GetAccessTokenFunction } from '../../types/auth0';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { AppActions } from '../../redux/actions/app-actions';
import { deleteTag } from '../../redux/thunks';

export const DeleteTagModal = ({ getAccessTokenSilently }: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const modalProps = useSelector(Selectors.getDeleteTagModal);
  const dispatch = useTypedDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const onClose = useCallback(() => {
    setIsDeleting(false);
    dispatch(AppActions.setDeleteTagModalOpen(null));
  }, [dispatch]);

  const onDelete = useCallback(async () => {
    if (modalProps?.externalId) {
      setIsDeleting(true);
      await dispatch(deleteTag(getAccessTokenSilently, { id: modalProps.externalId }));
      onClose();

      if (modalProps?.onCallback) {
        modalProps.onCallback();
      }
    }
  }, [dispatch, getAccessTokenSilently, onClose, modalProps]);

  return (
    <DialogModal isOpen={!!modalProps} onClose={onClose}>
      <div className="flex flex-col p-6 text-center">
        <h3 className="text-lg font-semibold text-gray-900 leading-6 mb-2">Are you sure?</h3>
        <p className="text-sm font-normal text-gray-600 leading-4 mb-6">
          Deleting a tag is permanent and cannot be reversed.
        </p>
        <Button
          text="Delete Tag"
          onClick={onDelete}
          variant="danger"
          roundedFull
          padding="py-2 px-3"
          isLoading={isDeleting}
          isDisabled={isDeleting}
        />
        <Button
          text="Cancel"
          onClick={onClose}
          variant="neutral"
          roundedFull
          padding="py-2 px-3"
          isDisabled={isDeleting}
        />
      </div>
    </DialogModal>
  );
};
