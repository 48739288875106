import { useCallback, useState } from 'react';
import { DialogModal, Button } from '@platform/ui';
import { GetAccessTokenFunction } from '../../types/auth0';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { AppActions } from '../../redux/actions/app-actions';
import { updateTag } from '../../redux/thunks';

export const HideTagModal = ({ getAccessTokenSilently }: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const modalProps = useSelector(Selectors.getHideTagModal);
  const dispatch = useTypedDispatch();
  const [isHiding, setIsHiding] = useState(false);

  const onClose = useCallback(() => {
    setIsHiding(false);
    dispatch(AppActions.setHideTagModalOpen(null));
    dispatch(AppActions.setEditTagModalOpen(null));
  }, [dispatch]);

  const onHide = useCallback(async () => {
    if (modalProps) {
      setIsHiding(true);
      await dispatch(
        updateTag(getAccessTokenSilently, {
          id: modalProps.externalId,
          isHidden: true,
        })
      );
      setIsHiding(false);
      onClose();
    }
  }, [dispatch, modalProps, getAccessTokenSilently, onClose]);

  return (
    <DialogModal isOpen={!!modalProps} onClose={onClose}>
      <div className="flex flex-col p-6 text-center">
        <h3 className="text-lg font-semibold text-gray-900 leading-6 mb-2">Hide this tag</h3>
        <p className="text-sm font-normal text-gray-600 leading-4 mb-6">
          This tag will be hidden and no longer available for use on prospects. You can always un-hide this tag later.
        </p>
        <Button
          text="Hide Tag"
          onClick={onHide}
          variant="primary"
          roundedFull
          padding="py-2 px-3"
          isLoading={isHiding}
          isDisabled={isHiding}
        />
        <Button
          text="Cancel"
          onClick={onClose}
          variant="neutral"
          roundedFull
          padding="py-2 px-3"
          isDisabled={isHiding}
        />
      </div>
    </DialogModal>
  );
};
