/* eslint-disable @typescript-eslint/no-redeclare */
import { ActionType } from '@platform/helpers';
import { PortalAPI } from '@platform/api';

export const TagsActions = {
  loaded: (payload: PortalAPI.TagAPI.TagWithProspectCount[]) =>
    ({
      type: 'Tags/loaded',
      payload,
    } as const),
  loading: () =>
    ({
      type: 'Tags/loading',
    } as const),
  create: (payload: { tag: PortalAPI.TagAPI.TagWithProspectCount }) =>
    ({
      type: 'Tags/create',
      payload,
    } as const),

  update: (payload: { externalId: string; isHidden?: boolean; value?: string }) =>
    ({
      type: 'Tags/update',
      payload,
    } as const),
  delete: (payload: { externalId: string }) =>
    ({
      type: 'Tags/delete',
      payload,
    } as const),

  clear: () =>
    ({
      type: 'Tags/clear',
    } as const),
};

export type TagsActions = ActionType<typeof TagsActions>;
