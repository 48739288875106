import { AppActions } from '../redux/actions/app-actions';
import { TypedDispatch } from '../redux/state';
import { updateMailStatus } from '../redux/thunks';
import { GetAccessTokenFunction } from '../types/auth0';
import { NotificationType } from '../types/notification';

export const stopMail = (dispatch: TypedDispatch, getAccessToken: GetAccessTokenFunction, prospectId: string) => {
  dispatch(updateMailStatus(getAccessToken, 'stop', prospectId));
  dispatch(
    AppActions.notification({
      title: 'Mail Stopped',
      type: NotificationType.info,
      description: 'Mail has been stopped for this prospect',
      placement: 'lower-right',
    })
  );
};

export const startMail = (dispatch: TypedDispatch, getAccessToken: GetAccessTokenFunction, prospectId: string) => {
  dispatch(updateMailStatus(getAccessToken, 'start', prospectId));
  dispatch(
    AppActions.notification({
      title: 'Mail Started',
      type: NotificationType.info,
      description: 'Mail has been started for this prospect',
      placement: 'lower-right',
    })
  );
};
