import { Button, DialogModal } from '@platform/ui';
import { PortalAPI } from '@platform/api';

type AddressConfirmationModalProps = {
  isOpen: boolean;
  suggested: PortalAPI.ProspectAPI.AddressValidateResponse['suggested'] | null;
  onClose: () => void;
  onUseSuggested: (suggested: PortalAPI.ProspectAPI.AddressValidateResponse['suggested']) => void;
  onUseProvided: () => void;
};
export const AddressConfirmationModal: React.FC<AddressConfirmationModalProps> = ({
  isOpen,
  suggested,
  onClose,
  onUseProvided,
  onUseSuggested,
}) => {
  return (
    <DialogModal isOpen={isOpen} onClose={onClose}>
      {!!suggested && (
        <div className="flex flex-col text-gray-900 p-4 text-center space-y-6">
          <div className="flex flex-col space-y-2">
            <h2 className="text-xl font-bold">
              Did you mean
              <br />
              this address?
            </h2>
            {suggested ? (
              <div className="flex flex-col">
                <p>{[suggested.address1, suggested.address2].filter((a) => !!a).join(', ')}</p>
                <p>
                  {suggested.city}, {suggested.state} {suggested.zip}
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="flex flex-col">
            <Button
              text="Yes, confirm this address"
              variant="primary"
              fullWidth
              roundedFull
              onClick={() => onUseSuggested(suggested)}
            />
            <Button
              text="No, use the address I provided"
              variant="neutral"
              fullWidth
              roundedFull
              onClick={onUseProvided}
            />
          </div>
        </div>
      )}
    </DialogModal>
  );
};
