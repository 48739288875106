"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.DeprecatedRouteDefinitions = exports.RouteDefinitions = exports.DeprecatedAPI = exports.API = void 0;
var helpers_1 = require("@platform/helpers");
var API;
(function (API) {
    API["GET /campaigns/list"] = "GET /campaigns/list";
    API["GET /campaigns/map"] = "GET /campaigns/map";
    API["POST /campaigns"] = "POST /campaigns";
    API["PATCH /campaigns/:id"] = "PATCH /campaigns/:id";
    API["POST /campaigns/prospect-count"] = "POST /campaigns/prospect-count";
    API["GET /v2/campaigns/:id"] = "GET /v2/campaigns/:id";
    API["GET /campaigns/:id/report"] = "GET /campaigns/:id/report";
})(API = exports.API || (exports.API = {}));
var DeprecatedAPI;
(function (DeprecatedAPI) {
})(DeprecatedAPI = exports.DeprecatedAPI || (exports.DeprecatedAPI = {}));
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.authenticatedRoute)(API['POST /campaigns'])), (0, helpers_1.authenticatedRoute)(API['PATCH /campaigns/:id'])), (0, helpers_1.authenticatedRoute)(API['POST /campaigns/prospect-count'])), (0, helpers_1.authenticatedRoute)(API['GET /campaigns/list'])), (0, helpers_1.authenticatedRoute)(API['GET /v2/campaigns/:id'])), (0, helpers_1.authenticatedRoute)(API['GET /campaigns/map'])), (0, helpers_1.authenticatedRoute)(API['GET /campaigns/:id/report']));
exports.DeprecatedRouteDefinitions = {};
