"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.BaseApiClient = exports.getUnauthenticatedApiClient = exports.getApiClient = void 0;
var helpers_1 = require("@platform/helpers");
var app_config_1 = require("@platform/app-config");
var client;
var unauthenticatedClient;
var getApiClient = function (getAccessToken) {
    client !== null && client !== void 0 ? client : (client = new ApiClient(getAccessToken, process.env.REACT_APP_API_URL));
    return client;
};
exports.getApiClient = getApiClient;
var getUnauthenticatedApiClient = function () {
    unauthenticatedClient !== null && unauthenticatedClient !== void 0 ? unauthenticatedClient : (unauthenticatedClient = new BaseApiClient(process.env.REACT_APP_API_URL));
    return unauthenticatedClient;
};
exports.getUnauthenticatedApiClient = getUnauthenticatedApiClient;
var BaseApiClient = /** @class */ (function (_super) {
    __extends(BaseApiClient, _super);
    function BaseApiClient(url) {
        var _this = _super.call(this, url, 60000) || this;
        _this.url = url;
        _this.request = function (params, data) {
            var _a;
            var _b, _c;
            if (data === void 0) { data = null; }
            var action = params.route.split(' ')[0];
            var url = (0, helpers_1.buildAPIUrl)(_this.url, params);
            var config = { headers: __assign({}, (params.headers ? params.headers : {})) };
            var impersonationHeader = (_c = (_b = window === null || window === void 0 ? void 0 : window.sessionStorage) === null || _b === void 0 ? void 0 : _b.getItem(app_config_1.Constants.IMPERSONATE_COMPANY_ID_HEADER)) !== null && _c !== void 0 ? _c : null;
            if (impersonationHeader) {
                config.headers = __assign(__assign({}, config.headers), (_a = {}, _a[app_config_1.Constants.IMPERSONATE_COMPANY_ID_HEADER] = JSON.parse(impersonationHeader).id, _a));
            }
            switch (action) {
                case 'GET':
                    return _this.instance.get(url, config);
                case 'POST':
                    return _this.instance.post(url, data, config);
                case 'DELETE':
                    return _this.instance["delete"](url, config);
                case 'PATCH':
                    return _this.instance.patch(url, data, config);
                default:
                    (0, helpers_1.assertUnreachable)(action);
            }
        };
        return _this;
    }
    return BaseApiClient;
}(helpers_1.HttpClient));
exports.BaseApiClient = BaseApiClient;
var ApiClient = /** @class */ (function (_super) {
    __extends(ApiClient, _super);
    function ApiClient(getToken, url) {
        var _this = _super.call(this, url) || this;
        _this.getToken = getToken;
        _this.addAuth = function (config) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c;
            var _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _a = [__assign({}, config)];
                        _d = {};
                        _b = [__assign({}, config.headers)];
                        _e = {};
                        _c = "Bearer ".concat;
                        return [4 /*yield*/, this.getToken()];
                    case 1: return [2 /*return*/, __assign.apply(void 0, _a.concat([(_d.headers = __assign.apply(void 0, _b.concat([(_e.Authorization = _c.apply("Bearer ", [_f.sent()]), _e)])), _d)]))];
                }
            });
        }); };
        _this.instance.interceptors.request.use(_this.addAuth, _this.handleError);
        return _this;
    }
    return ApiClient;
}(BaseApiClient));
