import { useCallback, useMemo, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Button, LeadStatusDetail } from '@platform/ui';
import { format } from 'date-fns';
import { PortalAPI } from '@platform/api';
import { DetailModalProps } from './types';
import { DetailModal } from './modal';
import { ensureDate, getAddressLine1, getAddressLine2, calculateDistance, DistanceUnits } from '@platform/helpers';
import { useTypedDispatch } from '../../redux/state';
import { deleteProspectLeadStatus } from '../../redux/thunks';

export const LeadStatusActivityDetailsModal: React.FC<
  DetailModalProps<PortalAPI.ProspectAPI.ActivityDataType.LEAD_STATUS>
> = ({ activity, prospect, user, safeAreaTop, onClose, getAccessTokenSilently }) => {
  const dispatch = useTypedDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const proximity = useMemo(() => {
    const { coordinates: to = [] } = activity.details.userLocation || {};
    const from = prospect?.location.coordinates ?? [];
    const distance = calculateDistance(from, to);
    return distance !== DistanceUnits.UNKNOWN ? `${distance} meters` : 'Unknown';
  }, [activity.details.userLocation, prospect?.location.coordinates]);

  const allowDelete = useMemo(
    () => user?.id === activity.details?.createdByUserId || user?.role === 'owner' || user?.isSuperAdmin,
    [user, activity]
  );

  const handleDelete = useCallback(async () => {
    try {
      if (prospect?.externalTrackingId) {
        setIsDeleting(true);
        await dispatch(
          deleteProspectLeadStatus(
            getAccessTokenSilently,
            activity.details.prospectLeadStatusId,
            prospect.externalTrackingId
          )
        );
      }
    } finally {
      setIsDeleting(false);
      onClose();
    }
  }, [activity.details.prospectLeadStatusId, dispatch, getAccessTokenSilently, onClose, prospect?.externalTrackingId]);

  return (
    <DetailModal
      isOpen
      headerProps={{
        safeAreaTop,
        onBack: onClose,
        title: 'Status Details',
        isDisabled: false,
        isLoading: false,
      }}
      onClose={onClose}
    >
      <div className="flex flex-col grow w-full space-y-5">
        <LeadStatusDetail
          addressLine1={prospect?.address ? getAddressLine1(prospect.address) : ''}
          addressLine2={prospect?.address ? getAddressLine2(prospect.address) : ''}
          status={activity.details.value}
          icon={activity.details.icon}
          userName={activity.details.userName ?? ''}
          timestamp={format(ensureDate(activity.timestamp), "MMM d, yyyy 'at' h:mmaaa")}
          proximity={proximity}
        />
        {allowDelete && (
          <Button
            onClick={handleDelete}
            icon={<TrashIcon className="h-5 w-5" aria-hidden="true" />}
            text="Delete Status"
            variant="dangerOutlined"
            isLoading={isDeleting}
            isDisabled={isDeleting}
          />
        )}
      </div>
    </DetailModal>
  );
};
