import { PlusIcon } from '@heroicons/react/24/solid';
import { Portal } from '@platform/ui-helpers';
import { Link } from 'react-router-dom';

export const EmptyState = () => (
  <div className="flex flex-col max-w-6xl mx-auto px-4 lg:px-8 pt-5">
    <div className="flex items-center justify-center md:shadow md:bg-white rounded-lg px-6 py-12 sm:p-12 bg-gradient-to-br from-blue-500 to-purple-500">
      <div className="w-full items-center text-center">
        <h1 className="text-center font-black text-3xl md:text-4xl text-white">Let's get started!</h1>
        <p className="text-center font-normal text-md md:text-lg my-4 text-indigo-100">
          Add an address to trigger a campaign.
        </p>
        <div className="mt-12">
          <Link to={Portal.getRouteName('/campaigns/new')}>
            <button
              type="button"
              className="inline-flex items-center px-4 sm:px-6 text-left py-4 border border-transparent rounded-md shadow-sm text-lg sm:text-xl font-bold text-white bg-green-400 hover:bg-green-500"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Start campaign
            </button>
          </Link>
        </div>
        <div className="mt-12">
          <p className="text-center font-normal text-md my-2 text-indigo-100">Need more help getting started?</p>
          <Link
            to={Portal.getRouteName('/getting-started')}
            className="inline-flex items-center text-md font-bold text-white"
          >
            Check out the onboarding section
          </Link>
        </div>
      </div>
    </div>
  </div>
);
