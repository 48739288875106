/* eslint-disable @typescript-eslint/no-redeclare */
import type { PortalAPI } from '@platform/api';
import { ActionType } from '@platform/helpers';

export type LoadedTeamMember = PortalAPI.UserAPI.GetTeamResponse['users'][0];
type InvitedTeamMember = PortalAPI.UserAPI.GetTeamResponse['invites'][0];

export type LoadedTeamResponse = {
  invites: InvitedTeamMember[];
  users: LoadedTeamMember[];
};

export const TeamActions = {
  loaded: (team: LoadedTeamResponse) =>
    ({
      type: 'Team/loaded',
      payload: { ...team },
    } as const),
  loading: () =>
    ({
      type: 'Team/loading',
    } as const),
  invite: (payload: InvitedTeamMember) =>
    ({
      type: 'Team/invited',
      payload,
    } as const),
  removeInvite: (id: number) =>
    ({
      type: 'Team/invite-removed',
      id,
    } as const),
  transferOwnership: (toId: number, fromId: number) =>
    ({
      type: 'Team/transfer-ownership',
      payload: { toId, fromId },
    } as const),
  removeUser: (id: number) =>
    ({
      type: 'Team/user-removed',
      id,
    } as const),
};

export type TeamActions = ActionType<typeof TeamActions>;
