import { Point } from 'geojson';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import markerIcon from '../../../assets/marker-icon.png';
import { Utils } from '@platform/ui-helpers';
import { TooltipText } from './TooltipText';

const fromPoint: (p: Point) => L.LatLngExpression = (p: Point) => ({
  lat: p.coordinates[1],
  lng: p.coordinates[0],
});

export const pinIcon = new Icon({
  iconUrl: markerIcon,
  iconSize: [35, 47],
  iconAnchor: [17, 45],
});

export const PinMarker = (props: { position: Point; tooltipData?: Utils.TooltipContent }) => {
  const data = typeof props.tooltipData === 'string' ? [props.tooltipData] : props.tooltipData;
  return (
    <Marker position={fromPoint(props.position)} icon={pinIcon}>
      {!!data && (
        <Popup>
          {data.map((item, index) => (
            <TooltipText key={index} content={item} />
          ))}
        </Popup>
      )}
    </Marker>
  );
};
