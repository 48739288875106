"use strict";
exports.__esModule = true;
exports.getInitials = void 0;
var lodash_1 = require("lodash");
var getInitials = function (name) {
    var _a;
    return (0, lodash_1.take)((_a = name === null || name === void 0 ? void 0 : name.split(' ')) !== null && _a !== void 0 ? _a : [], 2)
        .map(function (w) { return w[0]; })
        .join('')
        .toUpperCase();
};
exports.getInitials = getInitials;
