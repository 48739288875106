import { Constants } from '@platform/app-config';
import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { CampaignsMapState, initialCampaignMapState } from '../state/campaigns-map-state';

export const campaignsMapReducer: AppReducer<'CampaignsMap'> = (
  state = initialState.CampaignsMap,
  action
): typeof state => {
  switch (action.type) {
    case 'CampaignsMap/loaded': {
      action.payload.boundingBox
        ? localStorage.setItem(Constants.LAST_BOUNDING_BOX_STORAGE_KEY, JSON.stringify(action.payload.boundingBox))
        : localStorage.removeItem(Constants.LAST_BOUNDING_BOX_STORAGE_KEY);
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        boundingBox: action.payload.boundingBox ? { ...action.payload.boundingBox } : undefined,
        campaigns: action.payload.campaigns.reduce<CampaignsMapState['campaigns']>(
          (acc, campaign) => ({
            ...acc,
            [campaign.externalTrackingId]: campaign,
          }),
          { ...state.campaigns }
        ),
      };
    }
    case 'CampaignsMap/loading':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case 'Campaigns/clear': {
      return {
        ...initialCampaignMapState,
      };
    }
    case 'User/logout': {
      localStorage.removeItem(Constants.LAST_BOUNDING_BOX_STORAGE_KEY);
      return {
        ...state,
      };
    }
    case 'App/setCompanyImpersonation': {
      localStorage.removeItem(Constants.LAST_BOUNDING_BOX_STORAGE_KEY);
      return {
        ...state,
      };
    }
    case 'App/clearCompanyImpersonation': {
      localStorage.removeItem(Constants.LAST_BOUNDING_BOX_STORAGE_KEY);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
