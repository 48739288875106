import { PortalAPI } from '@platform/api';
import { AxiosResponse } from 'axios';
import { RouteArgs } from '@platform/helpers';
import { batch } from 'react-redux';
import { GetAccessTokenFunction } from '../../types/auth0';
import { NotificationType } from '../../types/notification';
import { AppThunk } from '../actions';
import { AppActions } from '../actions/app-actions';
import { TagsActions } from '../actions/tag-actions';
import { Selectors } from '../selectors';
import * as uuid from 'uuid';

export const loadAllTags: (getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (getAccessToken) => async (dispatch) => {
    try {
      dispatch(TagsActions.loading());

      const res = await PortalAPI.Client.getApiClient(getAccessToken).request({
        route: 'GET /v1/tags',
      });

      if (res.status === 200 && res.data) {
        dispatch(TagsActions.loaded(res.data));
      }
    } catch (error) {
      batch(() => {
        dispatch(TagsActions.loaded([]));
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Unable to load tags',
          })
        );
      });
    }
  };

export const createTag: (
  getAccessToken: GetAccessTokenFunction,
  params: Omit<PortalAPI.TagAPI.Requests['POST /v1/tags'], 'externalId'>
) => AppThunk<Promise<PortalAPI.Responses['POST /v1/tags']>> =
  (getAccessToken, { value }) =>
  async (dispatch) => {
    let ret: AxiosResponse<PortalAPI.TagAPI.Responses['POST /v1/tags']>;
    const externalId = uuid.v4();

    const tag: PortalAPI.TagAPI.Responses['POST /v1/tags'] = {
      externalId,
      value,
      prospectCount: 0,
      lastUsedAt: null,
      hiddenAt: null,
    };

    try {
      dispatch(TagsActions.create({ tag }));

      ret = await PortalAPI.Client.getApiClient(getAccessToken).request(
        {
          route: 'POST /v1/tags',
          queryParams: {
            platform: 'web',
          },
        },
        { value, externalId }
      );

      if (ret.data && typeof ret.data === 'object' && !('errors' in ret.data)) {
        const tag = ret.data as PortalAPI.TagAPI.TagWithProspectCount;

        batch(() => {
          dispatch(TagsActions.update({ externalId: tag.externalId, value: tag.value }));
          dispatch(
            AppActions.notification({
              type: NotificationType.success,
              title: 'Successfully created tag',
              placement: 'lower-right',
            })
          );
        });
      }

      return ret.data;
    } catch (e: any) {
      const res = e.response as typeof ret;

      batch(() => {
        dispatch(TagsActions.delete({ externalId }));
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: `Unable to create tag ${value}`,
          })
        );
      });

      return res.data;
    }
  };

export const updateTag: (
  getAccessToken: GetAccessTokenFunction,
  params: RouteArgs<typeof PortalAPI.TagAPI.API['PATCH /v1/tags/:id']> & PortalAPI.TagAPI.Requests['PATCH /v1/tags/:id']
) => AppThunk<Promise<PortalAPI.Responses['PATCH /v1/tags/:id']>> =
  (getAccessToken, { id, ...body }) =>
  async (dispatch, getState) => {
    let ret: AxiosResponse<PortalAPI.TagAPI.Responses['PATCH /v1/tags/:id']>;
    const existingTag = Selectors.getTagByExternalId(id)(getState());

    try {
      dispatch(TagsActions.update({ externalId: id, ...body }));

      ret = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.TagAPI.API['PATCH /v1/tags/:id'],
        PortalAPI.TagAPI.QueryParams['PATCH /v1/tags/:id']
      >(
        {
          route: PortalAPI.TagAPI.API['PATCH /v1/tags/:id'],
          params: {
            id,
          },
          queryParams: {
            platform: 'web',
          },
        },
        body
      );

      if (ret.data && typeof ret.data === 'object' && !('errors' in ret.data)) {
        dispatch(
          AppActions.notification({
            type: NotificationType.success,
            title: 'Successfully updated tag',
            placement: 'lower-right',
          })
        );
      }

      return ret.data;
    } catch (e: any) {
      const res = e.response as typeof ret;

      batch(() => {
        dispatch(
          TagsActions.update({
            externalId: id,
            value: existingTag?.value ?? '',
            isHidden: !!existingTag?.hiddenAt,
          })
        );
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Unable to update tag',
          })
        );
      });

      return res.data;
    }
  };

export const deleteTag: (
  getAccessToken: GetAccessTokenFunction,
  params: RouteArgs<typeof PortalAPI.TagAPI.API['DELETE /v1/tags/:id']>
) => AppThunk<Promise<void>> =
  (getAccessToken, { id }) =>
  async (dispatch, getState) => {
    let ret: AxiosResponse<PortalAPI.TagAPI.Responses['DELETE /v1/tags/:id']>;
    const existingTag = Selectors.getTagByExternalId(id)(getState());

    try {
      dispatch(TagsActions.delete({ externalId: id }));

      ret = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.TagAPI.API['DELETE /v1/tags/:id'],
        PortalAPI.TagAPI.QueryParams['DELETE /v1/tags/:id']
      >({
        route: PortalAPI.TagAPI.API['DELETE /v1/tags/:id'],
        params: {
          id,
        },
        queryParams: {
          platform: 'web',
        },
      });

      if (ret.data && typeof ret.data === 'object' && !('errors' in ret.data)) {
        dispatch(
          AppActions.notification({
            type: NotificationType.success,
            title: 'Successfully deleted tag',
            placement: 'lower-right',
          })
        );
      }
    } catch (e: any) {
      batch(() => {
        if (existingTag) {
          dispatch(TagsActions.create({ tag: existingTag }));
        }
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Unable to delete tag',
          })
        );
      });
    }
  };
