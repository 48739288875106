import { BuildingOffice2Icon as OfficeBuildingIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { UserState } from '../../redux/state/user-state';
import { WithAuthenticatedPageInjectedProps, withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import { PageHeader } from '../Layout/PageHeader';
import { CompanyList } from './CompanyList';
import { MyCompany, MyCompanyProps } from './MyCompany';
import { CompanyTab, CompanyTabId } from './types';

type CompanyUIProps = WithAuthenticatedPageInjectedProps &
  MyCompanyProps & {
    user: UserState['user'];
    onClickNewCompany: () => void;
    currentTab: CompanyTabId;
    tabs: CompanyTab[];
  };

export const CompanyUI = withAuthenticatedPageLayout(
  ({ user, onClickNewCompany, currentTab, tabs, isImpersonating, ...form }: CompanyUIProps) => {
    return (
      <div>
        <PageHeader
          icon={OfficeBuildingIcon}
          iconHeading="Company"
          title="My Company"
          newAction={{
            visible: !!user && !!user.permissions?.canCreateCompanies && (!isImpersonating || !!user.isSuperAdmin),
            text: (
              <>
                Create <span className="hidden sm:block">&nbsp;New</span>
              </>
            ),
            onClick: onClickNewCompany,
          }}
        >
          {tabs.length > 0 && (
            <div className="block">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <a
                    key={tab.id}
                    href={`#${tab.id}`}
                    className={classNames(
                      tab.id === currentTab
                        ? 'border-blue-600 text-blue-600 cursor-default'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer',
                      'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                    )}
                    aria-current={tab.id === currentTab ? 'page' : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          )}
        </PageHeader>
        <div className="px-4 py-5 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          {currentTab === 'my-company' && <MyCompany {...form} user={user} isImpersonating={isImpersonating} />}
          {currentTab === 'all-companies' && <CompanyList />}
        </div>
      </div>
    );
  }
);
