"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.API = void 0;
var helpers_1 = require("@platform/helpers");
var API;
(function (API) {
    API["GET /"] = "GET /";
    API["POST /v2/auth"] = "POST /v2/auth";
    API["POST /v1/prospects/:zoom"] = "POST /v1/prospects/:zoom";
    API["POST /v2/campaigns/:zoom"] = "POST /v2/campaigns/:zoom";
    // Deprecated 3.8.0
    API["POST /v1/campaigns/:zoom"] = "POST /v1/campaigns/:zoom";
    // Deprecated 3.6
    API["POST /v1/auth"] = "POST /v1/auth";
    API["POST /v3/tile/:zoom/prospects"] = "POST /v3/tile/:zoom/prospects";
    API["POST /v3/tile/:zoom/campaigns"] = "POST /v3/tile/:zoom/campaigns";
})(API = exports.API || (exports.API = {}));
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.unauthenticatedRoute)(API['GET /'])), (0, helpers_1.authenticatedRoute)(API['POST /v2/auth'])), (0, helpers_1.unauthenticatedRoute)(API['POST /v1/prospects/:zoom'])), (0, helpers_1.unauthenticatedRoute)(API['POST /v2/campaigns/:zoom'])), (0, helpers_1.unauthenticatedRoute)(API['POST /v1/campaigns/:zoom'])), (0, helpers_1.authenticatedRoute)(API['POST /v1/auth'])), (0, helpers_1.unauthenticatedRoute)(API['POST /v3/tile/:zoom/prospects'])), (0, helpers_1.unauthenticatedRoute)(API['POST /v3/tile/:zoom/campaigns']));
