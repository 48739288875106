import { Constants } from '@platform/app-config';
import { withPermissionsRequired } from '../../utils/with-permissions-required';
import { AddressLookup } from '../AddressLookup/address-lookup';
import { WithAuthenticatedPageInjectedProps, withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import CampaignCreator from './campaign-creator';
import Navigation from './campaign-creator/page-navigation';

const Page = ({ auth0: { getAccessTokenSilently } }: WithAuthenticatedPageInjectedProps) => {
  return (
    <div className="pb-20">
      <Navigation getAccessToken={getAccessTokenSilently} />
      <div className="flex flex-col md:items-center mx-auto px-5 mt-5 md:mt-8 md:max-w-xl mb-8">
        <div className="flex flex-col md:px-2">
          <h1 className="text-3xl font-bold leading-9 text-gray-900 sm:truncate md:text-center mb-1">New Campaign</h1>
          <p className="text-gray-500 md:text-center text-left">
            Generate a territory around a lead, a jobsite, or in a new area and use the{' '}
            <a className="text-blue-500" href={Constants.GUIDED_APP_IOS_URL} target="_blank" rel="noreferrer">
              Lead Scout Mobile App
            </a>{' '}
            to scout each prospect.
          </p>
        </div>
      </div>
      <AddressLookup getAccessToken={getAccessTokenSilently} />
      <CampaignCreator getAccessToken={getAccessTokenSilently} />
    </div>
  );
};

export const CampaignsNewPage = withPermissionsRequired(['canAccessCampaigns'])(withAuthenticatedPageLayout(Page));
