"use strict";
exports.__esModule = true;
exports.Sources = exports.Layers = void 0;
var Layers;
(function (Layers) {
    Layers["PROSPECTS"] = "prospects";
    Layers["CAMPAIGNS"] = "campaigns";
    Layers["PROSPECT_CLUSTER"] = "prospect_cluster";
    Layers["PROSPECT_CLUSTER_LABEL"] = "prospect_cluster_label";
    Layers["CAMPAIGN_LABEL"] = "campaign_label";
    Layers["CAMPAIGN_SHAPE_AREA"] = "campaign_shape";
    Layers["CAMPAIGN_SHAPE_BORDER"] = "campaign_shape_border";
    Layers["CAMPAIGN_TRIGGER"] = "campaign_trigger";
})(Layers = exports.Layers || (exports.Layers = {}));
var Sources;
(function (Sources) {
    Sources["PROSPECTS"] = "prospects";
    Sources["CAMPAIGNS"] = "campaigns";
    Sources["CAMPAIGN_SHAPES"] = "campaign_shapes";
})(Sources = exports.Sources || (exports.Sources = {}));
