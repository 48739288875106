/* eslint-disable @typescript-eslint/no-redeclare */
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { State } from '../state';
import { AddressLookupActions } from './address-lookup-actions';
import { AppActions } from './app-actions';
import { CampaignActions } from './campaign-actions';
import { CampaignCreatorActions } from './campaign-creator-actions';
import { CampaignMapActions } from './campaign-map-actions';
import { ProspectsActions } from './prospect-actions';
import { CompanyActions } from './company-actions';
import { DashboardActions } from './dashboard-actions';
import { LeadStatusActions } from './lead-status-actions';
import { ProgramActions } from './program-actions';
import { TeamActions } from './team-actions';
import { UserActions } from './user-actions';
import { TagsActions } from './tag-actions';

export const Actions = {
  AddressLookup: AddressLookupActions,
  App: AppActions,
  Campaign: CampaignActions,
  CampaignCreator: CampaignCreatorActions,
  CampaignMap: CampaignMapActions,
  Prospects: ProspectsActions,
  Company: CompanyActions,
  Dashboard: DashboardActions,
  LeadStatus: LeadStatusActions,
  Programs: ProgramActions,
  Team: TeamActions,
  User: UserActions,
  Tags: TagsActions,
};

export type Actions =
  | AddressLookupActions
  | AppActions
  | CampaignActions
  | CampaignCreatorActions
  | CampaignMapActions
  | CompanyActions
  | DashboardActions
  | LeadStatusActions
  | ProgramActions
  | ProspectsActions
  | TeamActions
  | UserActions
  | TagsActions;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, null | undefined, Action<Actions['type']>>;
