import { LoadedCampaign } from '../../../../redux/actions/campaign-actions';

export enum SelectedTab {
  MAP = 'map',
  LIST = 'list',
}

export interface DetailsHeaderUIProps {
  campaign: LoadedCampaign | null;
  onDownloadReport: (id: string) => void;
  isLoadingReport: boolean;
  deliveryWindow: string;
  isSuperAdmin: boolean;
  title?: string;
  address?: string;
  onMapSelected: () => void;
  onListSelected: () => void;
  selectedTab: SelectedTab;
  onEditTitle: (title: string) => Promise<void>;
  onAssignmentClicked: () => void;
  leadStatuses: {
    Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    count: number;
  }[];
}
