/* eslint-disable @typescript-eslint/no-redeclare */
import { PortalAPI } from '@platform/api';
import { LoadedCompany } from '../state/company-state';
import { ActionType } from '@platform/helpers';

export const CompanyActions = {
  loaded: (company: LoadedCompany) =>
    ({
      type: 'Company/loaded',
      payload: { ...company },
    } as const),
  loading: () =>
    ({
      type: 'Company/loading',
      payload: { isLoading: true },
    } as const),
  update: (payload: PortalAPI.CompanyAPI.UpdateCompanyResponse) =>
    ({
      type: 'Company/update',
      payload,
    } as const),
  allLoading: () =>
    ({
      type: 'Company/allLoading',
    } as const),
  allLoaded: (payload: PortalAPI.CompanyAPI.GetCompanyAllResponse) =>
    ({
      type: 'Company/allLoaded',
      payload,
    } as const),
  associations: (payload: PortalAPI.CompanyAPI.ChildCompanyAssociation[]) =>
    ({
      type: 'Company/loadedAssociations',
      payload,
    } as const),
  addAssociation: (payload: PortalAPI.CompanyAPI.InviteCompanyResponse) =>
    ({
      type: 'Company/addAssociation',
      payload,
    } as const),
  removeAssociation: (id: number) =>
    ({
      type: 'Company/removeAssociation',
      id,
    } as const),
};

export type CompanyActions = ActionType<typeof CompanyActions>;
