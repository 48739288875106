"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.queryParamsAreWithMailingScope = exports.queryParamsAreWithCampaignScope = exports.queryParamsAreWithoutScope = exports.RouteDefinitions = exports.ProgramPublicAPI = void 0;
var helpers_1 = require("@platform/helpers");
var ProgramPublicAPI;
(function (ProgramPublicAPI) {
    ProgramPublicAPI["POST /scan"] = "POST /scan";
    ProgramPublicAPI["GET /redirect/:company"] = "GET /redirect/:company";
    ProgramPublicAPI["GET /program/landing-page/:landingPagePath"] = "GET /program/landing-page/:landingPagePath";
    ProgramPublicAPI["POST /program/:id/submission"] = "POST /program/:id/submission";
    ProgramPublicAPI["GET /program/design/postcard/:template/:variation/:side"] = "GET /program/design/postcard/:template/:variation/:side";
    ProgramPublicAPI["GET /definition/:name/:sendout/:side"] = "GET /definition/:name/:sendout/:side";
    ProgramPublicAPI["GET /program/:id/preview/postcard/:sendout/:side"] = "GET /program/:id/preview/postcard/:sendout/:side";
    ProgramPublicAPI["GET /program/:id/preview/postcard/:sendout"] = "GET /program/:id/preview/postcard/:sendout";
    ProgramPublicAPI["GET /program/:id/preview/note/:side"] = "GET /program/:id/preview/note/:side";
    ProgramPublicAPI["GET /program/:id/preview/note"] = "GET /program/:id/preview/note";
    ProgramPublicAPI["GET /program/:id/lob_preview/postcard/:sendout"] = "GET /program/:id/lob_preview/postcard/:sendout";
    ProgramPublicAPI["GET /program/:id/pcm_preview/postcard/:sendout"] = "GET /program/:id/pcm_preview/postcard/:sendout";
    ProgramPublicAPI["GET /program/:id/test_qrcode"] = "GET /program/:id/test_qrcode";
    ProgramPublicAPI["GET /campaigns/:id/report"] = "GET /campaigns/:id/report";
    ProgramPublicAPI["GET /program/:id/proof-verification/:mailingId"] = "GET /program/:id/proof-verification/:mailingId";
})(ProgramPublicAPI = exports.ProgramPublicAPI || (exports.ProgramPublicAPI = {}));
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.unauthenticatedRoute)('POST /scan')), (0, helpers_1.unauthenticatedRoute)('GET /program/landing-page/:landingPagePath')), (0, helpers_1.unauthenticatedRoute)('GET /redirect/:company')), (0, helpers_1.unauthenticatedRoute)('POST /program/:id/submission')), (0, helpers_1.unauthenticatedRoute)('GET /program/design/postcard/:template/:variation/:side')), (0, helpers_1.unauthenticatedRoute)('GET /definition/:name/:sendout/:side')), (0, helpers_1.unauthenticatedRoute)('GET /program/:id/preview/postcard/:sendout/:side')), (0, helpers_1.unauthenticatedRoute)('GET /program/:id/preview/postcard/:sendout')), (0, helpers_1.unauthenticatedRoute)('GET /program/:id/lob_preview/postcard/:sendout')), (0, helpers_1.unauthenticatedRoute)('GET /program/:id/pcm_preview/postcard/:sendout')), (0, helpers_1.unauthenticatedRoute)('GET /program/:id/preview/note/:side')), (0, helpers_1.unauthenticatedRoute)('GET /program/:id/preview/note')), (0, helpers_1.unauthenticatedRoute)('GET /program/:id/test_qrcode')), (0, helpers_1.unauthenticatedRoute)('GET /program/:id/proof-verification/:mailingId')), (0, helpers_1.unauthenticatedRoute)('GET /campaigns/:id/report'));
function queryParamsAreWithoutScope(params) {
    return !!params.company && !params.mailingId && !params.campaignId;
}
exports.queryParamsAreWithoutScope = queryParamsAreWithoutScope;
function queryParamsAreWithCampaignScope(params) {
    return !!params.company && !params.mailingId && !!params.campaignId;
}
exports.queryParamsAreWithCampaignScope = queryParamsAreWithCampaignScope;
function queryParamsAreWithMailingScope(params) {
    return !!params.company && !!params.mailingId;
}
exports.queryParamsAreWithMailingScope = queryParamsAreWithMailingScope;
