import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { loadCampaignsMap } from '../../redux/thunks';
import { withPermissionsRequired } from '../../utils/with-permissions-required';
import { withCampaignList } from './campaigns-list/campaigns-list-data';
import { CampaignsPageUI } from './campaigns-page.ui';

export const CampaignsPage = withPermissionsRequired(['canAccessCampaigns'])(
  withCampaignList((listProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { isLoaded, isLoading, ...campaignsMap } = useSelector(Selectors.campaignMap);
    const dispatch = useTypedDispatch();

    const campaignMapProps = useMemo(
      () => ({
        ...campaignsMap,
        campaignsLoading: isLoading,
        campaignsLoaded: isLoaded,
      }),
      [campaignsMap, isLoaded, isLoading]
    );

    useEffect(() => {
      if (!isLoading && !isLoaded) {
        dispatch(loadCampaignsMap(getAccessTokenSilently));
      }
    }, [dispatch, getAccessTokenSilently, isLoaded, isLoading]);

    return <CampaignsPageUI campaignsList={listProps} campaignsMap={campaignMapProps} />;
  })
);
