import { BoostIndicator, ChatIcon, IconMap, TagsGroup, Tooltip, TooltipContent } from '@platform/ui';
import { ProspectMailStatus } from '@platform/model/dist/types/campaign';
import { getLeadStatusIcon, LeadStatusIcon } from '@platform/helpers';
import { useMemo } from 'react';
import moment from 'moment';

interface LeadStatusTagProps {
  leadStatus: { value: string; icon: LeadStatusIcon } | null;
}

export interface ProspectsListRowProps extends LeadStatusTagProps {
  name: string | null;
  address: string;
  addressLine2: string;
  mailStatus: ProspectMailStatus;
  currentDrip?: number;
  leadStatusUpdatedAt: Date | null;
  note: string | null;
  tags: { externalId: string; value: string }[];
  onClick: () => void;
  isDesktop: boolean;
}

const LeadStatusTag = ({ leadStatus }: LeadStatusTagProps) => {
  const Icon = IconMap[getLeadStatusIcon(leadStatus?.icon)];
  return (
    <div className="flex items-center bg-gray-100 rounded-lg p-2">
      <Icon className="shrink-0 h-5 w-5" aria-hidden="true" />
      <span className="text-sm leading-5 text-gray-900 font-semibold ml-2">{leadStatus?.value ?? 'New Prospect'}</span>
    </div>
  );
};

const ProspectsListRow = ({
  name,
  address,
  addressLine2,
  mailStatus,
  currentDrip,
  leadStatus,
  leadStatusUpdatedAt,
  note,
  tags,
  isDesktop,
  onClick,
}: ProspectsListRowProps) => {
  const [line1, line2, line3] = useMemo(
    () => (name ? [name, address, addressLine2] : [address, addressLine2, null]),
    [address, addressLine2, name]
  );

  const updated = useMemo(() => {
    if (!leadStatusUpdatedAt) {
      return null;
    }
    const date = moment(leadStatusUpdatedAt);
    const now = moment();
    if (now.year() !== date.year() && now.diff(date, 'months') > 6) {
      return `${date.format('MMM D, YY [•] h:mma')}`;
    }
    return `${date.format('MMM D [•] h:mma')}`;
  }, [leadStatusUpdatedAt]);
  return (
    <div
      className="shadow hover:shadow-md active:shadow-sm transition-shadow bg-white md:p-6 p-4 rounded-lg cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-col">
        <div className="flex items-center space-x-1 lg:hidden mb-4 justify-between">
          <LeadStatusTag leadStatus={leadStatus} />
          {!!updated && <span className="shrink-0 text-sm text-gray-500 font-normal text-right">{updated}</span>}
        </div>
        <div className="flex w-full items-start">
          <div className="flex mr-4 md:mr-6 min-w-0 space-x-2 md:w-2/3 grow">
            <div className="flex flex-col w-full md:w-1/2">
              <h3 className="md:text-lg leading-7 text-gray-900 font-semibold">{line1}</h3>
              <p className="text-sm leading-5 text-gray-500 font-normal">{line2}</p>
              <p className="text-sm leading-5 text-gray-500 font-normal">{line3}</p>
            </div>
            {isDesktop && (
              <div className="md:w-1/2">
                {!!note && (
                  <div className="flex items-center justify-start px-4 py-2 bg-gray-100 space-x-3 rounded-lg">
                    <ChatIcon className="shrink-0" color="#6B7280" />
                    <p className="text-sm leading-5 font-normal whitespace-pre-wrap">{note}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center justify-end shrink-0 md:w-1/3 place-self-center">
            <div className="hidden lg:flex mr-5 flex-col items-end space-y-2">
              <LeadStatusTag leadStatus={leadStatus} />
              {!!updated && <span className="text-sm leading-5 text-gray-500 font-normal text-right">{updated}</span>}
            </div>
            {mailStatus !== ProspectMailStatus.NONE && currentDrip !== undefined && (
              <BoostIndicator
                text={currentDrip.toString()}
                animate={mailStatus === ProspectMailStatus.ACTIVE}
                variant={mailStatus}
              />
            )}
          </div>
        </div>
        <div>
          {!!tags.length && <TagsGroup tags={tags} size="small" className="max-w-full" />}
          {!!note && !isDesktop && (
            <Tooltip
              iconJustify="start"
              className="pt-2"
              bottom={20}
              buttonContent={<ChatIcon />}
              content={
                <TooltipContent
                  content={[
                    {
                      text: note,
                    },
                  ]}
                  links={[]}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProspectsListRow;
