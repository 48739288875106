import { TriggerTypes } from '@platform/helpers';
import { breakpoints, IconMap } from '@platform/ui';
import { Utils } from '@platform/ui-helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useExportCampaign from '../../../../hooks/useExportCampaign';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { LoadedCampaign } from '../../../../redux/actions/campaign-actions';
import { Selectors } from '../../../../redux/selectors';
import { getCampaignNameSubtext } from '../../../../utils/campaign';
import DetailsHeaderDesktopUI from './details-header-desktop.ui';
import DetailsHeaderMobileUI from './details-header-mobile.ui';
import { SelectedTab } from './types';

interface Props {
  campaign: LoadedCampaign | null;
  isSuperAdmin: boolean;
  onMapSelected: () => void;
  onListSelected: () => void;
  onEditTitle: (title: string) => Promise<void>;
  selectedTab: SelectedTab;
  onAssignmentClicked: () => void;
}

const DetailsHeader = ({
  campaign,
  isSuperAdmin,
  selectedTab,
  onMapSelected,
  onListSelected,
  onEditTitle,
  onAssignmentClicked,
}: Props) => {
  const isDesktop = useMediaQuery(breakpoints.lg);
  const { leadStatuses, isLoaded: leadStatusesLoaded } = useSelector(Selectors.leadStatusMap);

  const currentLeadStatuses = useMemo(() => {
    if (!campaign || !leadStatuses || !leadStatusesLoaded || Object.keys(leadStatuses).length === 0) return [];
    return Object.keys(campaign.leadStatusSummary).map((lsId) => ({
      Icon: IconMap[leadStatuses[Number(lsId)].icon],
      count: campaign.leadStatusSummary[Number(lsId)].count,
    }));
  }, [campaign, leadStatuses, leadStatusesLoaded]);

  const { onDownloadReport, isLoadingReport } = useExportCampaign();

  const deliveryWindow = useMemo(() => {
    if (!campaign || !campaign.deliveryWindow) return '';

    // Create an interval for more than one drips
    if (campaign.deliveryWindow.total > 1) {
      return `${campaign.deliveryWindow.start} - ${campaign.deliveryWindow.end}`;
    }

    return campaign.deliveryWindow.start;
  }, [campaign]);

  const { title, address } = useMemo<{ title?: string; address?: string }>(() => {
    if (!campaign) return { title: '', address: '' };
    let title,
      address,
      addressLine2: string = '';
    if (
      (campaign.triggerType === TriggerTypes.jobSold || campaign.triggerType === TriggerTypes.lead) &&
      (campaign.triggerDetails || campaign.title)
    ) {
      const { homeownerName, address1, city, state, zip } = campaign.triggerDetails;
      const addressP2 = `${city}, ${state} ${zip}`;
      title = campaign.title ?? homeownerName ?? address1;
      address = campaign.title ?? homeownerName ? address1 : addressP2;
      addressLine2 = campaign.title ?? homeownerName ? addressP2 : '';
    } else if (campaign.triggerDetails || campaign.title) {
      title = campaign.title ?? Utils.getCampaignName(campaign);
      address = getCampaignNameSubtext(campaign);
    }

    return { title, address: addressLine2 ? `${address} ${addressLine2}` : address };
  }, [campaign]);

  const DetailsHeaderUI = isDesktop ? DetailsHeaderDesktopUI : DetailsHeaderMobileUI;

  return (
    <DetailsHeaderUI
      campaign={campaign}
      deliveryWindow={deliveryWindow}
      onDownloadReport={onDownloadReport}
      isLoadingReport={isLoadingReport}
      isSuperAdmin={isSuperAdmin}
      title={title}
      address={address}
      leadStatuses={currentLeadStatuses}
      onMapSelected={onMapSelected}
      onListSelected={onListSelected}
      selectedTab={selectedTab}
      onEditTitle={onEditTitle}
      onAssignmentClicked={onAssignmentClicked}
    />
  );
};

export default DetailsHeader;
