import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Constants } from '@platform/app-config';
import { Avatar } from '@platform/ui';
import { Utils } from '@platform/ui-helpers';
import classNames from 'classnames';
import { Fragment, MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Item = () => (
  <div className="flex items-center py-2 px-3 space-x-3">
    <div className="h-6 w-6 rounded-full bg-gray-100" />
    <div className="h-6 grow rounded bg-gray-200 mb-1"></div>
  </div>
);

const Loading = () => (
  <div className="animate-pulse flex flex-col">
    <Item />
    <Item />
    <Item />
  </div>
);

const buildImpersonationUrl = (externalId: string) =>
  Utils.buildUIUrl(window.location.origin, {
    route: '/',
    queryParams: { [Constants.IMPERSONATE_COMPANY_ID_HEADER]: externalId },
  });

export const CompanySelection = (props: {
  companyName?: string;
  externalId?: string;
  companyList: { externalId: string; name: string }[];
  onSearch: (value: string) => void;
  onMenuClosed?: () => void;
  isSearching?: boolean;
  searchError?: string | null;
  searchText: string;
  isAsync?: boolean;
}) => {
  const buttonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onChangeCompanyClicked = useCallback(() => {
    if (!menuIsOpen && (props.isAsync || props.companyList.length > 0)) {
      setMenuIsOpen(true);
      buttonRef.current?.click();
    } else {
      setMenuIsOpen(false);
    }
  }, [menuIsOpen, props.companyList.length, props.isAsync]);

  const onMenuClosed = useCallback(() => {
    setMenuIsOpen(false);
    if (props.onMenuClosed) {
      props.onMenuClosed();
    }
  }, [props]);

  const currentSelection = useMemo(
    () => ({ id: props.externalId, name: props.companyName }),
    [props.companyName, props.externalId]
  );

  const handleCompanySelected = useCallback((company: { externalId: string; name: string } | null) => {
    if (company) {
      window.location.href = buildImpersonationUrl(company.externalId);
    }
  }, []);

  return (
    <>
      <div
        onClick={onChangeCompanyClicked}
        className={classNames(
          { 'bg-blue-800 rounded-md': menuIsOpen },
          { 'hover:bg-blue-800 hover:rounded-md cursor-pointer': props.isAsync || props.companyList.length > 0 },
          'flex-shrink-0 flex items-center px-2 py-2 w-full'
        )}
      >
        <span className="flex min-w-0 items-center space-x-3 w-full">
          <Avatar avatar={{ name: props.companyName, color: 'rgb(30 58 138)' }} size="xl" />
          <div className="flex min-w-0 items-center space-x-1 justify-between w-full">
            <span className="text-white text-lg font-bold truncate">{props.companyName}</span>
            {(props.isAsync || props.companyList.length > 0) && (
              <ChevronUpDownIcon className="text-blue-500" height={20} width={20} />
            )}
          </div>
        </span>
      </div>
      <Combobox nullable onChange={handleCompanySelected} value={null}>
        <div className={classNames({ hidden: !menuIsOpen }, 'relative mt-2 ')}>
          <Tooltip id="companyName" className="z-[1000]" />
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            displayValue={(company: typeof props.companyList[number]) => company?.name}
            onChange={(event) => props.onSearch(event.target.value)}
            placeholder="Search for an account"
          />
          <Combobox.Button className="hidden" ref={buttonRef}>
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
          <Transition as={Fragment} afterLeave={onMenuClosed}>
            <Combobox.Options className="absolute mt-1 max-h-[50vh] w-full overflow-auto rounded-md  bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
              {props.isSearching ? (
                <Loading />
              ) : props.searchError ? (
                <div className="text-red-500">{props.searchError}</div>
              ) : props.companyList.length === 0 && props.searchText !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
              ) : props.companyList.length === 0 && props.searchText === '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Type to search...</div>
              ) : (
                props.companyList.map((company) => {
                  const selected = currentSelection.id === company.externalId;
                  return (
                    <Combobox.Option
                      key={company.externalId}
                      disabled={selected}
                      className={({ active }) =>
                        classNames(
                          {
                            'bg-blue-50': active && !selected,
                            'bg-gray-100': selected,
                            'text-gray-900': !active,
                          },
                          'relative cursor-default select-none'
                        )
                      }
                      value={company}
                    >
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <div
                        className="flex items-center py-2 px-3"
                        {...(company.name.length >= 18
                          ? {
                              'data-tooltip-id': 'companyName',
                              'data-tooltip-content': company.name,
                              'data-tooltip-delay-show': 500,
                            }
                          : {})}
                      >
                        <div className="flex items-center w-11/12">
                          {selected ? (
                            <CheckIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <Avatar avatar={{ name: company.name }} size="small" />
                          )}
                          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'} ml-3 truncate`}>
                            {company.name}
                          </span>
                        </div>
                        {!selected && (
                          <div
                            className="p-1 cursor-pointer w-1/12"
                            onClick={(e) => {
                              e.preventDefault();
                              if (!selected) {
                                window.open(buildImpersonationUrl(company.externalId), '_blank');
                              }
                            }}
                          >
                            <ArrowTopRightOnSquareIcon className="h-4 w-4 cursor-pointer" />
                          </div>
                        )}
                      </div>
                    </Combobox.Option>
                  );
                })
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </>
  );
};
