import React, { useCallback, useEffect } from 'react';
// Components
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { SelectMenu, Tooltip, TooltipContent } from '@platform/ui';
// Types
import { Item } from '@platform/ui/src/SelectMenu';
// Utils
import { Constants } from '@platform/app-config';

export interface Props {
  isLoading: boolean;
  members: Item[];
  selectedMember: Item;
  onSelectMember: (member: Item) => void;
  title: string | null;
  defaultTitle: string | null;
  onTitleChange: (title: string) => void;
}

const CampaignCreateDetailsUI = ({
  isLoading,
  members,
  selectedMember,
  onSelectMember,
  title,
  defaultTitle,
  onTitleChange,
}: Props) => {
  useEffect(() => {
    if (title === null && !!defaultTitle) {
      onTitleChange(defaultTitle);
    }
  }, [title, defaultTitle, onTitleChange]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => onTitleChange(e.target.value),
    [onTitleChange]
  );

  const onClear = useCallback(() => onTitleChange(''), [onTitleChange]);
  return (
    <div className="bg-white shadow rounded-lg p-6 mb-4 md:mb-10">
      <div className="flex flex-col">
        <div className="flex items-center mb-5">
          <h3 className="text-xl leading-7 text-gray-900 font-bold mr-2">Campaign Details</h3>
          <Tooltip
            bottom={28}
            buttonContent={<InformationCircleIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />}
            content={
              <TooltipContent
                content={[
                  {
                    text: 'Assign a team member to take responsibility for this campaign and follow up with each prospect in the Lead Scout mobile app.',
                  },
                ]}
                links={[
                  {
                    text: 'Find out more',
                    target: '_blank',
                    href: Constants.ASSIGN_TEAM_MEMBER_URL,
                  },
                ]}
              />
            }
          />
        </div>
        <div className="flex flex-col">
          {!isLoading && !!defaultTitle && (
            <div>
              <span className="block text-sm leading-5 font-medium text-gray-700 mb-1">Name the Campaign</span>
              <div className="flex flex-row items-center">
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="md:max-w-sm w-full rounded-md border border-gray-300 bg-white py-2.5 px-3 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                  autoComplete="off"
                  autoCapitalize="on"
                  placeholder={`e.g. ${defaultTitle}`}
                  value={title ?? ''}
                  onChange={onChange}
                />
                {!!title && (
                  <button className="text-xs text-blue-500 px-2 text-left" onClick={onClear}>
                    Clear
                  </button>
                )}
              </div>
              {!title?.length && <div className="text-sm text-red-500 my-1">Campaign Name is required</div>}
            </div>
          )}
        </div>
        <div className="flex flex-col md:max-w-sm mt-4">
          <SelectMenu
            title="Assign a Team Member"
            size="small"
            variant="avatar"
            fontWeight="medium"
            data={members}
            value={selectedMember}
            onChange={onSelectMember}
            isDisabled={members && members.length <= 1}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignCreateDetailsUI;
