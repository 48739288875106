"use strict";
exports.__esModule = true;
exports.formatAppointmentSubTitle = exports.getCampaignJobTooltip = exports.getCampaignName = exports.getCampaignNameFromEntity = void 0;
var helpers_1 = require("@platform/helpers");
var lodash_1 = require("lodash");
var date_fns_1 = require("date-fns");
var routes_1 = require("../portal/routes");
var build_ui_url_1 = require("./build-ui-url");
var getCampaignNameFromEntity = function (campaign) {
    var _a, _b, _c;
    return ((_a = campaign.title) !== null && _a !== void 0 ? _a : (campaign.trigger &&
        (campaign.trigger.triggerType === helpers_1.TriggerTypes.jobSold || campaign.trigger.triggerType === helpers_1.TriggerTypes.lead)
        ? ((_b = campaign.trigger.details) === null || _b === void 0 ? void 0 : _b.address1)
            ? "Neighbors to ".concat(campaign.trigger.details.address1)
            : 'Notes to Neighbors'
        : ((_c = campaign.trigger) === null || _c === void 0 ? void 0 : _c.triggerType) === 'targetsList'
            ? campaign.trigger.triggerExternalId
            : "Campaign ".concat(campaign.createdAt.toLocaleDateString())));
};
exports.getCampaignNameFromEntity = getCampaignNameFromEntity;
var getCampaignName = function (campaign) {
    var _a, _b, _c, _d, _e;
    // This isn't quite right but since the only way to trigger a N2N strategy is via a Job Sold trigger, we can
    // make this generalization.
    return campaign.triggerType === helpers_1.TriggerTypes.jobSold || campaign.triggerType === helpers_1.TriggerTypes.lead
        ? ((_a = campaign.triggerDetails) === null || _a === void 0 ? void 0 : _a.address1)
            ? "Neighbors to ".concat((_b = campaign.triggerDetails) === null || _b === void 0 ? void 0 : _b.address1)
            : 'Notes to Neighbors'
        : campaign.triggerType === 'targetsList'
            ? ((_c = campaign.triggerDetails) === null || _c === void 0 ? void 0 : _c.targetsListName)
                ? campaign.triggerDetails.targetsListName
                : ((_d = campaign.program) === null || _d === void 0 ? void 0 : _d.name)
                    ? (0, lodash_1.startCase)((0, lodash_1.toLower)(campaign.program.name))
                    : "Campaign ".concat(campaign.startDate)
            : (_e = (0, helpers_1.assertUnreachableSafe)(campaign)) !== null && _e !== void 0 ? _e : '';
};
exports.getCampaignName = getCampaignName;
var getCampaignJobTooltip = function (campaign, withLink) {
    if (withLink === void 0) { withLink = true; }
    if (!campaign) {
        return undefined;
    }
    var url = (0, build_ui_url_1.buildUIUrl)(null, {
        route: (0, routes_1.getRouteName)('/campaigns/:id'),
        params: { id: campaign.externalTrackingId }
    });
    return campaign.triggerType === helpers_1.TriggerTypes.jobSold || campaign.triggerType === helpers_1.TriggerTypes.lead
        ? [
            {
                text: campaign.title ? campaign.title : 'View',
                url: withLink ? url : undefined
            },
        ]
        : campaign.triggerType === helpers_1.TriggerTypes.targetsList
            ? "Target List - Center Point"
            : (0, helpers_1.assertUnreachableSafe)(campaign.triggerType);
};
exports.getCampaignJobTooltip = getCampaignJobTooltip;
var formatAppointmentSubTitle = function (appointmentTime) {
    if (!appointmentTime) {
        return 'Set a date & time';
    }
    var parsedDate = typeof appointmentTime === 'string' ? new Date(appointmentTime) : appointmentTime;
    if (!(0, date_fns_1.isValid)(parsedDate)) {
        return 'Set a date & time';
    }
    return (0, date_fns_1.format)(parsedDate, "MMM d, yyyy 'at' h:mmaaa");
};
exports.formatAppointmentSubTitle = formatAppointmentSubTitle;
