import { useAuth0 } from '@auth0/auth0-react';
import { UserIcon } from '@heroicons/react/24/outline';
import { PortalAPI } from '@platform/api';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { useSelector } from 'react-redux';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { UserState } from '../../redux/state/user-state';
import { updateUserById } from '../../redux/thunks';
import type { PersonalInformationPayload } from '../../types/account';
import { NotificationType } from '../../types/notification';
import { InputGroup } from '../InputGroup';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import { PageHeader } from '../Layout/PageHeader';
import { Spinner } from '../Spinner';

const getDefaultValues = (user: UserState['user']) => ({
  email: user?.email ?? '',
  firstName: user?.firstName ?? '',
  lastName: user?.lastName ?? '',
  phoneNumber: user?.phoneNumber ?? '',
});

export const Account = withAuthenticatedPageLayout(() => {
  const dispatch = useTypedDispatch();
  const [isSendingResetPassword, setIsSendingResetPassword] = useState(false);
  const [isSameFormContent, setIsSameFormContent] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const { user, isLoading } = useSelector(Selectors.user);

  const {
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors, isSubmitting },
    control,
  } = useForm<PersonalInformationPayload>({
    defaultValues: getDefaultValues(user),
  });

  useEffect(() => {
    if (isLoading) return;

    const defaultValues = getDefaultValues(user);

    // Only enable the save button if the information is different
    const subscription = watch((values) => {
      const isEqual = Object.entries(getDefaultValues(user)).toString() === Object.entries(values).toString();

      setIsSameFormContent(isEqual);
    });

    reset(defaultValues);

    return () => subscription.unsubscribe();
  }, [user, isLoading, watch, reset]);

  const onUpdateUserInformation = useCallback(async () => {
    try {
      const isSameEmail = getValues('email').toLowerCase() === user?.email.toLowerCase();

      await dispatch(updateUserById(getAccessTokenSilently, { ...getValues(), isSameEmail }));

      dispatch(
        AppActions.notification({
          type: NotificationType.success,
          title: 'User updated!',
          description: 'User information successfully updated',
        })
      );

      if (!isSameEmail) {
        dispatch(
          AppActions.notification({
            type: NotificationType.info,
            title: 'The email has changed!',
            description: 'You will be redirected to the login page.',
          })
        );

        // the page needs to be reloaded if the email changes to verify user authentication
        setTimeout(() => window.location.reload(), 2500);
      }
    } catch {
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Error updating!',
          description: 'Something went wrong when updating your information',
        })
      );
    }
  }, [user?.email, getValues, dispatch, getAccessTokenSilently]);

  const onUpdatePassword = useCallback(async () => {
    try {
      setIsSendingResetPassword(true);

      const { status } = await PortalAPI.Client.getApiClient(getAccessTokenSilently).request({
        route: 'POST /user/password-reset',
      });

      if (status === 200) {
        dispatch(
          AppActions.notification({
            type: NotificationType.success,
            title: 'Successfully sent!',
            description: "Email to update password has been sent. Please check your email's inbox.",
          })
        );
      }
    } catch (e) {
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Unable to send email!',
          description: 'Unable to send email to update password.',
        })
      );
    } finally {
      setIsSendingResetPassword(false);
    }
  }, [dispatch, getAccessTokenSilently]);

  return (
    <div>
      <PageHeader icon={UserIcon} iconHeading="Account" title="My Account" />
      <div className="px-4 py-5 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="flex flex-col lg:flex-row">
          <div className="mb-5 w-full lg:max-w-xs lg:pr-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Personal information</h3>
            <p className="mt-1 text-sm text-gray-500">Manage your personal information</p>
          </div>
          <div className="flex-1">
            <div className="flex-1 bg-white shadow overflow-hidden rounded-lg">
              <div className="p-6 max-w-xl">
                <div className="flex mb-5">
                  <div className="flex-1 mr-3">
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: 'First Name is required',
                      }}
                      render={({ field }) => (
                        <InputGroup
                          title="First name"
                          errors={errors.firstName}
                          isDisabled={isSubmitting}
                          isLoading={isLoading}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="flex-1 ml-3">
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: 'Last name is required',
                      }}
                      render={({ field }) => (
                        <InputGroup
                          title="Last name"
                          errors={errors.lastName}
                          isDisabled={isSubmitting}
                          isLoading={isLoading}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="mb-5">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    }}
                    render={({ field }) => (
                      <InputGroup
                        type="email"
                        title="Email address"
                        errors={errors.email}
                        isDisabled={isSubmitting}
                        isLoading={isLoading}
                        {...field}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: 'Phone number is required',
                    validate: (value) => (isValidPhoneNumber(value) ? true : 'Invalid Phone Number'),
                  }}
                  render={({ field }) => (
                    <InputGroup
                      isPhoneNumber
                      title="Phone Number (Mobile)"
                      errors={errors.phoneNumber}
                      isDisabled={isSubmitting}
                      isLoading={isLoading}
                      {...field}
                    />
                  )}
                />
              </div>
              <hr className="sm:divide-y sm:divide-gray-200" />
              <div className="px-6 py-3 flex justify-end">
                <button
                  onClick={handleSubmit(onUpdateUserInformation)}
                  type="button"
                  className={classNames(
                    {
                      'pointer-events-none opacity-60': isSubmitting || isLoading || isSameFormContent,
                    },
                    'inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  )}
                  disabled={isSubmitting || isLoading || isSameFormContent}
                >
                  {isSubmitting && <Spinner className="text-blue-500" />}
                  Save
                </button>
              </div>
            </div>
            <div className="bg-white shadow overflow-hidden rounded-lg flex-1 flex items-center mt-5">
              <div className="px-4 py-5 sm:px-6 flex-grow w-full">
                <h3 className="text-base leading-6 font-medium text-gray-900">Password</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Update your password</p>
              </div>
              <div className="flex mr-2">
                <button
                  onClick={onUpdatePassword}
                  disabled={isSendingResetPassword || isLoading}
                  className={classNames(
                    {
                      'pointer-events-none opacity-60': isSendingResetPassword || isLoading,
                    },
                    'flex items-center flex-row px-4 h-10 font-medium text-sm text-blue-600 hover:text-blue-500 text-right'
                  )}
                >
                  {isSendingResetPassword && <Spinner className="text-blue-500" />}
                  Update&nbsp;
                  <span className="hidden sm:block">Password</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
