import React, { useEffect } from 'react';
import { groupBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from '../../components/Notification';
import { Selectors } from '../../redux/selectors';
import { AppActions } from '../../redux/actions/app-actions';
import type { Notification as NotificationType } from '../../types/notification';
import classNames from 'classnames';

interface NotificationWrapperProps extends NotificationType {
  time?: number;
  id: string;
}

const NotificationWrapper = ({ id, type, title, description, placement, time = 5000 }: NotificationWrapperProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(AppActions.removeNotification());
    }, time);
  }, [dispatch, time]);

  return (
    <Notification
      type={type}
      title={title}
      description={description}
      onClose={() => dispatch(AppActions.removeNotificationById(id))}
      placement={placement}
    />
  );
};

const NotificationModal = () => {
  const notifications = useSelector(Selectors.notifications);

  if (notifications.length <= 0) return null;

  const byPlacement = groupBy(notifications, (n) => n.placement);

  return (
    <>
      {(Object.keys(byPlacement) as NotificationType['placement'][]).map((p) => (
        <div
          key={p}
          aria-live="assertive"
          className={classNames(
            {
              'inset-0': p === 'upper-right',
              'bottom-0': p === 'lower-right',
              'right-0': p === 'lower-right',
              'left-0': p === 'lower-right',
            },
            'fixed flex items-start px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[20120]'
          )}
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {byPlacement[p].map(({ id, ...rest }) => {
              return <NotificationWrapper key={id} id={id} {...rest} />;
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export default NotificationModal;
