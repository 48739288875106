import React from 'react';
import classNames from 'classnames';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

export interface ItemProps {
  onClick: () => void;
  isActive?: boolean;
  showDivider?: boolean;
  isDisabled?: boolean;
}

export type Position = 'right' | 'center';

export interface MenuItemProps extends ItemProps {
  text: string | Array<string>;
}

export interface MenuItemColsProps extends ItemProps {
  text: Array<string>;
}

export interface DropdownProps {
  /**
   * Menu.Button that will trigger dropdown
   */
  children: React.ReactElement;
  data: Array<MenuItemProps>;
  variant?: 'simple' | 'columns';
  contentSize?: 'small' | 'medium' | 'large';
  title?: string;
  position?: Position;
}

const MenuItem = ({ text, onClick, isActive = false, showDivider = false, isDisabled = false }: MenuItemProps) => {
  return (
    <>
      <Menu.Item>
        <button
          onClick={onClick}
          type="button"
          className={classNames({
            'block px-4 py-2 text-sm w-full text-left': true,
            'text-gray-700 hover:bg-gray-100 hover:text-gray-900': !isActive && !isDisabled,
            'bg-gray-100 text-gray-900': isActive,
            'text-gray-400 pointer-events-none': isDisabled,
          })}
        >
          {text}
        </button>
      </Menu.Item>
      {showDivider && <hr className="my-1" />}
    </>
  );
};

const MenuItemCols = ({ text, onClick, isActive = false, isDisabled = false }: MenuItemColsProps) => {
  return (
    <>
      <Menu.Item>
        <div
          onClick={onClick}
          role="button"
          className={classNames(
            {
              'text-gray-900 hover:bg-blue-50': !isActive && !isDisabled,
              'bg-blue-50': isActive,
              'text-gray-700 pointer-events-none': isDisabled,
              'grid-cols-3': text.length === 2,
              'grid-cols-4': text.length === 3,
              'grid-cols-5': text.length === 4,
            },
            'grid gap-3 cursor-pointer p-2 rounded-xl text-sm'
          )}
        >
          {text.map((item, index, { length }) => (
            <span
              className={classNames({
                'col-span-2': index === 0,
                'text-right': index !== 0,
                'font-bold': index + 1 === length,
              })}
              key={index}
            >
              {item}
            </span>
          ))}
        </div>
      </Menu.Item>
    </>
  );
};

export const Dropdown = ({
  children,
  data,
  variant = 'simple',
  contentSize = 'small',
  title,
  position = 'right',
}: DropdownProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {children}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            {
              'rounded-md': variant === 'simple',
              'rounded-3xl': variant === 'columns',
              'w-56': contentSize === 'small',
              'w-72': contentSize === 'medium',
              'w-96': contentSize === 'large',
              'right-0 origin-top-right': position === 'right',
              'left-1/2 -translate-x-1/2 origin-top': position === 'center',
            },
            'z-50 absolute mt-2 shadow-lg bg-white ring-1 ring-black/5 focus:outline-none'
          )}
        >
          <div className={classNames({ 'py-1': variant === 'simple', 'p-3': variant === 'columns' })}>
            {title && (
              <div className="p-2">
                <h3 className="text-base font-bold text-gray-900">{title}</h3>
              </div>
            )}
            {data.map((item, index) => {
              if (variant === 'columns') {
                return (
                  <MenuItemCols
                    key={index}
                    text={item.text as string[]}
                    onClick={item.onClick}
                    isActive={item.isActive}
                    isDisabled={item.isDisabled}
                  />
                );
              }
              return (
                <MenuItem
                  key={index}
                  text={item.text}
                  onClick={item.onClick}
                  isActive={item.isActive}
                  showDivider={item.showDivider}
                  isDisabled={item.isDisabled}
                />
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
