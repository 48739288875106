import { Fragment, useCallback, useState } from 'react';
import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Notification as NotificationProps, NotificationType } from '../../types/notification';

export interface Props extends NotificationProps {
  onClose?: () => void;
}

export const Notification = ({ title, description, type, onClose }: Props) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleIconVariant = useCallback(() => {
    switch (type) {
      case NotificationType.error:
        return <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />;
      case NotificationType.warning:
        return <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />;
      case NotificationType.success:
        return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
      case NotificationType.info:
        return <InformationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />;
    }
  }, [type]);

  return (
    <Transition
      show={isVisible}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black/5 overflow-hidden bg-white">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{handleIconVariant()}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              {!!description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                type="button"
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  setIsVisible(false);
                  if (typeof onClose === 'function') {
                    onClose();
                  }
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
