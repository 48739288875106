import { DialogModal, DuplicateAddressContent } from '@platform/ui';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';

const DuplicateAddressModal = () => {
  const modalProps = useSelector(Selectors.getDuplicateAddressModal);
  const dispatch = useTypedDispatch();
  const onClose = useCallback(() => {
    dispatch(AppActions.setDuplicateProspectModal(null));
  }, [dispatch]);

  return (
    <DialogModal isOpen={!!modalProps} onClose={onClose}>
      <DuplicateAddressContent
        addressLine1={modalProps?.addressLine1 ?? ''}
        addressLine2={modalProps?.addressLine2 ?? ''}
        onClose={onClose}
      />
    </DialogModal>
  );
};

export default DuplicateAddressModal;
