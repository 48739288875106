"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
exports.__esModule = true;
exports.buildGeoClient = exports.buildFetchClient = void 0;
var fetch_api_1 = require("./fetch-api");
var geo_api_1 = require("./geo-api");
var buildFetchClient = function (apiKey) {
    return new fetch_api_1.ColeFetchAPI(apiKey);
};
exports.buildFetchClient = buildFetchClient;
var buildGeoClient = function (apiKey) {
    return new geo_api_1.ColeGeoAPI(apiKey);
};
exports.buildGeoClient = buildGeoClient;
__exportStar(require("./fetch-api"), exports);
__exportStar(require("./geo-api"), exports);
__exportStar(require("./helpers"), exports);
__exportStar(require("./types"), exports);
