/* eslint-disable @typescript-eslint/no-redeclare */
import { ActionType } from '@platform/helpers';
import { MailDeliveryType, MapInstructionsStepType, ProspectCountStepType } from '../../types/campaign';
import { Polygon } from 'geojson';

export const CampaignCreatorActions = {
  setPolygon: (payload: Polygon) =>
    ({
      type: 'CampaignCreator/setPolygon',
      payload,
    } as const),
  setResetProspectCount: () =>
    ({
      type: 'CampaignCreator/setResetProspectCount',
    } as const),
  setProspectTotalCount: (payload: number) =>
    ({
      type: 'CampaignCreator/setProspectTotalCount',
      payload,
    } as const),
  setProspectCount: (payload: number) =>
    ({
      type: 'CampaignCreator/setProspectCount',
      payload,
    } as const),
  setProspectCountStep: (payload: ProspectCountStepType) =>
    ({
      type: 'CampaignCreator/setProspectCountStep',
      payload,
    } as const),
  setMailProgramId: (payload: number) =>
    ({
      type: 'CampaignCreator/setMailProgramId',
      payload,
    } as const),
  setIsMailProgramEnabled: (payload: boolean) =>
    ({
      type: 'CampaignCreator/setIsMailProgramEnabled',
      payload,
    } as const),
  setSalesRepId: (payload: number) =>
    ({
      type: 'CampaignCreator/setSalesRepId',
      payload,
    } as const),
  setIsSubmitting: (payload: boolean) =>
    ({
      type: 'CampaignCreator/setIsSubmitting',
      payload,
    } as const),
  setMapInstructionStep: (payload: MapInstructionsStepType) =>
    ({
      type: 'CampaignCreator/setMapInstructionStep',
      payload,
    } as const),
  setOnResetShape: (payload: () => void) =>
    ({
      type: 'CampaignCreator/setOnResetShape',
      payload,
    } as const),
  setCampaignTitle: (payload: string) =>
    ({
      type: 'CampaignCreator/setCampaignTitle',
      payload,
    } as const),
  setClearForm: () =>
    ({
      type: 'CampaignCreator/setClearForm',
    } as const),
  setMailDeliveryType: (payload: MailDeliveryType) =>
    ({
      type: 'CampaignCreator/setMailDeliveryType',
      payload,
    } as const),
  setMailDeliveryDate: (payload: Date) =>
    ({
      type: 'CampaignCreator/setMailDeliveryDate',
      payload,
    } as const),
};

export type CampaignCreatorActions = ActionType<typeof CampaignCreatorActions>;
