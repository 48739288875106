"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.API = void 0;
var helpers_1 = require("@platform/helpers");
var API;
(function (API) {
    API["GET /dashboard/lead-status-activity"] = "GET /dashboard/lead-status-activity";
    API["GET /dashboard/impressions"] = "GET /dashboard/impressions";
    API["GET /dashboard/tag-activity"] = "GET /dashboard/tag-activity";
})(API = exports.API || (exports.API = {}));
exports.RouteDefinitions = __assign(__assign(__assign({}, (0, helpers_1.authenticatedRoute)(API['GET /dashboard/lead-status-activity'])), (0, helpers_1.authenticatedRoute)(API['GET /dashboard/impressions'])), (0, helpers_1.authenticatedRoute)(API['GET /dashboard/tag-activity']));
