import React from 'react';
import { PlayIcon } from '@heroicons/react/20/solid';
import { Spinner } from '@platform/ui';
import classNames from 'classnames';

export interface StartCampaignButtonProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  size?: 'normal' | 'large';
  fullWidth?: boolean;
  onClick: () => void;
}

export const StartCampaignButton = ({
  isDisabled = false,
  isLoading = false,
  size = 'normal',
  fullWidth = false,
  onClick,
}: StartCampaignButtonProps) => {
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={isDisabled || isLoading}
      className={classNames(
        {
          'w-full': fullWidth,
          'h-[52px]': size === 'normal',
          'h-[56px]': size === 'large',
          'bg-green-500 hover:bg-green-600 active:bg-green-500': !isLoading && !isDisabled,
          'bg-green-300': isLoading || isDisabled,
        },
        'inline-flex justify-center items-center rounded-lg border border-transparent px-6 shadow-sm focus:outline-none'
      )}
    >
      {isLoading ? (
        <Spinner size={size === 'large' ? 'normal' : 'small'} variant="success" />
      ) : (
        <PlayIcon
          className={classNames(
            { '-ml-1 h-6 w-6': size === 'normal', '-ml-2 h-8 w-8': size === 'large' },
            'text-white'
          )}
          aria-hidden="true"
        />
      )}
      {size === 'normal' && (
        <span className="text-base leading-7 font-semibold text-white ml-3">
          Start<span className="hidden sm:inline"> Campaign</span>
        </span>
      )}
      {size === 'large' && <span className="text-lg leading-6 font-semibold text-white ml-3">Start Campaign</span>}
    </button>
  );
};
