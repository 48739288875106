import { DashboardDateFilter } from '@platform/helpers';
import { Utils } from '@platform/ui-helpers';
import type {
  ImpressionsResponse,
  LeadStatusActivityResponse,
  TagActivityResponse,
} from '../actions/dashboard-actions';

export type DashboardState = {
  selectedFilter: DashboardDateFilter;
  leadStatusActivity: {
    isLoaded: boolean;
    isLoading: boolean;
    filter: DashboardDateFilter['id'] | null;
    data: Omit<LeadStatusActivityResponse, 'leadStatuses'> | null;
    leadStatuses: LeadStatusActivityResponse['leadStatuses'] | null;
  };
  impressions: {
    isLoaded: boolean;
    isLoading: boolean;
    filter: DashboardDateFilter['id'] | null;
    data: ImpressionsResponse | null;
  };
  tagActivity: {
    isLoaded: boolean;
    isLoading: boolean;
    filter: DashboardDateFilter['id'] | null;
    data: TagActivityResponse | null;
  };
};

export const initialDashboardState: DashboardState = {
  selectedFilter: {
    id: 'wtd',
    title: 'Week to date',
    ...Utils.buildDateFilterFromOption('wtd'),
  },
  leadStatusActivity: {
    data: null,
    leadStatuses: null,
    filter: null,
    isLoaded: false,
    isLoading: false,
  },
  impressions: {
    data: null,
    filter: null,
    isLoaded: false,
    isLoading: false,
  },
  tagActivity: {
    data: null,
    filter: null,
    isLoaded: false,
    isLoading: false,
  },
};
