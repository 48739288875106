/* eslint-disable @typescript-eslint/no-redeclare */
import { PortalAPI } from '@platform/api';
import { ActionType } from '@platform/helpers';

export type LoadedMapCampaign = PortalAPI.CampaignAPI.CampaignsMapResponse;
export type MapCampaignSummary = PortalAPI.CampaignAPI.CampaignsMapSummaryResponse;

export const CampaignMapActions = {
  loaded: (campaigns: PortalAPI.CampaignAPI.CampaignsMapSummaryResponse) =>
    ({
      type: 'CampaignsMap/loaded',
      payload: {
        ...campaigns,
      },
    } as const),
  loading: () =>
    ({
      type: 'CampaignsMap/loading',
    } as const),
};

export type CampaignMapActions = ActionType<typeof CampaignMapActions>;
