import { useCallback } from 'react';
import { AddressSuggestion } from '../../../redux/actions/address-lookup-actions';
import { SearchBar } from '../../SearchBar/search-bar';

export interface AddressSearchBarUIProps {
  loading: boolean;
  suggestions: AddressSuggestion[];
  onInputChanged: (input: string) => void;
  onSelected: (selection: AddressSuggestion) => void;
  onClear: () => void;
}

const buildSingleEntryAddress = (suggestion: AddressSuggestion) =>
  `${suggestion.street_line} ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
const buildMultiEntryAddress = (suggestion: AddressSuggestion) =>
  `${suggestion.street_line} ${suggestion.secondary} (${suggestion.entries} entries) ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;

export const AddressSearchBarUI: React.FunctionComponent<AddressSearchBarUIProps> = ({
  onSelected,
  suggestions,
  ...rest
}) => {
  const handleSelection = useCallback(
    (selection: { selected: AddressSuggestion }) => onSelected(selection.selected),
    [onSelected]
  );

  const displayTransformer = useCallback(
    (suggestion: AddressSuggestion) =>
      (suggestion.secondary && suggestion.entries > 1 ? buildMultiEntryAddress : buildSingleEntryAddress)(suggestion),
    []
  );

  return (
    <SearchBar {...rest} options={suggestions} onSelected={handleSelection} displayTransformer={displayTransformer} />
  );
};
