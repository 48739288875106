import { useCallback, useMemo } from 'react';
import { useTypedDispatch } from '../../redux/state';
import { useSelector } from 'react-redux';
import { Selectors } from '../../redux/selectors';
import { logout as logoutAction } from '../../redux/thunks';
import { AppActions } from '../../redux/actions/app-actions';
import { DialogModal, SubscriptionError } from '@platform/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Portal } from '@platform/ui-helpers';
import { PortalAPI } from '@platform/api';

export const SubscriptionErrorModal = () => {
  const auth0 = useAuth0();
  const dispatch = useTypedDispatch();
  const modalProps = useSelector(Selectors.getSubscriptionErrorModalIsOpen);
  const navigate = useNavigate();

  const { title, description, primaryTitle, url } = useMemo(() => {
    switch (modalProps?.subscriptionStatus) {
      case 'paused':
        return {
          title: 'Your free trial has expired.',
          description: "Don't worry - your data is still safe and sound! Update your subscription to regain access.",
          url: Portal.getRouteName('/billing/add_payment_method'),
          primaryTitle: 'Add Payment Method',
        };
      case 'canceled':
        return {
          title: 'Your subscription has been canceled',
          description: "Don't worry - your data is still safe and sound! Update your subscription to regain access.",
          url: Portal.getRouteName('/plan'),
          primaryTitle: 'Subscribe',
        };
      case 'deleted':
        return {
          title: 'Your account has been removed',
          description:
            "Don't worry - your data is still safe and sound! Contact your account administrator to regain access.",
        };
      default:
        return {
          title: 'Uh oh! Your account has an issue.',
          description:
            "Don't worry - your data is still safe and sound! Contact our sales team and we'll help you get your account back up and running!",
        };
    }
  }, [modalProps]);

  const onOpenForm = useCallback(
    async (url: Portal.UIRoutes) => {
      if (url === '/plan') {
        const state: PortalAPI.AuthAPI.RedirectState['/plan'] = {
          freeTrial: false,
        };
        navigate(url, { state });
      } else {
        navigate(url);
      }
      dispatch(AppActions.setSubscriptionErrorModal(null));
    },
    [navigate, dispatch]
  );

  const onTryNewLogin = useCallback(async () => {
    dispatch(logoutAction(auth0.logout));
  }, [dispatch, auth0]);

  return (
    <DialogModal onClose={() => null} isOpen={!!modalProps}>
      <div className="px-5 md:px-12">
        <SubscriptionError
          title={title}
          description={[description]}
          buttons={
            !primaryTitle
              ? [
                  {
                    text: 'Try a different login',
                    onClick: onTryNewLogin,
                    isPrimary: true,
                  },
                ]
              : [
                  {
                    text: primaryTitle,
                    onClick: () => onOpenForm(url),
                    isPrimary: true,
                  },
                  {
                    text: 'Try a different login',
                    onClick: onTryNewLogin,
                    isNeutral: true,
                  },
                ]
          }
        />
      </div>
    </DialogModal>
  );
};
