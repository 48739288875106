"use strict";
exports.__esModule = true;
exports.delay = exports.delayMs = void 0;
var delayMs = function (ms) {
    return new Promise(function (resolve) {
        setTimeout(function () { return resolve('run'); }, ms);
    });
};
exports.delayMs = delayMs;
var delay = function (helper, ms) {
    return new Promise(function (resolve, reject) {
        var timer = setTimeout(function () { return resolve('run'); }, ms);
        helper.cancel = function () {
            clearTimeout(timer);
            reject('cancelled');
        };
    });
};
exports.delay = delay;
