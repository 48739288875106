import { UserPermissions } from '@platform/model/dist/types/user';
import { ComponentType, FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ErrorPage } from '../components/Error';
import { Selectors } from '../redux/selectors';

const Redirect: FunctionComponent<{ error?: string; errorDescription?: string }> = (props) => {
  return <ErrorPage {...props} />;
};

export const withPermissionsRequired =
  <P extends object>(
    permissions: (keyof UserPermissions)[],
    RedirectComponent?: FunctionComponent<{ error?: string; errorDescription?: string }>
  ) =>
  (Component: ComponentType<P>): FunctionComponent<P> => {
    return function WithPermissionsRequired(props: P): JSX.Element {
      const userPermissions = useSelector(Selectors.permissions);
      const RedirectTo = RedirectComponent ?? Redirect;

      const hasPermissions = useMemo(() => {
        // If we don't know OR if every one of the permissions is fulfilled, then we'll let it through
        return !userPermissions || permissions.every((p) => userPermissions[p]);
      }, [userPermissions]);

      return hasPermissions ? (
        <Component {...(props as P)} />
      ) : (
        <RedirectTo error="Not Allowed" errorDescription="User does not have permissions to view this page" />
      );
    };
  };
