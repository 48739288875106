"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.API = void 0;
var helpers_1 = require("@platform/helpers");
var API;
(function (API) {
    API["POST /signup"] = "POST /signup";
    API["POST /auth0/login-check"] = "POST /auth0/login-check";
    API["POST /portal/login-check"] = "POST /portal/login-check";
    API["POST /mobile/signup"] = "POST /mobile/signup";
    API["POST /mobile/login-check"] = "POST /mobile/login-check";
    API["GET /stripe-success"] = "GET /stripe-success";
    API["GET /stripe-cancel"] = "GET /stripe-cancel";
    API["GET /stripe-checkout"] = "GET /stripe-checkout";
    API["GET /stripe-checkout-sponsored"] = "GET /stripe-checkout-sponsored";
    API["GET /stripe-customer-portal"] = "GET /stripe-customer-portal";
    API["GET /stripe-retrieve-subscription"] = "GET /stripe-retrieve-subscription";
})(API = exports.API || (exports.API = {}));
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.unauthenticatedRoute)(API['POST /signup'])), (0, helpers_1.unauthenticatedRoute)(API['POST /auth0/login-check'])), (0, helpers_1.authenticatedRoute)(API['POST /portal/login-check'])), (0, helpers_1.authenticatedRoute)(API['POST /mobile/signup'])), (0, helpers_1.authenticatedRoute)(API['POST /mobile/login-check'])), (0, helpers_1.unauthenticatedRoute)(API['GET /stripe-success'])), (0, helpers_1.unauthenticatedRoute)(API['GET /stripe-cancel'])), (0, helpers_1.authenticatedRoute)(API['GET /stripe-checkout'])), (0, helpers_1.unauthenticatedRoute)(API['GET /stripe-checkout-sponsored'])), (0, helpers_1.authenticatedRoute)(API['GET /stripe-customer-portal'])), (0, helpers_1.authenticatedRoute)(API['GET /stripe-retrieve-subscription']));
