"use strict";
exports.__esModule = true;
exports.getMobileOS = exports.MobileOS = void 0;
var MobileOS;
(function (MobileOS) {
    MobileOS["ANDROID"] = "Android";
    MobileOS["IOS"] = "IOS";
    MobileOS["OTHER"] = "Other";
})(MobileOS = exports.MobileOS || (exports.MobileOS = {}));
var getMobileOS = function () {
    var ua = navigator.userAgent;
    if (/android/i.test(ua)) {
        return MobileOS.ANDROID;
    }
    else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return MobileOS.IOS;
    }
    return MobileOS.OTHER;
};
exports.getMobileOS = getMobileOS;
