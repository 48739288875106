import { assertUnreachable, CampaignStrategy, TriggerTypes } from '@platform/helpers';
import { LoadedCampaign } from '../redux/actions/campaign-actions';

export const getCampaignNameSubtext = (campaign: LoadedCampaign) => {
  if (campaign.triggerType === TriggerTypes.jobSold || campaign.triggerType === TriggerTypes.lead) {
    return campaign.triggerDetails?.formattedAddress;
  } else if (campaign.triggerType === TriggerTypes.targetsList) {
    if (campaign.strategy === CampaignStrategy.STORM_SWATH) {
      return 'Storm Swath';
    } else if (campaign.strategy === CampaignStrategy.REHASH) {
      return 'Rehash';
    } else {
      return 'Targets List';
    }
  } else {
    assertUnreachable(campaign);
  }
};
