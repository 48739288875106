import { PortalAPI } from '@platform/api';

export interface CampaignProspects {
  totalRecords: number;
  ids: string[];
}

export type LoadedProspect = PortalAPI.ProspectAPI.ProspectResponse_v3 & {
  isLoading: boolean;
  profundPropertyDataLoading?: boolean;
};

export interface ProspectsState {
  prospects: { [id: string]: LoadedProspect };
  prospectsByCampaign: { [campaignId: string]: CampaignProspects };
  activity: { [externalId: string]: { data: PortalAPI.ProspectAPI.ActivityData[]; isLoading: boolean } };
}

export const initialProspectsState: ProspectsState = {
  prospects: {},
  prospectsByCampaign: {},
  activity: {},
};
