export var CampaignFilter;
(function (CampaignFilter) {
    CampaignFilter["NEW"] = "new";
    CampaignFilter["RECENT"] = "recent";
    CampaignFilter["HISTORICAL"] = "historical";
})(CampaignFilter || (CampaignFilter = {}));
export var CampaignMailStatus;
(function (CampaignMailStatus) {
    CampaignMailStatus["NONE"] = "none";
    CampaignMailStatus["ACTIVE"] = "active";
    CampaignMailStatus["COMPLETED"] = "completed";
    CampaignMailStatus["SCHEDULED"] = "scheduled";
    CampaignMailStatus["PENDING"] = "pending";
    CampaignMailStatus["WAITING"] = "waiting";
})(CampaignMailStatus || (CampaignMailStatus = {}));
export var ProspectMailStatus;
(function (ProspectMailStatus) {
    ProspectMailStatus["NONE"] = "none";
    ProspectMailStatus["OFF"] = "off";
    ProspectMailStatus["ACTIVE"] = "active";
    ProspectMailStatus["STOPPED"] = "stopped";
    ProspectMailStatus["COMPLETED"] = "completed";
    ProspectMailStatus["SCHEDULED"] = "scheduled";
    ProspectMailStatus["UNDELIVERABLE"] = "undeliverable";
})(ProspectMailStatus || (ProspectMailStatus = {}));
