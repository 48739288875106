import { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { Drawer, ModalHeaderToolbar, ButtonList, ButtonListItem } from '@platform/ui';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppActions } from '../../redux/actions/app-actions';
import { updateTag } from '../../redux/thunks';

export const EditTagModal = ({ getAccessTokenSilently }: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const modalProps = useSelector(Selectors.getEditTagModal);
  const dispatch = useTypedDispatch();
  const [value, setValue] = useState('');

  const onClose = useCallback(() => {
    dispatch(AppActions.setEditTagModalOpen(null));
  }, [dispatch]);

  useEffect(() => {
    if (modalProps) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);

      setValue(modalProps.value);
    }
  }, [modalProps]);

  const onDelete = useCallback(() => {
    if (modalProps) {
      dispatch(
        AppActions.setDeleteTagModalOpen({
          onCallback: onClose,
          ...modalProps,
        })
      );
    }
  }, [dispatch, modalProps, onClose]);

  const onOpenConfirmHideModal = useCallback(() => {
    if (modalProps) {
      dispatch(AppActions.setHideTagModalOpen({ ...modalProps }));
    }
  }, [dispatch, modalProps]);

  const onUpdate = useCallback(
    async (hasClose: boolean = true) => {
      if (value && modalProps?.externalId) {
        await dispatch(
          updateTag(getAccessTokenSilently, {
            value,
            id: modalProps.externalId,
          })
        );
        if (hasClose) {
          onClose();
        }
      }
    },
    [dispatch, getAccessTokenSilently, onClose, value, modalProps]
  );

  const onShow = useCallback(async () => {
    if (modalProps?.externalId) {
      await dispatch(
        updateTag(getAccessTokenSilently, {
          id: modalProps.externalId,
          isHidden: false,
        })
      );
      onClose();
    }
  }, [dispatch, getAccessTokenSilently, modalProps?.externalId, onClose]);

  const getActions = useMemo(() => {
    const isHidden = !!modalProps?.isHidden;

    const EyeIconComponent = isHidden ? EyeIcon : EyeSlashIcon;

    const list: ButtonListItem[] = [
      {
        text: `${isHidden ? 'Show' : 'Hide'} tag`,
        onClick: !isHidden ? onOpenConfirmHideModal : onShow,
        icon: <EyeIconComponent className="h-6 w-6 text-blue-500 shrink-0" aria-hidden="true" />,
        variant: 'primary',
      },
      {
        text: 'Delete tag',
        onClick: onDelete,
        icon: <TrashIcon className="h-6 w-6 text-red-500 shrink-0" aria-hidden="true" />,
        variant: 'danger',
      },
    ];

    return list;
  }, [modalProps?.isHidden, onDelete, onOpenConfirmHideModal, onShow]);

  return (
    <Drawer isOpen={!!modalProps} onClose={onClose}>
      <ModalHeaderToolbar
        onBack={onClose}
        onSave={onUpdate}
        title="Edit Tag"
        isDisabled={!value || value === modalProps?.value}
        isLoading={false}
        hasShadow
      />
      <div className="flex flex-col p-6 bg-white">
        <input
          ref={inputRef}
          type="text"
          name="tag-input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="text-base text-gray-900 border border-gray-300 placeholder-gray-400 focus:border-blue-500 block w-full px-3 py-4 rounded-md focus:outline-none disabled:bg-slate-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
        />
        <p className="text-sm font-normal leading-5 text-center text-gray-500	mt-4 mb-8">
          {!modalProps || modalProps?.prospectCount === 0
            ? 'No prospects currently have this tag'
            : modalProps.prospectCount === 1
            ? '1 prospect currently has this tag'
            : `${modalProps.prospectCount} prospects currently have this tag`}
        </p>
        <ButtonList data={getActions} />
      </div>
    </Drawer>
  );
};
