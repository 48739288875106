import React from 'react';

const ProspectsListRow = () => (
  <div className="flex flex-col shadow bg-white p-6 rounded-lg">
    <div className="flex lg:hidden mb-4 animate-pulse">
      <div className="h-9 bg-gray-100 rounded-lg w-28"></div>
    </div>
    <div className="flex items-center animate-pulse">
      <div className="flex grow flex-col mr-4">
        <div className="h-6 bg-gray-100 rounded w-1/2 lg:w-1/4 block animate-pulse mb-2"></div>
        <div className="h-4 bg-gray-100 rounded w-1/3 lg:w-1/5 block animate-pulse mb-1"></div>
        <div className="h-4 bg-gray-100 rounded w-1/3 lg:w-1/5 block animate-pulse"></div>
      </div>
      <div className="flex items-center ml-auto">
        <div className="hidden lg:flex mr-5">
          <div className="h-9 bg-gray-100 rounded-lg w-28"></div>
        </div>
        <div className="bg-gray-100 rounded-full w-[60px] h-[60px]"></div>
      </div>
    </div>
  </div>
);

const Loading = () => {
  return (
    <>
      <ProspectsListRow />
      <ProspectsListRow />
      <ProspectsListRow />
    </>
  );
};

export default Loading;
