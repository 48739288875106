import { Polygon } from 'geojson';
import React from 'react';
import { MapUI } from './MapUI';
export interface MapProps {
  boundingBox?: Polygon;
  classes?: string;
  fullHeight?: boolean;
}
const Map = ({ boundingBox, classes, children, fullHeight = false }: React.PropsWithChildren<MapProps>) => {
  if (!boundingBox) return null;
  return (
    <MapUI classes={classes} boundingBox={boundingBox} fullHeight={fullHeight}>
      {children}
    </MapUI>
  );
};

export default Map;
