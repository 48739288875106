import { FunctionComponent } from 'react';

interface PageProps {}
export interface WithUnauthenticatedPageLayoutProps {}

export function withUnauthenticatedPageLayout<
  T extends WithUnauthenticatedPageLayoutProps = WithUnauthenticatedPageLayoutProps
>(WrappedComponent: React.ComponentType<T>) {
  const Page: FunctionComponent<T & PageProps> = (props) => {
    return (
      <div className="relative h-screen flex overflow-hidden bg-gray-100">
        <div className="flex-1 overflow-auto focus:outline-none">
          <main className="flex-1 relative z-0">
            <WrappedComponent {...props} />
          </main>
        </div>
      </div>
    );
  };
  return Page;
}
