import { PortalAPI } from '@platform/api';
import { AxiosResponse } from 'axios';
import { GetAccessTokenFunction } from '../../types/auth0';
import { NotificationType } from '../../types/notification';
import { Actions, AppThunk } from '../actions';

export const updateCompany: (
  getAccessToken: GetAccessTokenFunction,
  body: PortalAPI.CompanyAPI.Requests['PATCH /company']
) => AppThunk<Promise<void>> = (getAccessToken, body) => async (dispatch) => {
  const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request(
    {
      route: 'PATCH /company',
    },
    body
  );

  if (status === 200 && data) {
    dispatch(Actions.Company.update(data));
  }
};

export const loadCompanyAssociations: (getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (getAccessToken) => async (dispatch) => {
    const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
      route: 'GET /company/associations',
    });

    if (status === 200 && data && 'children' in data) {
      dispatch(Actions.Company.associations(data.children));
    }
  };

export const loadBirdseyeCompanies: (getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (getAccessToken) => async (dispatch) => {
    dispatch(Actions.Company.allLoading());
    const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
      route: 'GET /company/all',
    });

    if (status === 200 && data) {
      dispatch(Actions.Company.allLoaded(data));
    }
  };

export const deleteCompanyAssociation: (
  getAccessToken: GetAccessTokenFunction,
  id: number
) => AppThunk<Promise<void>> = (getAccessToken, id) => async (dispatch) => {
  try {
    await PortalAPI.Client.getApiClient(getAccessToken).request({
      route: 'DELETE /company/associations/:id',
      params: { id: id.toString() },
    });
    dispatch(Actions.Company.removeAssociation(id));
    dispatch(
      Actions.App.notification({
        type: NotificationType.success,
        title: `Company Invite deleted`,
        description: `Invitation has been deleted`,
      })
    );
  } catch {
    dispatch(
      Actions.App.notification({
        type: NotificationType.error,
        title: `Company Invite Delete Failed`,
        description: `Failed to delete invitation`,
      })
    );
  }
};

export const createCompanyInvite: (
  getAccessToken: GetAccessTokenFunction,
  company: PortalAPI.Requests['POST /company/invite']
) => AppThunk<Promise<PortalAPI.Responses['POST /company/invite']>> = (getAccessToken, company) => async (dispatch) => {
  let ret: AxiosResponse<PortalAPI.CompanyAPI.Responses['POST /company/invite']>;

  try {
    ret = await PortalAPI.Client.getApiClient(getAccessToken).request(
      {
        route: 'POST /company/invite',
      },
      company
    );

    if (ret.data && typeof ret.data === 'object' && !('errors' in ret.data)) {
      dispatch(Actions.Company.addAssociation(ret.data));
      dispatch(
        Actions.App.notification({
          type: NotificationType.success,
          title: `Company Invited`,
          description: `${company.userEmail} has been invited to ${company.companyName}`,
        })
      );
    } else {
      dispatch(
        Actions.App.notification({
          type: NotificationType.error,
          title: 'Unknown Error',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    }
    return ret.data;
  } catch (e: any) {
    const res = e.response as typeof ret;
    dispatch(
      Actions.App.notification({
        type: NotificationType.error,
        title: 'Error',
        description:
          'errors' in res.data && typeof res.data.errors === 'string' ? res.data.errors : 'Unable to create company',
      })
    );
    return res.data;
  }
};
