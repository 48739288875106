import * as React from 'react';
import { SelectMenu } from '@platform/ui';
import { LoadedTeamMember } from '../../redux/actions/team-actions';

export type CampaignAssignmentUIProps = {
  submitting: boolean;
  isTeamLoading: boolean;
  onCancel: () => void;
  users: LoadedTeamMember[];
  assignedUserId: number | null;
  onSelectUser: (value: { id: number; text: string }) => void;
};

export const CampaignAssignmentUI: React.FunctionComponent<CampaignAssignmentUIProps> = (props) => {
  const members = React.useMemo(() => {
    return !props.isTeamLoading && props.users
      ? props.users.map(({ id, firstName, lastName }) => ({
        id,
        text: `${firstName} ${lastName}`,
        avatar: '',
      }))
      : [];
  }, [props.isTeamLoading, props.users]);

  const selectedTeamMember = React.useMemo(
    () => members.find((u) => u.id === props.assignedUserId) ?? null,
    [members, props]
  );

  return (
    <div className="min-h-full flex flex-col justify-center p-5">
      <div className="sm:bg-white sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex pb-8 pt-4 flex-col">
          <SelectMenu
            title="Assigned to:"
            size="medium"
            variant="avatar"
            fontWeight="normal"
            data={members}
            value={selectedTeamMember}
            onChange={props.onSelectUser}
            isDisabled={members && members.length <= 1}
            isLoading={props.isTeamLoading}
          />
        </div>
        <div className="flex flex-row pt-0 pb-4 justify-end">
            <button
              className="w-full sm:text-base leading-4 text-red-500 background-transparent font-bold text-sm outline-none focus:outline-none hover:text-red-600 hover:underline"
              type="button"
              onClick={props.onCancel}
            >
              Cancel
            </button>
          </div>
      </div>
    </div>
  );
};
