import { PortalAPI } from '@platform/api';
import { useMemo } from 'react';
import { useSearch, UseSearchProps } from '../../hooks/use-search';
import { GetAccessTokenFunction } from '../../types/auth0';
import { CompanySelection } from './CompanySelect';

export const CompanySelectSearch = (props: {
  companyName?: string;
  externalId?: string;
  getAccessToken: GetAccessTokenFunction;
}) => {
  const searchProps: UseSearchProps<
    { externalId: string; name: string },
    PortalAPI.CompanyAPI.Responses['POST /company/search']
  > = useMemo(
    () => ({
      debounce: { ms: 500, maxWaitMs: 1000 },
      onSearch: async (value: string) => {
        try {
          const { data } = await PortalAPI.Client.getApiClient(props.getAccessToken).request(
            {
              route: 'POST /company/search',
            },
            { value }
          );

          return data;
        } catch (e) {
          console.error(e);
          return null;
        }
      },
    }),
    [props.getAccessToken]
  );

  const { onValueChanged, searchError, searchIsFetching, searchResults } = useSearch(searchProps);

  const companyList = useMemo(() => searchResults.results ?? [], [searchResults.results]);

  return (
    <CompanySelection
      companyList={companyList}
      onSearch={onValueChanged}
      searchText={searchResults.searchTerm}
      companyName={props.companyName}
      externalId={props.externalId}
      isSearching={searchIsFetching}
      searchError={searchError}
      isAsync
    />
  );
};
