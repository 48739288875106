import L, { MarkerClusterGroupOptions } from 'leaflet';
import React from 'react';
import MarkerCluster from 'react-leaflet-cluster';

export interface ClusterProps extends MarkerClusterGroupOptions {
  getClusterCount?: (markers: L.Marker[]) => number;
  smallCountLimit?: number;
  mediumCountLimit?: number;
}

export const MarkerClusterGroup: React.FunctionComponent<React.PropsWithChildren<ClusterProps>> = ({
  getClusterCount,
  smallCountLimit,
  mediumCountLimit,
  children,
  ...props
}) => {
  const clusterIcon = (cluster: L.MarkerCluster) => {
    // Note: this logic is taken directly from the default clustering icon. The only difference here is
    // that we need to filter out the circles from the counts, otherwise it doubles the counts. The
    // styling should be identical though.
    const markerCount = getClusterCount
      ? getClusterCount(cluster.getAllChildMarkers())
      : cluster.getAllChildMarkers().length;
    let c = 'marker-cluster-';
    if (markerCount < (smallCountLimit ?? 10)) {
      c += 'small';
    } else if (markerCount < (mediumCountLimit ?? 100)) {
      c += 'medium';
    } else {
      c += 'large';
    }
    return new L.DivIcon({
      html: `<div><span>${markerCount}</span></div>`,
      className: `marker-cluster ${c}`,
      iconSize: new L.Point(40, 40),
    });
  };

  const { zoomToBoundsOnClick, spiderfyOnMaxZoom, iconCreateFunction, disableClusteringAtZoom, ...rest } = props;

  return (
    <MarkerCluster
      zoomToBoundsOnClick={zoomToBoundsOnClick ?? true}
      spiderfyOnMaxZoom={spiderfyOnMaxZoom ?? false}
      iconCreateFunction={iconCreateFunction ?? clusterIcon}
      disableClusteringAtZoom={disableClusteringAtZoom ?? 14}
      {...rest}
    >
      {children}
    </MarkerCluster>
  );
};
