"use strict";
exports.__esModule = true;
exports.buildUIErrorUrl = void 0;
var utils_1 = require("../utils");
var routes_1 = require("./routes");
var buildUIErrorUrl = function (frontendUrl, errorHeading, errorDescription) {
    return (0, utils_1.buildUIUrl)(frontendUrl, {
        route: (0, routes_1.getRouteName)('/error'),
        queryParams: {
            error: errorHeading,
            error_description: errorDescription
        }
    });
};
exports.buildUIErrorUrl = buildUIErrorUrl;
