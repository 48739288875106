import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Drawer, TagsInput, TagOption, ModalHeaderToolbar } from '@platform/ui';
import { ActionMeta, SelectInstance } from 'react-select';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppActions } from '../../redux/actions/app-actions';
import { updateProspectTags } from '../../redux/thunks';
import { uniqBy } from 'lodash';

const buildTagOptions = (tags: { externalId: string; value: string }[]): readonly TagOption[] =>
  tags.map((t) => ({ value: t.externalId, label: t.value }));
const buildTagsFromOptions = (options: readonly TagOption[]) =>
  options.map((t) => ({ externalId: t.value, value: t.label } as const));

export const ProspectTagsModal = ({ getAccessTokenSilently }: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const modalProps = useSelector(Selectors.getProspectsTagsModal);
  const dispatch = useTypedDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<SelectInstance<TagOption, true> | null>(null);
  const allTags = useSelector(Selectors.getTags);
  const permissions = useSelector(Selectors.permissions);
  const [newTags, setNewTags] = useState<readonly TagOption[] | null>(null);

  const tagOptions = useMemo(
    () => uniqBy([...buildTagOptions(allTags.filter((t) => !t.hiddenAt) ?? []), ...(newTags ?? [])], (t) => t.value),
    [newTags, allTags]
  );
  const selectedTags = useMemo(() => (modalProps?.tags ? buildTagOptions(modalProps.tags) : []), [modalProps?.tags]);

  const onClose = useCallback(() => {
    dispatch(AppActions.setProspectsTagsModal(null));
  }, [dispatch]);

  const onSave = useCallback(async () => {
    if (modalProps?.prospectId) {
      setIsSubmitting(true);
      try {
        await dispatch(
          updateProspectTags(getAccessTokenSilently, {
            externalId: modalProps.prospectId,
            tags: buildTagsFromOptions(newTags ?? []),
          })
        );

        onClose();
      } catch {
        console.error('error updating tags');
      } finally {
        setIsSubmitting(false);
      }
    }
  }, [dispatch, getAccessTokenSilently, modalProps, onClose, newTags]);

  const addTags = useCallback((tags: readonly TagOption[], meta: ActionMeta<TagOption>) => {
    setNewTags(tags);
    setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.openMenu('first');
    }, 0);
  }, []);

  useEffect(() => {
    if (!!modalProps) {
      inputRef.current?.focusInput();
      inputRef.current?.openMenu('first');

      setNewTags(selectedTags);
    }
  }, [selectedTags, modalProps]);

  const selectedOptions = useMemo(() => (newTags ?? []).map((t) => t.value), [newTags]);

  return (
    <Drawer isOpen={!!modalProps} onClose={onClose}>
      <ModalHeaderToolbar
        onBack={onClose}
        onSave={onSave}
        title="Add Tags"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        hasShadow
      />
      <div className="flex flex-col pt-6 bg-white">
        <form>
          <TagsInput
            ref={inputRef}
            creatable={!!permissions?.canManageTags}
            saveInProgress={isSubmitting}
            options={tagOptions}
            selectedOptions={selectedOptions}
            onTagSelected={addTags}
            isPortrait
            isTablet
          />
        </form>
      </div>
    </Drawer>
  );
};
