"use strict";
exports.__esModule = true;
exports.streamToBuffer = exports.streamToString = void 0;
var streamToString = function (stream) {
    return new Promise(function (resolve, reject) {
        var chunks = [];
        stream.on('data', function (chunk) { return chunks.push(chunk); });
        stream.on('error', reject);
        stream.on('end', function () { return resolve(Buffer.concat(chunks).toString('utf8')); });
    });
};
exports.streamToString = streamToString;
var streamToBuffer = function (stream) {
    return new Promise(function (resolve, reject) {
        var chunks = [];
        stream.on('data', function (chunk) { return chunks.push(chunk); });
        stream.on('error', reject);
        stream.on('end', function () { return resolve(Buffer.concat(chunks)); });
    });
};
exports.streamToBuffer = streamToBuffer;
