import { PortalAPI } from '@platform/api';
import classNames from 'classnames';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { isWebUri } from 'valid-url';
import { LoadedUser } from '../../../redux/actions/user-actions';
import { InputGroup } from '../../InputGroup';
import { Spinner } from '../../Spinner';

export type MyCompanyProps = {
  initialValues: PortalAPI.CompanyAPI.Requests['PATCH /company'];
  control: Control<PortalAPI.CompanyAPI.Requests['PATCH /company']>;
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors?: FieldErrors<PortalAPI.CompanyAPI.Requests['PATCH /company']>;
  submitting: boolean;
  isSameContent: boolean;
  user: LoadedUser | null;
  isImpersonating: boolean;
};

export const MyCompany = ({ user, ...form }: MyCompanyProps) => {
  return (
    <div>
      <div className="flex flex-col lg:flex-row">
        <div className="mb-5 w-full lg:max-w-xs lg:pr-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Company information</h3>
          <p className="mt-1 text-sm text-gray-500">Manage your company information</p>
        </div>
        <div className="flex-1">
          <div className="flex-1 bg-white shadow overflow-hidden rounded-lg">
            <div className="p-6 max-w-xl">
              <div className="mb-5">
                <Controller
                  name="name"
                  control={form.control}
                  rules={{
                    required: 'Company name is required',
                  }}
                  render={({ field }) => (
                    <InputGroup
                      title="Company Name"
                      errors={form.errors?.name}
                      isDisabled={form.submitting}
                      isLoading={!user}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-5">
                <Controller
                  name="email"
                  control={form.control}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  }}
                  render={({ field }) => (
                    <InputGroup
                      type="email"
                      title="Primary Email address"
                      errors={form.errors?.email}
                      isDisabled={form.submitting}
                      isLoading={!user}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-5">
                <Controller
                  name="phoneNumber"
                  control={form.control}
                  rules={{
                    validate: (value) => !value || (isValidPhoneNumber(value) ? true : 'Invalid Phone Number'),
                  }}
                  render={({ field }) => (
                    <InputGroup
                      isPhoneNumber
                      title="Phone Number"
                      errors={form.errors?.phoneNumber}
                      isDisabled={form.submitting}
                      isLoading={!user}
                      {...field}
                    />
                  )}
                />
              </div>
              <Controller
                name="website"
                control={form.control}
                rules={{
                  validate: (value) =>
                    !value || (!!isWebUri(value) ? true : 'Invalid Website URL (Make sure it starts with https://)'),
                }}
                render={({ field }) => (
                  <InputGroup
                    title="Website URL"
                    errors={form.errors?.website}
                    isDisabled={form.submitting}
                    isLoading={!user}
                    {...field}
                  />
                )}
              />
            </div>
            <hr className="sm:divide-y sm:divide-gray-200" />
            <div className="px-6 py-3 flex justify-end">
              <button
                onClick={form.handleSubmit}
                type="button"
                className={classNames(
                  {
                    'pointer-events-none opacity-60': form.submitting || !user || form.isSameContent,
                  },
                  'inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                )}
                disabled={form.submitting || !user || form.isSameContent}
              >
                {form.submitting && <Spinner className="text-blue-500" />}
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
