import { PortalAPI } from '@platform/api';
import { ensureDate, getAddressLine1, getAddressLine2 } from '@platform/helpers';
import { AppointmentDetail } from '@platform/ui';
import { format } from 'date-fns';
import { DetailModal } from './modal';
import { DetailModalProps } from './types';

export const AppointmentActivityDetailsModal: React.FC<
  DetailModalProps<PortalAPI.ProspectAPI.ActivityDataType.APPOINTMENT>
> = ({ prospect, activity, safeAreaTop, onClose }) => (
  <DetailModal
    isOpen
    headerProps={{
      safeAreaTop,
      onBack: onClose,
      title: 'Appointment Details',
      isDisabled: false,
      isLoading: false,
    }}
    onClose={onClose}
  >
    <AppointmentDetail
      addressLine1={prospect?.address ? getAddressLine1(prospect.address) : ''}
      addressLine2={prospect?.address ? getAddressLine2(prospect.address) : ''}
      userName={activity.details.userName ?? ''}
      timestamp={format(ensureDate(activity.timestamp), "MMM d, yyyy 'at' h:mmaaa")}
      appointmentTime={format(ensureDate(activity.details.appointmentTime), "MMM d, yyyy 'at' h:mmaaa")}
      status={activity.details.action}
    />
  </DetailModal>
);
