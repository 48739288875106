"use strict";
exports.__esModule = true;
exports.getSignupCompleteQueryParams = exports.getRouteName = void 0;
var getRouteName = function (name) { return name; };
exports.getRouteName = getRouteName;
var getQueryParam = function (query, key) {
    return query.get(key);
};
var getSignupCompleteQueryParams = function (query) { return ({
    token: getQueryParam(query, 'token'),
    state: getQueryParam(query, 'state'),
    userHash: getQueryParam(query, 'userHash')
}); };
exports.getSignupCompleteQueryParams = getSignupCompleteQueryParams;
