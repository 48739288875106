import type { LoadedTeamResponse } from '../actions/team-actions';

export type TeamState = LoadedTeamResponse & {
  isLoading: boolean;
  isLoaded: boolean;
  isSuperAdmin?: boolean;
};

export const initialTeamState: TeamState = {
  isLoaded: false,
  isLoading: false,
  isSuperAdmin: undefined,
  users: [],
  invites: [],
};
