import React from 'react';
import { AddressSearchBar } from './AddressSearchBar';
import { AddressSearchResult } from './AddressSearchResult/address-search-result';
import { InjectedProps } from './address-lookup-data';

type AddressLookupUIProps = InjectedProps;

export const AddressLookupUI: React.FunctionComponent<AddressLookupUIProps> = ({
  onSearch,
  onClear,
  onStartCampaign,
  ...rest
}) => {
  return (
    <div className="flex flex-col">
      <div className="md:max-w-lg w-full mx-auto relative z-50 mb-8 px-5">
        <span className="block text-sm leading-5 font-medium text-gray-700 mb-1">Enter an Address</span>
        <AddressSearchBar onSelected={onSearch} onClear={onClear} />
      </div>
      <div className="max-w-screen-lg w-full mx-auto relative z-0 px-3 md:px-5">
        <AddressSearchResult {...rest} />
      </div>
    </div>
  );
};
