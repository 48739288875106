import { MailDeliveryType, MapInstructionsStepType, ProspectCountStepType } from '../../types/campaign';
import { Polygon } from 'geojson';

export type CampaignCreatorState = {
  polygon: Polygon | null;
  prospectTotalCount: number;
  prospectCount: number | null;
  prospectCountStep: ProspectCountStepType;
  mailProgramId: number | null;
  isMailProgramEnabled: boolean | null;
  salesRepId: number | null;
  isSubmitting: boolean;
  mapInstructionStep: MapInstructionsStepType;
  onResetShape?: () => void;
  campaignTitle: string | null;
  mailDeliveryType: MailDeliveryType | null;
  mailDeliveryDate: Date | null;
};

export const initialCampaignCreatorState: CampaignCreatorState = {
  polygon: null,
  prospectTotalCount: 0,
  prospectCount: null,
  mailProgramId: null,
  isMailProgramEnabled: null,
  salesRepId: null,
  isSubmitting: false,
  mapInstructionStep: MapInstructionsStepType.SELECT,
  prospectCountStep: ProspectCountStepType.CALCULATING,
  onResetShape: undefined,
  campaignTitle: null,
  mailDeliveryType: null,
  mailDeliveryDate: null,
};
