import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  UserIcon,
  BuildingOffice2Icon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';
import { PolygonBlue } from '@platform/ui';
import { TagIcon } from '@heroicons/react/24/solid';
import { Portal } from '@platform/ui-helpers';
import { Account } from '../components/Account';
import { Billing } from '../components/Billing';
import { Tags } from '../components/Tags';
import { CampaignsPage } from '../components/Campaigns';
import Plan from '../components/Plan';
import CampaignsDetailsPage from '../components/Campaigns/details';
import { CampaignsNewPage } from '../components/Campaigns/new-campaign-page';
import { ErrorPage } from '../components/Error';
import { LandingUI, withUnauthenticationRequired } from '../components/Landing';
import { ProgramSettings, ProgramSettingsDetail } from '../components/ProgramSettings';
import { LoginRedirect, SignupComplete, SignupRedirect } from '../components/Signup';
import { AcceptInvite } from '../components/Signup/accept-invite';
import { TeamPage } from '../components/Team';
import { getAllPrimaryRoutes, getAllRoutes, getAllSecondaryRoutes } from './helpers';
import { MenuVisibility, UIRouteDefinition } from './types';
import { CompanyPage } from '../components/Company';
import { GettingStarted } from '../components/GettingStarted';
import { Dashboard } from '../components/Dashboard';
import {
  StripeCustomerPortalAddPaymentMethodRedirect,
  StripeCustomerPortalDetailsRedirect,
} from '../components/Billing/stripe-customer-portal-redirect';
import { CompanyOnboardingSurvey } from '../components/Signup/company-onboarding';

const routes: Record<Portal.UIRoutes, UIRouteDefinition> = {
  '/': {
    name: 'Home',
    component: withUnauthenticationRequired(LandingUI, '/dashboard'),
  },
  '/dashboard': {
    name: 'Dashboard',
    component: withAuthenticationRequired(Dashboard),
    menu: {
      visible: MenuVisibility.VISIBLE_PRIMARY,
      icon: Squares2X2Icon,
    },
  },
  '/campaigns': {
    name: 'Campaigns',
    component: withAuthenticationRequired(CampaignsPage),
    menu: {
      visible: MenuVisibility.VISIBLE_PRIMARY,
      icon: PolygonBlue,
      classes: 'campaigns',
      disabled: ({ permissions }) => {
        return !permissions?.canAccessCampaigns;
      },
    },
  },
  '/campaigns/new': {
    name: 'New Campaign',
    component: withAuthenticationRequired(CampaignsNewPage),
  },
  '/campaigns/:id': {
    name: 'Campaign Details',
    component: withAuthenticationRequired(CampaignsDetailsPage),
  },
  '/account': {
    name: 'My Account',
    component: withAuthenticationRequired(Account),
    menu: {
      visible: MenuVisibility.VISIBLE_SECONDARY,
      icon: UserIcon,
    },
  },
  '/company': {
    name: 'Company',
    component: withAuthenticationRequired(CompanyPage),
    menu: {
      visible: MenuVisibility.VISIBLE_SECONDARY,
      icon: BuildingOffice2Icon,
    },
  },
  '/billing': {
    name: 'Billing',
    component: withAuthenticationRequired(Billing),
    menu: {
      visible: MenuVisibility.VISIBLE_SECONDARY,
      icon: QuestionMarkCircleIcon,
      disabled: ({ isSuperAdmin, isImpersonating, userName, isSubscribed }) =>
        !userName || !isSubscribed || (!isSuperAdmin && isImpersonating),
    },
  },
  // Deprecate
  '/billing/update_payment_method': {
    name: 'Add Payment Method',
    component: withAuthenticationRequired(StripeCustomerPortalAddPaymentMethodRedirect),
  },
  '/billing/add_payment_method': {
    name: 'Add Payment Method',
    component: withAuthenticationRequired(StripeCustomerPortalAddPaymentMethodRedirect),
  },
  '/billing/details': {
    name: 'Stripe Customer Portal',
    component: withAuthenticationRequired(StripeCustomerPortalDetailsRedirect),
  },
  '/team': {
    name: 'Team',
    component: withAuthenticationRequired(TeamPage),
    menu: {
      visible: MenuVisibility.VISIBLE_SECONDARY,
      icon: UserGroupIcon,
    },
  },
  '/tags': {
    name: 'Tags',
    component: withAuthenticationRequired(Tags),
    menu: {
      visible: MenuVisibility.VISIBLE_SECONDARY,
      icon: TagIcon,
      disabled: ({ permissions }) => {
        return !permissions?.canManageTags;
      },
    },
  },
  '/team/:id/join': {
    name: 'Accept Invite',
    component: AcceptInvite,
  },
  '/error': {
    name: 'Error',
    component: ErrorPage,
  },
  '/program-settings': {
    name: 'Marketing',
    component: withAuthenticationRequired(ProgramSettings),
    menu: {
      visible: MenuVisibility.VISIBLE_PRIMARY,
      icon: PaperAirplaneIcon,
      classes: 'automations',
      disabled: ({ hasPrograms }) => !hasPrograms,
    },
  },
  '/program-settings/:id': {
    name: 'ProgramSettings',
    component: withAuthenticationRequired(ProgramSettingsDetail),
  },
  '/signup/complete': {
    name: 'Signup',
    component: SignupComplete,
  },
  '/signup': {
    name: 'Signup',
    component: withUnauthenticationRequired(SignupRedirect, '/dashboard'),
  },
  '/login': {
    name: 'Login',
    component: withUnauthenticationRequired(LoginRedirect, '/dashboard'),
  },
  '/plan': {
    name: 'Plan',
    component: withAuthenticationRequired(Plan),
  },
  '/getting-started': {
    name: 'Getting Started',
    component: withAuthenticationRequired(GettingStarted),
  },
  '/survey/company-onboarding': {
    name: 'Onboarding Survey',
    component: withAuthenticationRequired(CompanyOnboardingSurvey),
  },
};

export const getRoutes = () => getAllRoutes(routes);
export const getPrimaryRoutes = () => getAllPrimaryRoutes(routes);
export const getSecondaryRoutes = () => getAllSecondaryRoutes(routes);
