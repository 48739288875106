import { UserPermissions } from '@platform/model/dist/types/user';
import { Portal } from '@platform/ui-helpers';

export enum MenuVisibility {
  NOT_VISIBLE,
  VISIBLE_PRIMARY,
  VISIBLE_SECONDARY,
}

export type UIMenuDefinition = {
  visible: MenuVisibility;
  icon: React.JSXElementConstructor<any>;
  classes?: string;
  disabled?:
    | boolean
    | ((props: {
        isSuperAdmin: boolean;
        isImpersonating: boolean;
        userName?: string;
        isSubscribed: boolean | null;
        permissions?: UserPermissions;
        hasPrograms?: boolean;
      }) => boolean);
};

export type UIRouteDefinition = {
  name: string;
  component: React.ComponentType<any>;
  menu?: UIMenuDefinition;
};

export type FullUIRouteDefinition = UIRouteDefinition & { route: Portal.UIRoutes };
export type FullUIMenuRouteDefinition = FullUIRouteDefinition & { menu: UIMenuDefinition };
