import { Drawer, ModalHeaderToolbar, ModalHeaderToolbarProps } from '@platform/ui';
import React from 'react';

export const DetailModal: React.FC<
  React.PropsWithChildren<{
    onClose: () => void;
    isOpen: boolean;
    onModalDidPresent?: () => void;
    headerProps: ModalHeaderToolbarProps;
  }>
> = ({ onClose, isOpen, headerProps, onModalDidPresent, children }) => (
  <Drawer isOpen={isOpen} onClose={onClose} onDidPresent={onModalDidPresent}>
    <ModalHeaderToolbar {...headerProps} />
    <div className="flex flex-col p-6 bg-white">{children}</div>
  </Drawer>
);
