import { PortalAPI } from '@platform/api';

// DEPRECATED__3_10_0
// Remove the 'Omit'when deprecation is removed
export type LoadedCompany = Omit<PortalAPI.UserAPI.GetUserResponse['company'], 'homeownerDataSubscription'>;

export type CompanyState = { [P in keyof LoadedCompany]: LoadedCompany[P] | undefined } & {
  isLoading: boolean;
  isLoaded: boolean;
} & {
  parent?: PortalAPI.CompanyAPI.ParentCompanyAssociation;
  children?: PortalAPI.CompanyAPI.ChildCompanyAssociation[];
  all: {
    list: PortalAPI.CompanyAPI.GetCompanyAllResponse;
    loaded: boolean;
    loading: boolean;
  };
};

export const initialCompanyState: CompanyState = {
  isLoaded: false,
  isLoading: false,
  billingEmail: undefined,
  prospectDataSubscription: 'none',
  hasCampaigns: false,
  createdAt: undefined,
  externalId: undefined,
  name: undefined,
  stripeSubscriptionId: undefined,
  stripeCustomerId: undefined,
  formSubmissionEmail: undefined,
  phoneNumber: undefined,
  website: undefined,
  users: 0,
  hasPrograms: false,
  all: {
    list: [],
    loaded: false,
    loading: false,
  },
};
