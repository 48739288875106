import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { ClickSquareIcon, PolygonIcon, Spinner, Tooltip, TooltipContent } from '@platform/ui';
import classNames from 'classnames';
import { ProspectCountStepType } from '../../types/campaign';

export interface ProspectCountCardProps {
  step: ProspectCountStepType;
  total: number;
  onReset: () => void;
}

export const ProspectCountCard = ({
  step = ProspectCountStepType.INSTRUCTIONS,
  total = 0,
  onReset,
}: ProspectCountCardProps) => {
  const numberOfProspects = total.toString();
  return (
    <div className="flex flex-col shadow px-6 py-7 bg-white rounded-lg">
      {step === ProspectCountStepType.INSTRUCTIONS && (
        <div className="flex flex-col items-center">
          <PolygonIcon className="h-[40px] w-[40px] text-amber-500" />
          <h1 className="text-xl font-bold leading-7 text-gray-900 py-2 mt-7">
            Adjust the shape on the map to update the address count
          </h1>
        </div>
      )}
      {step === ProspectCountStepType.CALCULATING && (
        <div className="flex flex-col items-center">
          <Spinner size="large" variant="primary" />
          <h1 className="text-xl font-bold leading-7 text-gray-900 py-2 mt-1">Calculating...</h1>
        </div>
      )}
      {step === ProspectCountStepType.SELECT && (
        <div className="flex flex-col">
          <div className="flex items-center md:justify-center relative h-10">
            <div className="flex items-center">
              <h1 className="text-xl font-bold leading-7 text-gray-900 mr-2">
                <span
                  className={classNames(
                    {
                      // this prevents the text to move on every number change
                      'min-w-[54px]': numberOfProspects.length === 4,
                      'min-w-[40px]': numberOfProspects.length === 3,
                      'min-w-[28px]': numberOfProspects.length === 2,
                      'min-w-[16px]': numberOfProspects.length === 1,
                    },
                    'inline-flex md:justify-end'
                  )}
                >
                  {total} Addresses
                </span>
              </h1>
              <Tooltip
                bottom={28}
                buttonContent={<InformationCircleIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />}
                content={
                  <TooltipContent
                    content={[
                      {
                        text: 'The estimated number of addresses in the selection',
                        list: [
                          {
                            icon: ClickSquareIcon,
                            text: 'Move or edit the shape on the map to update the address count.',
                          },
                        ],
                      },
                    ]}
                    links={[]}
                  />
                }
              />
            </div>
            <button
              onClick={onReset}
              type="button"
              className="inline-flex absolute right-0 text-red-500 hover:text-red-600 active:text-red-500 items-center bg-white h-10 focus:outline-none"
            >
              <span className="text-sm leading-5 font-medium ml-2">
                Reset <span className="hidden md:inline">shape</span>
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
