import { useMemo } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { withAuthenticatedPageLayout, WithAuthenticatedPageInjectedProps } from '../Layout/authenticated-page';
import Map from '../Map';
import { EmptyState } from '../Map/EmptyState';
import { Polygon } from 'geojson';
import { LoadedMapCampaign } from '../../redux/actions/campaign-map-actions';
import { CampaignAreas } from './campaign-map-marker';
import { Utils, Portal } from '@platform/ui-helpers';
import { CampaignsListUI, CampaignUIProps } from './campaigns-list/campaigns-list.ui';
import { MapUILoading } from '../Map/MapUI';
import { PageHeader } from '../Layout/PageHeader';

interface CampaignsPageUIProps extends WithAuthenticatedPageInjectedProps {
  campaignsMap: {
    campaigns: (LoadedMapCampaign & { tooltip?: Utils.TooltipContent })[];
    campaignsLoaded: boolean;
    campaignsLoading: boolean;
    boundingBox?: Polygon;
  };
  campaignsList: CampaignUIProps;
}

export const CampaignsPageUI = withAuthenticatedPageLayout<CampaignsPageUIProps>(({ campaignsMap, campaignsList }) => {
  const mapData = useMemo(
    () =>
      campaignsMap.campaigns.map((campaign) => {
        return {
          center: campaign.center,
          radius: campaign.radius,
          tooltip: campaign.tooltip,
        };
      }),
    [campaignsMap]
  );

  return (
    <div>
      <PageHeader
        icon={PaperAirplaneIcon}
        iconClasses="transform -rotate-45 -mt-0.5 mr-1.5 h-5 w-5 text-gray-400"
        iconHeading="Campaigns"
        title="All Campaigns"
        newLink={{
          url: Portal.getRouteName('/campaigns/new'),
          text: 'New Campaign',
          visible: true,
        }}
      />

      {!campaignsMap.campaignsLoaded ? (
        <MapUILoading classes="rounded-lg lg:rounded-none" fullHeight={false} />
      ) : campaignsMap.campaignsLoaded &&
        campaignsList.loaded &&
        !campaignsMap.campaigns.length &&
        !campaignsList.campaigns.length ? (
        <EmptyState />
      ) : (
        <div className="flex flex-col px-4 pt-4 lg:p-0">
          <Map boundingBox={campaignsMap.boundingBox} classes="rounded-lg lg:rounded-none">
            <CampaignAreas markers={mapData} />
          </Map>
        </div>
      )}
      {(!campaignsList.loaded || !!campaignsList.campaigns.length) && (
        <div className="flex flex-col max-w-6xl mx-auto px-4 lg:px-8 pt-5 pb-8">
          <CampaignsListUI {...campaignsList} />
        </div>
      )}
    </div>
  );
});
