import * as React from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { UseFormRegister, FieldErrors, FieldPath, Controller, Control } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import type { PortalAPI } from '@platform/api';
import lsi_logo from '../../assets/LSLogo_red_500w.png';
import { Spinner } from '../Spinner';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';

interface UserProps {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export type AcceptInviteUIProps = {
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  register: UseFormRegister<PortalAPI.UserAPI.AcceptInviteRequest>;
  errors?: FieldErrors<PortalAPI.UserAPI.AcceptInviteRequest>;
  user: UserProps;
  submitting: boolean;
  control: Control<PortalAPI.UserAPI.AcceptInviteRequest>;
};

const InputError = (props: {
  errors?: FieldErrors<PortalAPI.AuthAPI.SignupPayload>;
  name: FieldPath<PortalAPI.AuthAPI.SignupPayload>;
}) => (
  <ErrorMessage
    errors={props.errors}
    name={props.name}
    render={({ message }) => <span className="text-sm text-red-500">{message}</span>}
  />
);

const TermsLink: React.FunctionComponent<{ url: string; title: string }> = ({ url, title }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">
    {title}{' '}
  </a>
);

const inputClasses =
  'block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm disabled:bg-slate-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none';

export const AcceptInviteUI: React.FunctionComponent<AcceptInviteUIProps> = (props) => {
  return (
    <div className="min-h-full flex flex-col justify-center p-5">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 w-auto" src={lsi_logo} alt="Logo" />
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 text-center">
        <h2 className="text-2xl font-bold text-gray-800">Account Setup</h2>
        <p className="mt-2 text-md text-gray-600">Enter your account details below to create your account.</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="sm:bg-white py-8 px-4 sm:shadow sm:rounded-lg sm:px-10">
          <form onSubmit={props.handleSubmit} className="space-y-6" action="#" method="POST">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  className={inputClasses}
                  {...props.register('firstName', { required: 'First Name is required' })}
                />
                <InputError errors={props.errors} name="firstName" />
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  className={inputClasses}
                  {...props.register('lastName', { required: 'Last Name is required' })}
                />
                <InputError errors={props.errors} name="lastName" />
              </div>
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                Mobile Phone Number
              </label>
              <div className="mt-1">
                <Controller
                  name="phoneNumber"
                  control={props.control}
                  rules={{
                    required: 'Phone number is required',
                    validate: (value) => (isValidPhoneNumber(value) ? true : 'Invalid Phone Number'),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      value={value}
                      onChange={onChange}
                      defaultCountry="US"
                      placeholder="1 (123) 456-7890"
                      id="phoneNumber"
                      className={inputClasses}
                    />
                  )}
                />
                <InputError errors={props.errors} name="phoneNumber" />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  {...props.register('terms', { required: 'Must accept Terms & Privacy Policy' })}
                />
                <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
                  I agree to {<TermsLink url="https://leadscoutapp.com/terms" title="Terms" />} &amp;{' '}
                  {<TermsLink url="https://leadscoutapp.com/privacy" title="Privacy Policy" />}
                </label>
              </div>
            </div>
            <InputError errors={props.errors} name="terms" />
            <div>
              <button
                disabled={props.submitting}
                className="w-full sm:text-base text-sm leading-4 font-semibold text-white h-[40px] sm:h-[52px] bg-blue-500 hover:bg-blue-600 active:bg-blue-500 inline-flex justify-center items-center rounded-lg border border-transparent px-3 sm:px-6 shadow-sm focus:outline-none"
              >
                {props.submitting ? <Spinner /> : 'Continue'}
                <ArrowRightIcon className="ml-2 mt-1.5 h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
