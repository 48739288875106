"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateRGBStyle = exports.HSLtoString = exports.generateHSLStyle = exports.generateHSL = void 0;
var colortranslator_1 = require("colortranslator");
var getHashOfString = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};
var normalizeHash = function (hash, min, max) {
    return Math.floor((hash % (max - min)) + min);
};
var hRange = [0, 360];
var sRange = [40, 60];
var lRange = [40, 60];
var generateHSL = function (value) {
    var hash = getHashOfString(value);
    var h = normalizeHash.apply(void 0, __spreadArray([hash], hRange, false));
    var s = normalizeHash.apply(void 0, __spreadArray([hash], sRange, false));
    var l = normalizeHash.apply(void 0, __spreadArray([hash], lRange, false));
    return [h, s, l];
};
exports.generateHSL = generateHSL;
var generateHSLStyle = function (value) {
    return (0, exports.HSLtoString)((0, exports.generateHSL)(value));
};
exports.generateHSLStyle = generateHSLStyle;
var HSLtoString = function (hsl) {
    return "hsl(".concat(hsl[0], ", ").concat(hsl[1], "%, ").concat(hsl[2], "%)");
};
exports.HSLtoString = HSLtoString;
var generateRGBStyle = function (value) {
    return new colortranslator_1.ColorTranslator((0, exports.generateHSLStyle)(value)).RGB;
};
exports.generateRGBStyle = generateRGBStyle;
