import { Menu } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { PortalAPI } from '@platform/api';
import { Dropdown } from '../../Dropdown';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

type CompanyListItem = PortalAPI.CompanyAPI.ChildCompanyAssociation;

interface CompanyListUIProps {
  companies: CompanyListItem[];
  isLoaded: boolean;
  isLoading: boolean;
  onResendInvitation: (id: number) => void;
  onDeleteAssociation: (id: number) => void;
}

export const CompanyListUI = (props: CompanyListUIProps) => {
  const hasSponsorships = useMemo(() => props.companies.some((c) => !!c.sponsorship), [props.companies]);
  const rows = useMemo(
    () =>
      orderBy(props.companies, (a) => a.invitedAt, 'desc').map((company, i) => (
        <CompanyListRow
          key={i}
          association={company}
          onDeleteAssociation={props.onDeleteAssociation}
          onResendInvitation={props.onResendInvitation}
          hasSponsorships={hasSponsorships}
        />
      )),
    [hasSponsorships, props]
  );

  return (
    <div>
      <div className="flex flex-col max-w-6xl mx-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="flex items-center justify-center md:shadow md:bg-white">
            <div className="w-full">
              <div className="w-full">
                <div
                  className={`hidden md:grid grid-cols-12 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 md:rounded-t-md`}
                >
                  <div className={`p-3 border-b border-gray-200 col-span-3`}>Name</div>
                  <div className={`p-3 border-b border-gray-200 col-span-4`}> Email</div>
                  {hasSponsorships && <div className={`p-3 border-b border-gray-200 col-span-2`}> Sponsorship</div>}
                  <div className={`p-3 border-b border-gray-200 col-span-${hasSponsorships ? 2 : 4}`}>Status</div>
                  <div className="p-3 border-b border-gray-200 col-span-1">
                    <span className="hidden">Action</span>
                  </div>
                </div>

                <div className={classNames({ 'animate-pulse': props.isLoading }, 'flex-1 sm:flex-none w-full')}>
                  {props.isLoading ? [1, 2, 3].map((i) => <CompanyListEmptyRow key={i} />) : rows}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyListEmptyRow = () => (
  <div className="flex flex-1 md:grid flex-wrap md:grid-cols-12 items-center shadow border-b border-gray-200 bg-white md:shadow-none md:bg-transparent mb-2 md:mb-0 rounded-md md:rounded-none">
    <div className={`p-3 order-1 w-1/4 md:w-auto md:col-span-3`}>
      <div className="flex flex-grow items-center">
        <div className="h-4 bg-gray-100 rounded w-full block"></div>
      </div>
    </div>
    <div className={`p-3 order-2 w-3/4 md:w-auto md:col-span-4`}>
      <div className="flex flex-grow items-center">
        <div className="h-4 bg-gray-100 rounded w-3/4 block"></div>
      </div>
    </div>
    <div className={`p-3 order-3 w-3/4 md:w-auto md:col-span-4`}>
      <div className="flex flex-grow items-center">
        <div className="w-full">
          <div className="h-4 bg-gray-100 rounded w-4/6 block"></div>
        </div>
      </div>
    </div>
    <div className="p-3 md:pr-6 order-4 w-1/4 md:w-auto text-right md:col-span-1 text-sm text-gray-900 self-start md:self-auto">
      <button
        type="button"
        className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400 pointer-events-none" aria-hidden="true" />
      </button>
    </div>
  </div>
);

const CompanyListRow = (props: {
  association: CompanyListItem;
  onDeleteAssociation: (id: number) => void;
  onResendInvitation: (id: number) => void;
  hasSponsorships: boolean;
}) => {
  return (
    <div
      className={`flex md:grid flex-wrap md:grid-cols-12 items-center shadow border-b border-gray-200 bg-white md:shadow-none md:bg-transparent mb-2 md:mb-0 rounded-md md:rounded-none`}
    >
      <div className={`p-3 order-1 md:col-span-3 justify-center`}>{props.association.companyName}</div>
      <div className={`p-3 md:w-auto order-3 md:col-span-4`}>
        <div className="text-sm font-medium text-gray-900">{props.association.userEmail}</div>
      </div>
      {props.hasSponsorships && (
        <div className={`p-3 md:w-auto order-4 md:col-span-2`}>
          <div className="text-sm font-medium text-gray-900">{props.association.sponsorship ?? ''}</div>
        </div>
      )}
      <div className={`p-3 order-5 md:col-span-${props.hasSponsorships ? 2 : 4} w-3/4 md:w-auto`}>
        <div className="flex flex-grow items-center">
          <div className="">
            <div className="text-sm font-medium text-gray-900">
              {!props.association.acceptedAt
                ? `${props.association.userId ? 'Joined' : 'Invited'} ${moment(props.association.invitedAt).format(
                    'L'
                  )}`
                : `Member since ${moment(props.association.acceptedAt).format('L')}`}
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 md:pr-6 order-5 text-right  md:col-span-1 text-sm text-gray-900 self-start md:self-auto w-1/4 md:w-auto">
        <Dropdown
          position="right"
          data={[
            {
              text: 'Resend Invitation',
              onClick: () => props.onResendInvitation(props.association.inviteId),
              showDivider: true,
              isDisabled: !!props.association.userId,
            },
            {
              text: !props.association.acceptedAt ? `Delete Invitation` : `Remove`,
              onClick: () => props.onDeleteAssociation(props.association.id),
              isDisabled: !!props.association.sponsorship && !!props.association.acceptedAt,
            },
          ]}
        >
          <Menu.Button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400 pointer-events-none" aria-hidden="true" />
          </Menu.Button>
        </Dropdown>
      </div>
    </div>
  );
};
