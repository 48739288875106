import { PortalAPI } from '@platform/api';

export interface TagState {
  isLoading: boolean;
  isLoaded: boolean;
  data: {
    tags: Record<string, PortalAPI.TagAPI.TagWithProspectCount>;
    list: string[];
  }
}

export const initialTagsState: TagState = {
  isLoading: false,
  isLoaded: false,
  data: {
    tags: {},
    list: []
  },
};
