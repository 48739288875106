import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { LeadStatusState } from '../state/lead-status-state';
import { sortBy } from 'lodash';

export const leadStatusReducer: AppReducer<'LeadStatus'> = (state = initialState.LeadStatus, action): typeof state => {
  switch (action.type) {
    case 'LeadStatus/loaded':
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        // These should be ordered already but just in case
        order: sortBy(action.payload.leadStatuses, ({ order }) => order).map((leadStatus) => leadStatus.id),
        leadStatuses: action.payload.leadStatuses.reduce<LeadStatusState['leadStatuses']>((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}),
      };
    case 'LeadStatus/loading':
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};
