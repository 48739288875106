const CHECK_SUBSCRIPTION_STATUS_TIMESTAMP_STORAGE_KEY = 'SubscriptionStatusTimestamp';

export const saveSubscriptionStatusTimestamp = (date: Date) => {
  const currentTimestamp = date.getTime();
  localStorage.setItem(CHECK_SUBSCRIPTION_STATUS_TIMESTAMP_STORAGE_KEY, currentTimestamp.toString());
};

export const getSubscriptionStatusTimestamp = () => {
  const ts = localStorage.getItem(CHECK_SUBSCRIPTION_STATUS_TIMESTAMP_STORAGE_KEY);
  if (!ts) {
    return null;
  }
  return new Date(parseInt(ts, 10));
};

export const shouldRecheckSubscription = (lastChecked: Date | null) => {
  if (!lastChecked) {
    return true;
  }

  const currentTimestamp = new Date().getTime();
  const twelveHoursInMs = 12 * 60 * 60 * 1000;

  return currentTimestamp - lastChecked.getTime() >= twelveHoursInMs;
};

export const clearSubscriptionStatusTimestamp = () =>
  localStorage.removeItem(CHECK_SUBSCRIPTION_STATUS_TIMESTAMP_STORAGE_KEY);
