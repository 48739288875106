"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.StripeWebhookAPI = void 0;
var helpers_1 = require("@platform/helpers");
var StripeWebhookAPI;
(function (StripeWebhookAPI) {
    StripeWebhookAPI["POST /stripe/webhook"] = "POST /stripe/webhook";
    StripeWebhookAPI["POST /stripe/mixpanel-sync"] = "POST /stripe/mixpanel-sync";
})(StripeWebhookAPI = exports.StripeWebhookAPI || (exports.StripeWebhookAPI = {}));
exports.RouteDefinitions = __assign(__assign({}, (0, helpers_1.unauthenticatedRoute)('POST /stripe/webhook')), (0, helpers_1.unauthenticatedRoute)('POST /stripe/mixpanel-sync'));
