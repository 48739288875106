import { Point } from 'geojson';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import markerIcon from '../../../assets/jobsite.png';
import { Utils } from '@platform/ui-helpers';
import { TooltipText } from './TooltipText';

const fromPoint: (p: Point) => L.LatLngExpression = (p: Point) => ({
  lat: p.coordinates[1],
  lng: p.coordinates[0],
});

const pinIcon = new Icon({
  iconUrl: markerIcon,
  iconSize: [28, 28],
  iconAnchor: [14, 10],
});

interface JobSiteMarkerProps {
  position: Point;
  tooltipData?: Utils.TooltipContent;
}

export const JobSiteMarker = ({ position, tooltipData }: JobSiteMarkerProps) => {
  const data = typeof tooltipData === 'string' ? [tooltipData] : tooltipData;
  return (
    <Marker position={fromPoint(position)} icon={pinIcon}>
      {data && (
        <Popup>
          {data.map((item, index) => (
            <TooltipText key={index} content={item} />
          ))}
        </Popup>
      )}
    </Marker>
  );
};
