import React from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import PhoneInput, { Value, Country } from 'react-phone-number-input/input';

interface InputGroupProps {
  title: string;
  id?: string;
  value?: string | null;
  type?: string;
  name: string;
  onChange: ((value?: Value | undefined) => void) & React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  errors?: FieldError;
  isPhoneNumber?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const InputGroup = React.forwardRef<HTMLInputElement, InputGroupProps>(
  (
    {
      title,
      id,
      value,
      type = 'text',
      name,
      onChange,
      placeholder,
      errors,
      isPhoneNumber = false,
      isDisabled = false,
      isLoading = false,
    },
    ref
  ) => {
    const customProps: { defaultCountry?: Country } = isPhoneNumber ? { defaultCountry: 'US' } : {};
    const Input = isPhoneNumber ? PhoneInput : 'input';

    return (
      <div className="flex-column flex-1">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          {title}
        </label>
        <div className="mt-1">
          {isLoading ? (
            <div className="h-9 bg-gray-100 rounded w-1/2 block animate-pulse"></div>
          ) : (
            <Input
              ref={ref}
              id={id}
              value={value ?? ''}
              type={type}
              name={name}
              onChange={onChange}
              className={classNames({
                'shadow-sm rounded-md block w-full sm:text-sm border-gray-300': true,
                'focus:ring-indigo-500 focus:border-indigo-500': errors?.message && errors?.message.length === 0,
                'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500':
                  !!errors?.message,
                'pointer-events-none opacity-50': isDisabled,
              })}
              placeholder={placeholder}
              {...customProps}
            />
          )}
        </div>
        {!!errors?.message && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errors?.message}
          </p>
        )}
      </div>
    );
  }
);
