import { useCallback, useRef, useEffect, useState } from 'react';
import { Drawer, ModalHeaderToolbar } from '@platform/ui';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppActions } from '../../redux/actions/app-actions';
import { createTag } from '../../redux/thunks';

export const CreateTagModal = ({ getAccessTokenSilently }: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const modalProps = useSelector(Selectors.getCreateTagModal);
  const dispatch = useTypedDispatch();
  const [value, setValue] = useState('');

  const onClose = useCallback(() => {
    setTimeout(() => {
      setValue('');
    }, 0);

    dispatch(AppActions.setCreateTagModalOpen(false));
  }, [dispatch]);

  useEffect(() => {
    if (modalProps) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [modalProps]);

  const onSave = useCallback(async () => {
    if (value) {
      await dispatch(createTag(getAccessTokenSilently, { value }));
      onClose();
    }
  }, [dispatch, getAccessTokenSilently, onClose, value]);

  return (
    <Drawer isOpen={!!modalProps} onClose={onClose}>
      <ModalHeaderToolbar
        onBack={onClose}
        onSave={onSave}
        title="Add Tag"
        isDisabled={!value}
        isLoading={false}
        hasShadow
      />
      <div className="flex flex-col p-6 bg-white">
        <input
          ref={inputRef}
          placeholder="Type a name for the tag"
          type="text"
          name="tag-input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="text-base text-gray-900 border border-gray-300 placeholder-gray-400 focus:border-blue-500 block w-full px-3 py-4 rounded-md focus:outline-none disabled:bg-slate-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
        />
        <p className="text-sm font-normal leading-5 text-center text-gray-500	mt-4 mb-8">
          Add a new tag that your entire team will be able to apply to any prospect.
        </p>
      </div>
    </Drawer>
  );
};
