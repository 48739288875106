import type { LoadedLeadStatus } from '../actions/lead-status-actions';

export type LeadStatusState = {
  isLoading: boolean;
  isLoaded: boolean;
  leadStatuses: Record<number, LoadedLeadStatus>;
  order: number[];
};

export const initialLeadStatusState: LeadStatusState = {
  isLoaded: false,
  isLoading: false,
  leadStatuses: {},
  order: [],
};
