"use strict";
exports.__esModule = true;
exports.NetWorthRanges = void 0;
exports.NetWorthRanges = {
    A: 'Less than $1',
    B: '$1 - $4,999',
    C: '$5,000 - $9,999',
    D: '$10,000 - $24,999',
    E: '$25,000 - $49,999',
    F: '$50,000 - $99,999',
    G: '$100,000 - $249,999',
    H: '$250,000 - $499,999',
    I: 'Greater than $499,999'
};
