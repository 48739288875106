import { PlusIcon } from '@heroicons/react/24/outline';
import { Portal } from '@platform/ui-helpers';
import { Link } from 'react-router-dom';

interface PageHeaderProps {
  newLink?: {
    visible: boolean;
    url: Portal.UIRoutes;
    text: string | JSX.Element;
  };
  newAction?: {
    visible: boolean;
    onClick: () => void;
    text: string | JSX.Element;
  };
  title: string;
  icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  iconClasses?: string;
  iconHeading: string;
  lgMaxWidth?: `lg:max-w-${string}`;
}

export const PageHeader = (props: React.PropsWithChildren<PageHeaderProps>) => {
  return (
    <div className="bg-white shadow sticky top-0 z-10">
      <div className={`px-4 sm:px-6 ${props.lgMaxWidth ?? 'lg:max-w-6xl'} lg:mx-auto lg:px-8`}>
        <div className="pt-0 pb-7 lg:py-7 flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="flex-1 min-w-0">
            <div className="mb-2 flex flex-col sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="flex items-center">
                <props.icon
                  className={`shrink-0 ${props.iconClasses ?? 'mr-2 h-5 w-5 text-gray-400'}`}
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500 font-medium leading-5">{props.iconHeading}</span>
              </div>
            </div>
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{props.title}</h1>
          </div>
          {!!props.newLink && props.newLink.visible && (
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
              <Link to={props.newLink.url}>
                <span className="sm:ml-3">
                  <button
                    type="button"
                    className="sm:text-base text-sm leading-4 font-semibold text-white h-[40px] sm:h-[52px] bg-blue-500 hover:bg-blue-600 active:bg-blue-500 inline-flex justify-center items-center rounded-lg border border-transparent px-3 sm:px-6 shadow-sm focus:outline-none"
                  >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    {props.newLink.text}
                  </button>
                </span>
              </Link>
            </div>
          )}
          {!!props.newAction && props.newAction.visible && (
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
              <span className="sm:ml-3">
                <button
                  type="button"
                  className="sm:text-base text-sm leading-4 font-semibold text-white h-[40px] sm:h-[52px] bg-blue-500 hover:bg-blue-600 active:bg-blue-500 inline-flex justify-center items-center rounded-lg border border-transparent px-3 sm:px-6 shadow-sm focus:outline-none"
                  onClick={props.newAction.onClick}
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  {props.newAction.text}
                </button>
              </span>
            </div>
          )}
        </div>
        {props.children}
      </div>
    </div>
  );
};
