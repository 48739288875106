import { useAuth0 } from '@auth0/auth0-react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { createPolygonSquare } from '@platform/helpers';
import { Polygon, Position } from 'geojson';
import { Map } from 'mapbox-gl';
import React, { useCallback, useMemo } from 'react';
import { IControl } from 'react-map-gl';
import { batch } from 'react-redux';
import { Actions } from '../../../../redux/actions';
import { useTypedDispatch } from '../../../../redux/state';
import { getCampaignProspectCount } from '../../../../redux/thunks';
import { MapInstructionsStepType } from '../../../../types/campaign';
import { drawStyles } from './utils';

export interface Props {
  map: React.MutableRefObject<Map>;
  initialPoint: Position;
}

const useDraw = ({ map, initialPoint }: Props) => {
  const dispatch = useTypedDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const draw = useMemo(
    () =>
      new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: false,
          trash: false,
        },
        defaultMode: 'simple_select',
        styles: drawStyles,
      }),
    []
  );

  const formatPolygon = useCallback(
    (polygon: Polygon) => {
      batch(() => {
        dispatch(Actions.CampaignCreator.setPolygon(polygon));
        dispatch(getCampaignProspectCount(getAccessTokenSilently, polygon));
      });
    },
    [dispatch, getAccessTokenSilently]
  );

  const refreshAreaAndCount = useCallback(() => {
    const data = draw.getAll();
    const [feature] = data.features;
    const polygon = feature.geometry as Polygon;
    formatPolygon(polygon);
    return polygon;
  }, [draw, formatPolygon]);

  const onClickArea = () => {
    if (draw.getMode() === draw.modes.DIRECT_SELECT) {
      dispatch(Actions.CampaignCreator.setMapInstructionStep(MapInstructionsStepType.ADJUST));
    } else {
      dispatch(Actions.CampaignCreator.setMapInstructionStep(MapInstructionsStepType.SELECT));
    }
  };

  const onCreatePolygon = (squareDistanceKm: number) => {
    if (initialPoint.length) {
      draw.deleteAll();
      const polygon = createPolygonSquare(initialPoint, squareDistanceKm);
      formatPolygon(polygon);
      draw.add(polygon);
      return polygon;
    }
  };

  const onInit = () => {
    map.current.addControl(draw as IControl);
    map.current.on('draw.update', refreshAreaAndCount);
    map.current.on('click', onClickArea);
  };

  return { onInit, onCreatePolygon, refreshAreaAndCount } as const;
};

export default useDraw;
