import { PortalAPI } from '@platform/api';
import { toPosition } from '@platform/helpers';
import {
  breakpoints,
  ClickSquareIcon,
  FocusSpecification,
  InstructionsCard,
  PolygonIcon,
  SelectedIconMap,
} from '@platform/ui';
import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { useSelector } from 'react-redux';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { AddressResponse } from '../../../redux/actions/address-lookup-actions';
import { Selectors } from '../../../redux/selectors';
import { Map } from '../../MapBox';

export interface AddressSearchResultUIProps {
  loaded: boolean;
  loading: boolean;
  address: AddressResponse;
}

export const AddressSearchResultUI: React.FunctionComponent<AddressSearchResultUIProps> = ({ address }) => {
  const instructionStep = useSelector(Selectors.getMapInstructionStep);
  const isDesktop = useMediaQuery(breakpoints.lg);

  const boundingBox: FocusSpecification | null = useMemo(
    () =>
      address && PortalAPI.AddressAPI.addressLookupResponseIsValid(address)
        ? {
            type: 'center',
            center: toPosition(address.location),
            focal: 'location',
            focalId: '',
            paddingBottom: 0,
          }
        : null,
    [address]
  );

  const drawSpec = useMemo(
    () =>
      address && PortalAPI.AddressAPI.addressLookupResponseIsValid(address)
        ? {
            enabled: true,
            center: toPosition(address.location!),
          }
        : { enabled: false },
    [address]
  );

  const JobsiteMarker = useMemo(() => SelectedIconMap['hammer-light-blue-600-selected'], []);

  if (!address || !PortalAPI.AddressAPI.addressLookupResponseIsValid(address)) {
    return null;
  }

  return (
    <>
      <div className="relative rounded-2xl overflow-hidden">
        <div className="flex justify-center pt-2.5 absolute left-0 top-0 right-0 z-10 pl-2 px-14 md:pl-14 pointer-events-none">
          <InstructionsCard
            step={instructionStep}
            data={[
              {
                icon: <ClickSquareIcon />,
                text: 'Click on the outline on the map to move the shape, click twice to edit it',
              },
              {
                icon: <PolygonIcon className="text-amber-500" />,
                text: 'Adjust the outline on the map to increase or decrease address count',
              },
            ]}
          />
        </div>
        <Map
          height={isDesktop ? '550px' : '400px'}
          drawSpec={drawSpec}
          boundingBox={boundingBox}
          defaultBoxKm={address.recommendedBoxKm}
          clickHandlers={{}}
        >
          <Marker
            latitude={address.location.coordinates[1]}
            longitude={address.location.coordinates[0]}
            anchor="bottom"
          >
            <JobsiteMarker />
          </Marker>
        </Map>
      </div>
    </>
  );
};
