import { PortalAPI } from '@platform/api';
import { AxiosResponse } from 'axios';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../redux/actions/app-actions';
import { NotificationType } from '../../types/notification';
import { useQuery } from '../../hooks/use-query';
import { withUnauthenticatedPageLayout } from '../Layout/unauthenticated-page';
import { AcceptInviteUI } from './accept-invite.ui';

export const AcceptInvite = withUnauthenticatedPageLayout(() => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const token = query.get('token');
  const payload = useMemo(
    () => jwt_decode(token!) as (JwtPayload & PortalAPI.UserAPI.InviteTokenPayload) | null,
    [token]
  );

  if (!payload) {
    return <div />;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<PortalAPI.UserAPI.AcceptInviteRequest>({
    defaultValues: {
      firstName: payload.firstName,
      lastName: payload.lastName,
      phoneNumber: payload.phoneNumber ?? '',
    },
  });

  const onSubmit = useCallback(
    async (values: PortalAPI.UserAPI.AcceptInviteRequest) => {
      let ret: AxiosResponse<PortalAPI.UserAPI.Responses['POST /invite/accept']>;

      setSubmitting(true);
      try {
        ret = await PortalAPI.Client.getUnauthenticatedApiClient().request(
          {
            route: 'POST /invite/accept',
            queryParams: { token: token! },
          },
          { ...values }
        );
        if (ret.status === 200 && ret.data && !('error' in ret.data)) {
          window.location.href = ret.data.resetPasswordUrl;
        } else {
          dispatch(
            AppActions.notification({
              type: NotificationType.error,
              title: 'error' in ret.data ? ret.data.error : 'Unknown Error',
              description: 'Please contact support@leadscoutapp.com for support',
            })
          );
        }
      } catch (e: any) {
        const res = e.response as typeof ret;
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'error' in res.data ? res.data.error : 'Unknown Error',
            description: 'Please contact support@leadscoutapp.com for support',
          })
        );
      } finally {
        setSubmitting(false);
      }
    },
    [token, dispatch]
  );

  return (
    <AcceptInviteUI
      user={payload}
      submitting={submitting}
      errors={errors}
      handleSubmit={handleSubmit(onSubmit)}
      register={register}
      control={control}
    />
  );
});
