import * as Redux from 'redux';
import Thunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { Reducer } from '../reducers';
import { State } from '../state';
import { Actions } from '../actions';

export const configureStore = (state?: Partial<State>) => {
  return Redux.createStore(
    Reducer,
    (state as any) || {},
    composeWithDevTools(Redux.applyMiddleware(Thunk as ThunkMiddleware<State, Redux.Action<Actions['type']>>))
  );
};
