import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { ArrowLeftIcon, ArrowRightIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';
import {
  DayPicker,
  DayProps,
  CaptionProps,
  useNavigation,
  useDayRender,
  DayPickerDefaultProps,
  Matcher,
} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import classNames from 'classnames';

type Variant = 'default' | 'inline';

const InlineCustomCaption = (props: CaptionProps & { defaultMonth: Date | null }) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const [initialMonth, setInitialMonth] = useState(false);

  useEffect(() => {
    if (props.defaultMonth && !initialMonth) {
      setInitialMonth(true);
      goToMonth(props.defaultMonth);
    }
  }, [goToMonth, initialMonth, props.defaultMonth]);

  return (
    <div className="flex items-center justify-between pl-2 mb-1">
      <h2 className="text-md font-semibold text-gray-900 leading-6">{format(props.displayMonth, 'MMMM yyyy')}</h2>
      <div className="flex items-center ml-2">
        <button
          className="inline-flex justify-center items-center bg-transparent hover:bg-gray-50 active:bg-gray-100 rounded-full h-9 w-9"
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
        >
          <ChevronLeftIcon className="h-7 w-7 text-blue-500" aria-hidden="true" />
        </button>
        <button
          className="inline-flex justify-center items-center bg-transparent hover:bg-gray-50 active:bg-gray-100 rounded-full h-9 w-9"
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
        >
          <ChevronRightIcon className="h-7 w-7 text-blue-500" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const CustomCaption = (props: CaptionProps & { defaultMonth: Date | null }) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const [initialMonth, setInitialMonth] = useState(false);
  useEffect(() => {
    if (props.defaultMonth && !initialMonth) {
      setInitialMonth(true);
      goToMonth(props.defaultMonth);
    }
  }, [goToMonth, initialMonth, props.defaultMonth]);

  return (
    <div className="flex flex-col items-center justify-center mb-2 md:mb-0 relative">
      <h2 className="text-lg leading-6 font-medium text-gray-900">{format(props.displayMonth, 'MMMM yyyy')}</h2>
      <button
        className="absolute right-[52px] md:right-[-66px] md:top-[119px] inline-flex justify-center items-center bg-gray-100 hover:bg-gray-200 active:bg-gray-100 rounded-full h-10 w-10"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <ArrowRightIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
      </button>
      <button
        className="absolute left-[52px] md:left-[-66px] md:top-[119px] inline-flex justify-center items-center bg-gray-100 hover:bg-gray-200 active:bg-gray-100 rounded-full h-10 w-10"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <ArrowLeftIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
      </button>
    </div>
  );
};

interface CustomWeekNumberProps extends DayProps {
  onClickDay: (date: Date) => void;
  variant: Variant;
}

const CustomWeekNumber = ({ date, onClickDay, displayMonth, variant }: CustomWeekNumberProps) => {
  const buttonRef = useRef(null);
  const day = useDayRender(date, displayMonth, buttonRef);
  const { selected, today, outside, disabled } = day.activeModifiers;

  return (
    <button
      ref={buttonRef}
      onClick={() => onClickDay(date)}
      disabled={outside || disabled}
      className={classNames(
        {
          'opacity-50': outside,
          'bg-white': outside && !disabled,
          'border-gray-200 bg-gray-100': disabled || outside,
          'border-blue-500 bg-blue-500': selected,
          'border-gray-900': today && !selected,
          'border-gray-300': !today && !selected,
          'h-10 w-10': variant === 'default',
          'h-11 w-11': variant === 'inline',
        },
        'inline-flex items-center justify-center border border-solid rounded-lg m-1 md:m-[5px]'
      )}
    >
      <span
        className={classNames(
          { 'text-white': selected, 'text-gray-900': today, 'text-gray-500': !today && !selected },
          'text-base leading-6 font-semibold'
        )}
      >
        {format(date, 'd')}
      </span>
    </button>
  );
};

const CustomHead = ({ variant }: { variant?: Variant }) => {
  const days =
    variant === 'inline' ? ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] : ['s', 'm', 't', 'w', 'th', 'f', 's'];

  return (
    <thead>
      <tr className="rdp-head_row">
        {days.map((item, index) => {
          return (
            <th key={index} scope="col" className={classNames({ 'rdp-head_cell': variant === 'default' })}>
              <span
                className={classNames(
                  { 'text-base': variant === 'default', 'text-sm': variant === 'inline' },
                  'text-gray-400  font-semibold uppercase'
                )}
              >
                {item}
              </span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export interface CalendarProps extends DayPickerDefaultProps {
  disabled?: Matcher | Matcher[] | undefined;
  selectedDate: Date | null;
  onSelectDate: (date: Date) => void;
  variant?: Variant;
}

const Calendar = ({ disabled, selectedDate, onSelectDate, variant = 'default', ...props }: CalendarProps) => {
  const Caption = variant === 'inline' ? InlineCustomCaption : CustomCaption;

  const styles = variant === 'inline' ? { '--rdp-cell-size': '60px' } : {};

  return (
    <DayPicker
      // @ts-ignore
      style={styles}
      className="!m-0"
      components={{
        Caption: (props) => <Caption defaultMonth={selectedDate} {...props} />,
        Day: (props) => <CustomWeekNumber variant={variant} onClickDay={onSelectDate} {...props} />,
        Head: () => <CustomHead variant={variant} />,
      }}
      selected={selectedDate ?? undefined}
      disabled={disabled}
      showOutsideDays
      {...props}
    />
  );
};

export default Calendar;
