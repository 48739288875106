import * as React from 'react';
import { UseFormRegister, FieldErrors, FieldPath, Control, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import type { PortalAPI } from '@platform/api';
import { Spinner } from '../../components/Spinner';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

export type CompanyInviteUIProps = {
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  register: UseFormRegister<PortalAPI.CompanyAPI.CreateCompanyRequest>;
  errors?: FieldErrors<PortalAPI.CompanyAPI.CreateCompanyRequest>;
  submitting: boolean;
  control: Control<PortalAPI.CompanyAPI.CreateCompanyRequest>;
  onCancel: () => void;
};

const InputError = (props: {
  errors?: FieldErrors<PortalAPI.CompanyAPI.CreateCompanyRequest>;
  name: FieldPath<PortalAPI.CompanyAPI.CreateCompanyRequest>;
}) => (
  <ErrorMessage
    errors={props.errors}
    name={props.name}
    render={({ message }) => <span className="text-sm text-red-500">{message}</span>}
  />
);

const inputClasses =
  'block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm disabled:bg-slate-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none';

export const CompanyInviteUI: React.FunctionComponent<CompanyInviteUIProps> = (props) => {
  return (
    <div className="min-h-full flex flex-col justify-center p-5">
      <div className="sm:bg-white sm:mx-auto sm:w-full sm:max-w-md">
        <form onSubmit={props.handleSubmit} className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
              Company Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                className={inputClasses}
                {...props.register('companyName', { required: 'Company Name is required' })}
              />
              <InputError errors={props.errors} name="companyName" />
            </div>
          </div>
          <div>
            <label htmlFor="userFirstName" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                className={inputClasses}
                {...props.register('userFirstName', { required: 'First Name is required' })}
              />
              <InputError errors={props.errors} name="userFirstName" />
            </div>
          </div>
          <div>
            <label htmlFor="userLastName" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                className={inputClasses}
                {...props.register('userLastName', { required: 'Last Name is required' })}
              />
              <InputError errors={props.errors} name="userLastName" />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1">
              <input
                type="email"
                className={inputClasses}
                {...props.register('userEmail', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
              <InputError errors={props.errors} name="userEmail" />
            </div>
          </div>
          <div>
            <label htmlFor="userPhoneNumber" className="block text-sm font-medium text-gray-700">
              Phone Number (Optional)
            </label>
            <div className="mt-1">
              <Controller
                name="userPhoneNumber"
                control={props.control}
                rules={{
                  validate: (value) =>
                    !value || value.length === 0 || isValidPhoneNumber(value) ? true : 'Invalid Phone Number',
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    defaultCountry="US"
                    id="phoneNumber"
                    className={inputClasses}
                  />
                )}
              />
              <InputError errors={props.errors} name="userPhoneNumber" />
            </div>
          </div>
          <div className="flex flex-row py-4 justify-end">
            <button
              className="sm:text-base leading-4 mr-8 text-red-500 background-transparent font-bold text-sm outline-none focus:outline-none hover:text-red-600 hover:underline"
              type="button"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              disabled={props.submitting}
              className="sm:text-base text-sm leading-4 font-semibold text-white h-[40px] sm:h-[52px] bg-blue-500 hover:bg-blue-600 active:bg-blue-500 inline-flex justify-center items-center rounded-lg border border-transparent px-3 sm:px-6 shadow-sm focus:outline-none"
            >
              {props.submitting ? <Spinner className="h-5 w-5" /> : 'Send Invite'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
