import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { withUnauthenticatedPageLayout } from '../Layout/unauthenticated-page';
import { CompanySurveyAnswersV1 } from '@platform/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { submitSurvey } from '../../redux/thunks';
import { useAuth0 } from '@auth0/auth0-react';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { DEFAULT_STARTING_PLAN } from '@platform/app-config';
import { breakpoints, OnboardingSurvey } from '@platform/ui';
import useMediaQuery from '../../hooks/useMediaQuery';
import { PortalAPI } from '@platform/api';
import { Hooks, Portal } from '@platform/ui-helpers';

const SpecifyInput: React.FC<{
  value: string | null;
  onChange: (value: string) => void;
  placeholder?: string | null;
}> = ({ onChange, value, placeholder }) => {
  const inputRef = useRef<HTMLTextAreaElement>();
  const handleInput: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      onChange(e.target.value ?? '');
    },
    [onChange]
  );

  const onInputRef = useCallback(
    (node: HTMLTextAreaElement | null) => {
      inputRef.current = node ?? undefined;
      if (node && !value) {
        node.autocapitalize = 'on';
        setTimeout(() => node.focus(), 0);
      }
    },
    [value]
  );

  return (
    <textarea
      ref={onInputRef}
      placeholder={placeholder ?? ''}
      value={value ?? ''}
      onChange={handleInput}
      rows={2}
      style={{ fontSize: '14px' }}
      className="block bg-transparent w-full p-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring-0 sm:text-sm"
    />
  );
};

export const CompanyOnboardingSurvey = withUnauthenticatedPageLayout(() => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { boot } = useIntercom();
  const dispatch = useTypedDispatch();
  const { user } = useSelector(Selectors.user);
  const isDesktop = useMediaQuery(breakpoints.md);
  const location = useLocation();
  const { currentQuestion, setNextQuestion } = Hooks.useCompanyOnboardingQuestionManager(
    location.search,
    navigate,
    location.state
  );

  const state = useMemo(
    () => location.state as PortalAPI.AuthAPI.LocalRedirect<'/survey/company-onboarding'>['state'],
    [location.state]
  );

  useEffect(() => {
    if (user && user.intercomUserHash) {
      boot({
        userId: user.externalId,
        email: user.email,
        userHash: user.intercomUserHash,
        hideDefaultLauncher: !isDesktop,
      });
    }
  }, [boot, isDesktop, user]);

  const openWindow = useCallback((url: string) => window.open(url, '_blank'), []);

  const handleSubmit = useCallback(
    async (values: Partial<CompanySurveyAnswersV1>) => {
      dispatch(submitSurvey(getAccessTokenSilently, values));
    },
    [dispatch, getAccessTokenSilently]
  );

  const handleBack = useCallback(() => navigate(-1), [navigate]);

  const finishSurvey = useCallback(() => {
    if (state?.nextUrl) {
      if (!state.nextUrl.isExternal) {
        navigate(state.nextUrl.url, { replace: true, state: state.nextUrl.state });
      } else {
        window.location.href = state.nextUrl.url;
      }
    } else {
      navigate(Portal.getRouteName('/'));
    }
  }, [navigate, state?.nextUrl]);

  return (
    <div className="w-full h-full max-w-2xl px-6 mx-auto md:mt-8 md:py-12 py-4 lg:px-20 xl:px-24 bg-gray-100">
      <OnboardingSurvey
        openWindow={openWindow}
        freeTrialPeriod={state.freeTrialDays ?? DEFAULT_STARTING_PLAN.freeTrialPeriod}
        handleSubmit={handleSubmit}
        handleFinishSurvey={finishSurvey}
        handleBack={handleBack}
        currentQuestion={currentQuestion}
        setNextQuestion={setNextQuestion}
        SpecifyInput={SpecifyInput}
      />
    </div>
  );
});
