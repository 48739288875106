import axios, { AxiosError } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { PortalAPI } from '@platform/api';
import { Spinner } from '@platform/ui';
import { Portal, Utils } from '@platform/ui-helpers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import lsi_logo from '../../assets/LSLogo_red_500w.png';
import { withUnauthenticatedPageLayout } from '../Layout/unauthenticated-page';

const StripeCustomerPortalRedirect: React.FunctionComponent<{ type?: 'payment_method_update' }> =
  withUnauthenticatedPageLayout(({ type }) => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
      PortalAPI.Client.getApiClient(getAccessTokenSilently)
        .request<
          typeof PortalAPI.AuthAPI.API['GET /stripe-customer-portal'],
          PortalAPI.AuthAPI.QueryParams['GET /stripe-customer-portal']
        >({
          route: PortalAPI.AuthAPI.API['GET /stripe-customer-portal'],
          queryParams: {
            return_url: Utils.buildUIUrl(window.location.origin, { route: Portal.getRouteName('/billing') }),
            type,
          },
        })
        .then(
          ({ status, data }) => {
            if (status === 200 && !('error' in data)) {
              window.location.href = data.url;
            }
          },
          (e) => {
            let error: string | null = null;
            if (axios.isAxiosError(e)) {
              const res = ((e ?? {}) as AxiosError<PortalAPI.AuthAPI.Responses['GET /stripe-customer-portal']>)
                ?.response;
              if (res && 'error' in res.data) {
                error = res.data.error;
              }
            }
            navigate(
              Portal.buildUIErrorUrl(
                null,
                'Billing Portal Error',
                error ?? 'Please contact support@leadscoutapp.com for assistance.'
              )
            );
          }
        );
    }, [getAccessTokenSilently, navigate, type]);

    return (
      <div className="flex flex-col lg:max-w-6xl lg:mx-auto items-center">
        <img className="sm:w-1/4 w-1/2 pt-10" src={lsi_logo} alt="Lead Scout Logo" />
        <p className="text-center text-xl text-black-900 pt-20 pb-10">Fetching your billing details</p>
        <Spinner size="large" variant="neutral" />
      </div>
    );
  });

export const StripeCustomerPortalDetailsRedirect: React.FunctionComponent<{}> = () => {
  return <StripeCustomerPortalRedirect />;
};

export const StripeCustomerPortalAddPaymentMethodRedirect: React.FunctionComponent<{}> = () => {
  return <StripeCustomerPortalRedirect type="payment_method_update" />;
};
