import { Utils } from '@platform/ui-helpers';
import { AddressLookupState, initialAddressLookupState } from './address-lookup-state';
import { AppState, initialAppState } from './app-state';
import { CampaignCreatorState, initialCampaignCreatorState } from './campaign-creator-state';
import { ProspectsState, initialProspectsState } from './prospects-state';
import { CampaignsMapState, initialCampaignMapState } from './campaigns-map-state';
import { CampaignsState, initialCampaignState } from './campaigns-state';
import { CompanyState, initialCompanyState } from './company-state';
import { DashboardState, initialDashboardState } from './dashboard-state';
import { initialProgramsState, ProgramsState } from './programs-state';
import { initialTeamState, TeamState } from './team-state';
import { initialUserState, UserState } from './user-state';
import { initialLeadStatusState, LeadStatusState } from './lead-status-state';
import { initialTagsState, TagState } from './tag-state';

export type State = {
  AddressLookups: AddressLookupState;
  App: AppState;
  Campaigns: CampaignsState;
  CampaignsMap: CampaignsMapState;
  CampaignCreator: CampaignCreatorState;
  Company: CompanyState;
  Dashboard: DashboardState;
  LeadStatus: LeadStatusState;
  Programs: ProgramsState;
  Prospects: ProspectsState;
  Team: TeamState;
  User: UserState;
  Tags: TagState;
};

export const initialState: State = {
  AddressLookups: initialAddressLookupState,
  App: initialAppState,
  Campaigns: initialCampaignState,
  CampaignsMap: initialCampaignMapState,
  CampaignCreator: initialCampaignCreatorState,
  Company: initialCompanyState,
  Dashboard: initialDashboardState,
  LeadStatus: initialLeadStatusState,
  Programs: initialProgramsState,
  Prospects: initialProspectsState,
  Team: initialTeamState,
  User: initialUserState,
  Tags: initialTagsState,
};

export type TypedDispatch = Utils.TypedDispatch<State>;
export const useTypedDispatch = () => Utils.useTypedDispatch<State>();
