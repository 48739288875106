import { MailDetail } from '@platform/ui';
import { PortalAPI } from '@platform/api';
import { DetailModalProps } from './types';
import { DetailModal } from './modal';

export const MailActivityDetailsModal: React.FC<
  DetailModalProps<
    | PortalAPI.ProspectAPI.ActivityDataType.MAIL_DELIVERY
    | PortalAPI.ProspectAPI.ActivityDataType.MAIL_STARTED
    | PortalAPI.ProspectAPI.ActivityDataType.MAIL_STOPPED
    | PortalAPI.ProspectAPI.ActivityDataType.MAIL_RESTARTED
  >
> = ({ activity, safeAreaTop, onClose }) => (
  <DetailModal
    isOpen
    headerProps={{
      safeAreaTop,
      onBack: onClose,
      title: 'Mail Activity',
      isDisabled: false,
      isLoading: false,
    }}
    onClose={onClose}
  >
    <MailDetail {...activity} />
    {/* <img
      className="w-full rounded-2xl aspect-[16/9]"
      src="https://images.pexels.com/photos/27855638/pexels-photo-27855638/free-photo-of-neve-mar-preto-e-branco-p-b.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load"
      alt="mail front preview"
    />
    <img
      className="w-full rounded-2xl aspect-[16/9]"
      src="https://images.pexels.com/photos/27855638/pexels-photo-27855638/free-photo-of-neve-mar-preto-e-branco-p-b.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load"
      alt="mail back preview"
    /> */}
  </DetailModal>
);
