import { Portal } from '@platform/ui-helpers';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CampaignCreatorSelectors, Selectors } from '../../../redux/selectors';
import { useTypedDispatch } from '../../../redux/state';
import { createNewCampaign, loadPrograms } from '../../../redux/thunks';
import { GetAccessTokenFunction } from '../../../types/auth0';
import { StartCampaignButton } from '../../StartCampaignButton';
import SalesRep from './details';
import MailProgram from './mail-program';
import ProspectCount from './prospect-count';

export interface Props {
  getAccessToken: GetAccessTokenFunction;
}

const CampaignCreator = ({ getAccessToken }: Props) => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { address } = useSelector(Selectors.addressLookup);
  const isSubmitting = useSelector(CampaignCreatorSelectors.getIsSubmitting);
  const enableCampaignCreation = useSelector(Selectors.getEnableCampaignCreation);
  const canAddProgramToCampaign = useSelector(Selectors.canAccessMailPrograms);

  const onCreateCampaign = useCallback(() => {
    dispatch(createNewCampaign(getAccessToken, navigate));
  }, [dispatch, getAccessToken, navigate]);

  useEffect(() => {
    dispatch(loadPrograms(getAccessToken));
  }, [dispatch, getAccessToken]);

  if (!address) {
    return null;
  }

  return (
    <div className="flex flex-col px-2.5 md:px-8 -mt-10 relative z-10">
      <div className="flex flex-col w-full md:max-w-screen-sm md:mx-auto">
        <ProspectCount />
        <SalesRep getAccessToken={getAccessToken} />
        {canAddProgramToCampaign && <MailProgram />}
        <div className="flex flex-col md:mx-auto w-full md:max-w-md mt-10">
          <StartCampaignButton
            onClick={onCreateCampaign}
            size="large"
            isDisabled={!enableCampaignCreation}
            isLoading={isSubmitting}
            fullWidth
          />
          {canAddProgramToCampaign && (
            <p className="text-gray-500 text-center italic text-sm font-normal leading-5 mt-5">
              Prospect records, mail, and automations are priced and billed in accordance with your subscription terms.{' '}
              <a className="text-blue-500" href={Portal.getRouteName('/billing')} target="_blank" rel="noreferrer">
                View pricing
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignCreator;
