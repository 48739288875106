import { useState } from 'react';
import Select, { ActionMeta, InputActionMeta } from 'react-select';
import styles from './searchBar.module.css';

interface SearchBarProps<T> {
  options: T[];
  onInputChanged: (input: string) => void;
  onSelected: (selected: { selected: T; displayedValue: string }) => void;
  onClear: () => void;
  loading: boolean;
  displayTransformer?: (option: T) => string;
}

export const SearchBar = <T extends string | object>({
  loading,
  onInputChanged,
  onSelected,
  onClear,
  options,
  displayTransformer: transform = (s) => s.toString(),
}: SearchBarProps<T>) => {
  const [isSelected, setIsSelected] = useState(false);

  const onInputChange = (userInput: string, action: InputActionMeta) => {
    if (action?.action === 'input-change') {
      onInputChanged(userInput);
    }
  };

  const onChange = (userInput: T | null, opt: ActionMeta<T>) => {
    if (opt?.action === 'clear') {
      onClear();
    } else if (opt?.action === 'select-option' && userInput) {
      const displayedValue = transform(userInput);
      setIsSelected(true);
      onSelected({ selected: userInput, displayedValue });
    }
  };

  return (
    <Select
      className={styles.searchBarContainer}
      isClearable={isSelected}
      options={options}
      onInputChange={onInputChange}
      filterOption={() => true}
      onChange={onChange}
      placeholder="e.g. Lead or Jobsite Address"
      isLoading={loading && !options}
      noOptionsMessage={() => null}
      getOptionLabel={transform}
      backspaceRemovesValue={false}
      blurInputOnSelect={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
    />
  );
};
