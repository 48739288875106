import type { LoadedUser } from '../actions/user-actions';

export type UserState = {
  isLoading: boolean;
  isLoaded: boolean;
  user: LoadedUser | null;
};

export const initialUserState: UserState = {
  isLoaded: false,
  isLoading: false,
  user: null,
};
