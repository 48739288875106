"use strict";
exports.__esModule = true;
exports.buildAPIUrl = exports.buildAPIUrlPath = void 0;
var build_url_ts_1 = require("build-url-ts");
var route_1 = require("./route");
function isUrlOptionWithPath(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
config) {
    return config && config.params;
}
var buildAPIUrlPath = function (config) {
    return (0, exports.buildAPIUrl)(null, config);
};
exports.buildAPIUrlPath = buildAPIUrlPath;
var buildAPIUrl = function (baseUrl, config) {
    var _a;
    var resource = (0, route_1.parseRoute)(config.route).resource;
    if (isUrlOptionWithPath(config) && config.params) {
        for (var param in config.params) {
            resource = resource.replace(":".concat(param), config.params[param].toString());
        }
    }
    return (0, build_url_ts_1.buildUrl)(baseUrl !== null && baseUrl !== void 0 ? baseUrl : '', {
        path: resource,
        queryParams: (_a = config === null || config === void 0 ? void 0 : config.queryParams) !== null && _a !== void 0 ? _a : undefined
    });
};
exports.buildAPIUrl = buildAPIUrl;
