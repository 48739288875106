import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LoginRedirect: React.FunctionComponent<{}> = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ prompt: 'login', appState: { returnTo: location.pathname } });
    }
  }, [loginWithRedirect, isAuthenticated, isLoading, location.pathname]);

  return <></>;
};
