/* eslint-disable @typescript-eslint/no-redeclare */
import type { PortalAPI } from '@platform/api';
import { ActionType } from '@platform/helpers';

export type LoadedLeadStatus = Pick<
  PortalAPI.LeadStatusAPI.LeadStatusResponse,
  'id' | 'value' | 'icon' | 'order' | 'isMailTrigger' | 'type'
>;

export const LeadStatusActions = {
  loaded: (leadStatuses: LoadedLeadStatus[]) =>
    ({
      type: 'LeadStatus/loaded',
      payload: { leadStatuses },
    } as const),
  loading: () =>
    ({
      type: 'LeadStatus/loading',
      payload: { isLoading: true },
    } as const),
};

export type LeadStatusActions = ActionType<typeof LeadStatusActions>;
