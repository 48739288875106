"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.ProspectRouteDefinitions = exports.AuthRouteDefinitions = exports.AppRouteDefinitions = void 0;
var address_api_types_1 = require("./address-api.types");
var campaign_api_types_1 = require("./campaign-api.types");
var dashboard_api_types_1 = require("./dashboard-api.types");
var lead_status_api_types_1 = require("./lead-status-api.types");
var program_api_types_1 = require("./program-api.types");
var user_api_types_1 = require("./user-api.types");
var company_api_types_1 = require("./company-api.types");
var report_api_types_1 = require("./report-api.types");
var survey_api_types_1 = require("./survey-api.types");
var tag_api_types_1 = require("./tag-api.types");
var auth_api_types_1 = require("./auth-api.types");
var prospect_api_types_1 = require("./prospect-api.types");
exports.AppRouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, address_api_types_1.RouteDefinitions), campaign_api_types_1.DeprecatedRouteDefinitions), campaign_api_types_1.RouteDefinitions), company_api_types_1.RouteDefinitions), dashboard_api_types_1.RouteDefinitions), lead_status_api_types_1.RouteDefinitions), program_api_types_1.RouteDefinitions), report_api_types_1.RouteDefinitions), survey_api_types_1.RouteDefinitions), tag_api_types_1.RouteDefinitions), user_api_types_1.RouteDefinitions);
exports.AuthRouteDefinitions = __assign({}, auth_api_types_1.RouteDefinitions);
exports.ProspectRouteDefinitions = __assign(__assign({}, prospect_api_types_1.RouteDefinitions), prospect_api_types_1.DeprecatedRouteDefinitions);
