"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.API = exports.addressLookupResponseIsValid = void 0;
var helpers_1 = require("@platform/helpers");
function addressLookupResponseIsValid(response) {
    return !response || !('error' in response);
}
exports.addressLookupResponseIsValid = addressLookupResponseIsValid;
var API;
(function (API) {
    API["POST /v1/address/lookup"] = "POST /v1/address/lookup";
})(API = exports.API || (exports.API = {}));
exports.RouteDefinitions = __assign({}, (0, helpers_1.authenticatedRoute)(API['POST /v1/address/lookup']));
