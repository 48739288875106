"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.isValueRequest = exports.isHideRequest = exports.RouteDefinitions = exports.API = void 0;
var helpers_1 = require("@platform/helpers");
var API;
(function (API) {
    API["GET /v1/tags"] = "GET /v1/tags";
    API["GET /v1/tags/:id"] = "GET /v1/tags/:id";
    API["POST /v1/tags"] = "POST /v1/tags";
    API["PATCH /v1/tags/:id"] = "PATCH /v1/tags/:id";
    API["DELETE /v1/tags/:id"] = "DELETE /v1/tags/:id";
})(API = exports.API || (exports.API = {}));
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.authenticatedRoute)(API['GET /v1/tags'])), (0, helpers_1.authenticatedRoute)(API['GET /v1/tags/:id'])), (0, helpers_1.authenticatedRoute)(API['POST /v1/tags'])), (0, helpers_1.authenticatedRoute)(API['PATCH /v1/tags/:id'])), (0, helpers_1.authenticatedRoute)(API['DELETE /v1/tags/:id']));
// One of the annoying things about typescript is that you can't just check if the property
// exists in a type-safe way. This is overkill but it enforces type-safety.
function isHideRequest(arg) {
    return arg.isHidden !== undefined;
}
exports.isHideRequest = isHideRequest;
function isValueRequest(arg) {
    return arg.value !== undefined;
}
exports.isValueRequest = isValueRequest;
