import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { Selectors } from '../../redux/selectors';
import { useQuery } from '../../hooks/use-query';
import { withUnauthenticatedPageLayout } from '../Layout/unauthenticated-page';
import lsi_logo from '../../assets/LSLogo_red_500w.png';

export const ErrorPage = withUnauthenticatedPageLayout((props: { error?: string; errorDescription?: string }) => {
  const query = useQuery();
  const { boot } = useIntercom();
  const error = props.error ?? query.get('error');
  const errorDescription = props.errorDescription ?? query.get('error_description');
  const { isAuthenticated } = useAuth0();
  const { user } = useSelector(Selectors.user);

  const email = isAuthenticated && user ? user.email : query.get('email') ? query.get('email') : null;

  useEffect(() => {
    if (isAuthenticated && user && email) {
      boot({
        email,
        createdAt: Math.floor((user.createdAt ? new Date(user.createdAt) : new Date()).getTime() / 1000).toString(),
        userId: user.externalId,
        userHash: user.intercomUserHash,
      });
    }
  }, [boot, email, isAuthenticated, user]);

  return (
    <div className="flex flex-col lg:max-w-6xl lg:mx-auto items-center">
      <img className="sm:w-1/4 w-1/2 pt-10" src={lsi_logo} alt="Lead Scout Logo" />
      <h1 className="p-20 text-center text-4xl font-extrabold text-black-900 tracking-tight sm:text-5xl">
        Uh Oh. Something isn't right.
      </h1>
      {error && <p className="text-center text-3xl mb-10 text-black-500">{error}</p>}
      {errorDescription && <p className="text-center text-xl mt-6 text-gray-500">{errorDescription}</p>}
      <p className="text-center text-xl mt-6 text-gray-500">
        Contact{' '}
        <a className="text-blue-600" href="mailto:support@leadscoutapp.com">
          Lead Scout Support Team
        </a>{' '}
        for additional help.
      </p>
    </div>
  );
});
