"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.API = void 0;
var helpers_1 = require("@platform/helpers");
var API;
(function (API) {
    API["GET /company"] = "GET /company";
    API["GET /company/all"] = "GET /company/all";
    API["PATCH /company"] = "PATCH /company";
    API["POST /company/invite"] = "POST /company/invite";
    API["GET /company/associations"] = "GET /company/associations";
    API["DELETE /company/associations/:id"] = "DELETE /company/associations/:id";
    API["POST /company/search"] = "POST /company/search";
})(API = exports.API || (exports.API = {}));
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.authenticatedRoute)(API['GET /company'])), (0, helpers_1.authenticatedRoute)(API['GET /company/all'])), (0, helpers_1.authenticatedRoute)(API['PATCH /company'])), (0, helpers_1.authenticatedRoute)(API['POST /company/invite'])), (0, helpers_1.authenticatedRoute)(API['GET /company/associations'])), (0, helpers_1.authenticatedRoute)(API['DELETE /company/associations/:id'])), (0, helpers_1.authenticatedRoute)(API['POST /company/search']));
