import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Actions } from '../../../../redux/actions';
import { CampaignCreatorSelectors } from '../../../../redux/selectors';
import { useTypedDispatch } from '../../../../redux/state';
import { ProspectCountStepType } from '../../../../types/campaign';
import { ProspectCountCard } from '../../../ProspectsCountCard';

const ProspectCount = () => {
  const dispatch = useTypedDispatch();
  const step = useSelector(CampaignCreatorSelectors.getProspectCountStep);
  const totalCount = useSelector(CampaignCreatorSelectors.getProspectTotalCount);
  const onResetShape = useSelector(CampaignCreatorSelectors.getProspectCountResetShape);

  const onResetProspectCount = useCallback(() => {
    if (typeof onResetShape === 'function' && step !== ProspectCountStepType.CALCULATING) {
      dispatch(Actions.CampaignCreator.setResetProspectCount());
      onResetShape();
    }
  }, [dispatch, onResetShape, step]);

  return (
    <div className="flex flex-col mb-4 md:mb-10">
      <ProspectCountCard step={step} total={totalCount} onReset={onResetProspectCount} />
    </div>
  );
};

export default ProspectCount;
