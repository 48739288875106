import { DateFilterWithDescription, CustomDateFilterWithDescription } from '@platform/helpers';
import { Button, DialogModal, Divider } from '@platform/ui';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Utils, Hooks } from '@platform/ui-helpers';
import { Selectors } from '../../redux/selectors';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

interface LeaderboardCustomDateModalProps {
  show: boolean;
  onExit: () => void;
  onSubmit: (filter: CustomDateFilterWithDescription | null) => void;
  filter: DateFilterWithDescription | null;
}

const buildRangeFromCalendarEvent = (
  range: Utils.DateRange,
  type: Utils.BeforeAfter,
  value: Date | null,
  defaultDateRange: Utils.DateRange
): Utils.DateRange => {
  const newRange = {
    ...range,
    [type]: moment(value).startOf('day').format('M/D/YYYY'),
  };

  return {
    ...newRange,
    title: Utils.buildCustomDateTitle(newRange, defaultDateRange),
  };
};

type CalendarProps = {
  type: BeforeAfter;
  title: string;
  onChange: (value: Date | null) => void;
  range: { before: string | null; after: string | null };
  minDate?: Date | null;
  maxDate: Date;
};

type DateRange = Utils.DateRange;
type BeforeAfter = Utils.BeforeAfter;

const today = moment().format('M/D/YYYY');
const defaultDateRange: DateRange = { after: null, before: today, title: 'All time' };

const Calendar = (props: CalendarProps) => {
  const value = useMemo(() => {
    const value = props.range[props.type];
    return value ? new Date(value) : null;
  }, [props]);

  return (
    <ReactDatePicker
      id={props.type}
      title={props.title}
      name={props.title}
      selected={value}
      onChange={props.onChange}
      minDate={props.minDate ? new Date(props.minDate) : undefined}
      maxDate={props.maxDate}
      placeholderText={props.type === 'after' ? 'No start date' : undefined}
    />
  );
};

export const LeaderboardCustomDateModal = ({ show, onExit, onSubmit, filter }: LeaderboardCustomDateModalProps) => {
  const { createdAt: companyCreatedAt } = useSelector(Selectors.company);

  const customDateProps: Hooks.UseCustomDateModalProps = useMemo(
    () => ({
      companyCreatedAt: companyCreatedAt ? new Date(companyCreatedAt) : null,
      isVisible: show,
      filter: filter?.id === 'custom-range' ? filter : null,
      onSubmit,
      onExit,
      defaultDateRange,
    }),
    [companyCreatedAt, filter, onExit, onSubmit, show]
  );

  const { handleSubmit, newDateRange, setNewDateRange } = Hooks.useCustomDateModal(customDateProps);

  const handleSetDate = useCallback(
    (type: BeforeAfter, value: Date | null) => {
      setNewDateRange((r) => buildRangeFromCalendarEvent(r, type, value, defaultDateRange));
    },
    [setNewDateRange]
  );

  return (
    <DialogModal onClose={onExit} isOpen={show}>
      <div className="p-4 border-b border-solid border-slate-200 rounded-t">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg font-medium text-center">Leaderboard Date Range</h3>
          {!!newDateRange.title && (
            <div className="text-sm font-light text-gray-500 text-center">{newDateRange.title}</div>
          )}
        </div>
      </div>
      <div className={'flex flex-col p-4 w-full justify-center'}>
        <DateRow
          minDate={companyCreatedAt}
          onChange={handleSetDate}
          range={newDateRange}
          type="after"
          title="Start Date"
        />
        <Divider />
        <DateRow
          minDate={companyCreatedAt}
          onChange={handleSetDate}
          range={newDateRange}
          type="before"
          title="End Date"
        />
      </div>
      <div className={'flex py-8 justify-center shrink space-x-4'}>
        <Button text="Cancel" onClick={onExit} variant="neutral" />
        <Button text="Apply" onClick={handleSubmit} />
      </div>
    </DialogModal>
  );
};

const DateRow = (props: {
  type: BeforeAfter;
  title: string;
  minDate?: Date | null;
  range: Utils.DateRange;
  onChange: (type: BeforeAfter, date: Date | null) => void;
}) => {
  const onChange = useCallback(
    (value: Date | null) => {
      props.onChange(props.type, value);
    },
    [props]
  );

  const value =
    props.type === 'after' && !props.range.after
      ? 'No start date'
      : props.range[props.type] === today
      ? 'Today'
      : props.range[props.type];

  const minDate = useMemo(
    () => (props.type === 'before' && props.range.after ? new Date(props.range.after) : props.minDate),
    [props.minDate, props.range.after, props.type]
  );
  const maxDate = useMemo(
    () => (props.type === 'after' && props.range.before ? new Date(props.range.before) : new Date()),
    [props.range.before, props.type]
  );

  return (
    <div className="flex items-center justify-between px-4 py-2">
      <div className="flex flex-col justify-start">
        <div className="text-gray-900 font-medium">{props.title}</div>
        <div className="text-gray-500 text-sm font-normal">{value}</div>
      </div>
      <div className="shrink">
        <Calendar
          type={props.type}
          range={props.range}
          title={props.title}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};
