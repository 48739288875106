import axios, { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AddressForm, ModalHeaderToolbar } from '@platform/ui';
import { PortalAPI } from '@platform/api';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppActions } from '../../redux/actions/app-actions';
import { updateProspectAddress } from '../../redux/thunks';
import objectHash from 'object-hash';
import { AddressConfirmationModal } from './AddressConfirmation';
import { getAddressLine1, getAddressLine2, getAddressString } from '@platform/helpers';

export const AddressFormModal = ({
  getAccessTokenSilently,
  prospectId,
}: {
  getAccessTokenSilently: GetAccessTokenFunction;
  prospectId: string;
}) => {
  const prospect = useSelector(Selectors.prospectById(prospectId));
  const dispatch = useTypedDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm<PortalAPI.ProspectAPI.AddressValidateRequest>({
    defaultValues: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    },
  });

  const [sameContent, setSameContent] = useState(true);
  const [addressConfirmation, setAddressConfirmation] = useState<
    PortalAPI.ProspectAPI.AddressValidateResponse['suggested'] | null
  >(null);

  const address = useMemo(
    () => ({
      address1: prospect?.address.address1,
      address2: prospect?.address.address2 ?? undefined,
      city: prospect?.address.city,
      state: prospect?.address.state,
      zip: prospect?.address.zip,
    }),
    [
      prospect?.address.address1,
      prospect?.address.address2,
      prospect?.address.city,
      prospect?.address.state,
      prospect?.address.zip,
    ]
  );

  useEffect(() => {
    if (!prospectId) {
      return;
    }

    reset(address);
    setSameContent(true);
    const subscription = watch((values) => {
      setSameContent(objectHash(address) === objectHash(values));
    });

    return () => subscription.unsubscribe();
  }, [address, prospectId, reset, watch]);

  const onConfirmationClose = useCallback(() => {
    setAddressConfirmation(null);
  }, []);

  const onClose = useCallback(() => {
    onConfirmationClose();
    reset();
    setSameContent(true);
    dispatch(AppActions.setProspectAddressModal(null));
  }, [dispatch, onConfirmationClose, reset]);

  const onUseSuggested = useCallback(
    (suggested: PortalAPI.ProspectAPI.AddressValidateResponse['suggested']) => {
      if (prospectId) {
        dispatch(updateProspectAddress(getAccessTokenSilently, { externalId: prospectId, ...suggested }));
      }
      onClose();
    },
    [prospectId, onClose, dispatch, getAccessTokenSilently]
  );

  const onUseProvided = useCallback(() => {
    if (prospectId) {
      dispatch(updateProspectAddress(getAccessTokenSilently, { externalId: prospectId, ...getValues() }));
    }

    onClose();
  }, [dispatch, getAccessTokenSilently, getValues, onClose, prospectId]);

  const onSave = useCallback(
    async (values: PortalAPI.ProspectAPI.AddressValidateRequest) => {
      if (prospectId) {
        setIsSubmitting(true);
        try {
          const { data } = await PortalAPI.Client.getApiClient(getAccessTokenSilently).request(
            {
              route: PortalAPI.ProspectAPI.API['POST /v1/prospects/:externalId/address-check'],
              params: { externalId: prospectId },
            },
            values
          );
          if (data && !('error' in data)) {
            const { address1, address2, city, state, zip } = data.suggested;
            const newAddress: PortalAPI.ProspectAPI.AddressValidateRequest = { address1, address2, city, state, zip };
            if (getAddressString(newAddress) !== getAddressString(values)) {
              setAddressConfirmation(data.suggested);
            } else {
              onUseSuggested(data.suggested);
            }
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            const res = (e ?? {}) as AxiosError<
              PortalAPI.ProspectAPI.Responses['POST /v1/prospects/:externalId/address-check']
            >;
            if (res?.response?.data && 'error' in res.response.data && res.response.data.error === 'duplicate') {
              return dispatch(
                AppActions.setDuplicateProspectModal({
                  duplicateProspectId: res.response.data.details.prospectId,
                  addressLine1: getAddressLine1(values),
                  addressLine2: getAddressLine2(values),
                  prospect: res.response.data.details.feature,
                })
              );
            }

            if (res.response?.status !== 400 && res.response?.status !== 403) {
              // If there was an error then we'll just go ahead and save it how they wanted
              onUseProvided();
            }
          }
        } finally {
          setIsSubmitting(false);
        }
      }
    },
    [dispatch, getAccessTokenSilently, onUseProvided, onUseSuggested, prospectId]
  );

  return (
    <>
      <ModalHeaderToolbar
        onBack={onClose}
        onSave={handleSubmit(onSave)}
        title="Edit Address"
        isDisabled={isSubmitting || sameContent}
        isLoading={isSubmitting}
        hasShadow
      />
      <AddressForm register={register} isSubmitting={isSubmitting} control={control} errors={errors} />
      <AddressConfirmationModal
        isOpen={!!addressConfirmation}
        onUseSuggested={onUseSuggested}
        onUseProvided={onUseProvided}
        onClose={onConfirmationClose}
        suggested={addressConfirmation}
      />
    </>
  );
};
