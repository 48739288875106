"use strict";
exports.__esModule = true;
exports.calculateBuildingArea = exports.calculateLengthOfResidence = exports.calculateAgeOfHome = exports.getNetWorthRange = void 0;
var integrations_1 = require("@platform/integrations");
var yearFormat = function (value, format, fullString) {
    return format === 'y' ? "".concat(fullString !== null && fullString !== void 0 ? fullString : value, "y") : "".concat(fullString !== null && fullString !== void 0 ? fullString : value, " year").concat(value > 1 ? 's' : '');
};
var getNetWorthRange = function (netWorth) {
    var _a;
    return (_a = (netWorth ? integrations_1.Cole.NetWorthRanges[netWorth] : null)) !== null && _a !== void 0 ? _a : null;
};
exports.getNetWorthRange = getNetWorthRange;
var calculateAgeOfHome = function (yearBuilt, withLabel) {
    if (withLabel === void 0) { withLabel = 'years'; }
    if (!yearBuilt || yearBuilt > new Date().getFullYear()) {
        return null;
    }
    if (typeof yearBuilt === 'string' && Number.isNaN(Number(yearBuilt))) {
        return yearBuilt;
    }
    return yearFormat(new Date().getFullYear() - Number(yearBuilt), withLabel);
};
exports.calculateAgeOfHome = calculateAgeOfHome;
var calculateLengthOfResidence = function (lengthOfResidence, withLabel) {
    if (withLabel === void 0) { withLabel = 'years'; }
    if (!lengthOfResidence) {
        return null;
    }
    var lengthVal;
    var lengthString = undefined;
    if (typeof lengthOfResidence === 'string') {
        lengthString = lengthOfResidence;
        var match = lengthOfResidence.match(/^[<>]?(\d+)$/);
        if (match && match[1] && !Number.isNaN(Number(match[1]))) {
            lengthVal = Number(match[1]);
        }
        else {
            return lengthOfResidence;
        }
    }
    else {
        lengthVal = lengthOfResidence;
    }
    return yearFormat(lengthVal, withLabel, lengthString);
};
exports.calculateLengthOfResidence = calculateLengthOfResidence;
var calculateBuildingArea = function (buildingArea) {
    return buildingArea
        ? !Number.isNaN(Number(buildingArea))
            ? "".concat(new Intl.NumberFormat('en-US').format(Number(buildingArea)), " sqft")
            : buildingArea.toString()
        : null;
};
exports.calculateBuildingArea = calculateBuildingArea;
