"use strict";
exports.__esModule = true;
exports.getDefaultLeadStatusType = exports.getDefaultLeadStatusIcon = exports.DefaultLeadStatusNames = exports.getLeadStatusIcon = exports.LeadStatusType = void 0;
var ts_pattern_1 = require("ts-pattern");
var LeadStatusIcons = [
    'fire-red-600',
    'arrow-left-turn-orange-500',
    'hammer-light-blue-600',
    'home-cross-yellow-500',
    'heart-pink-500',
    'calendar-green-500',
    'signature-purple-500',
    'hand-raised-gray-500',
    'x-mark-gray-900',
    'check-blue-500',
    'thumbs-down-gray-400',
    'circle-red-500',
];
var LeadStatusType;
(function (LeadStatusType) {
    LeadStatusType["APPOINTMENT"] = "appointment";
})(LeadStatusType = exports.LeadStatusType || (exports.LeadStatusType = {}));
var getLeadStatusIcon = function (status) { return status !== null && status !== void 0 ? status : 'circle-red-500'; };
exports.getLeadStatusIcon = getLeadStatusIcon;
exports.DefaultLeadStatusNames = [
    'Hot Lead',
    'Not Home',
    'Revisit',
    'Not Interested',
    'Referral',
    'Appointment Scheduled',
    'Contract Signed',
    'Existing Customer',
    'Not Qualified',
    'Do Not Contact',
];
var getDefaultLeadStatusIcon = function (status) {
    return (0, ts_pattern_1.match)(status)["with"]('Hot Lead', function () { return (0, exports.getLeadStatusIcon)('fire-red-600'); })["with"]('Not Home', function () { return (0, exports.getLeadStatusIcon)('home-cross-yellow-500'); })["with"]('Revisit', function () { return (0, exports.getLeadStatusIcon)('arrow-left-turn-orange-500'); })["with"]('Not Interested', function () { return (0, exports.getLeadStatusIcon)('hand-raised-gray-500'); })["with"]('Referral', function () { return (0, exports.getLeadStatusIcon)('heart-pink-500'); })["with"]('Appointment Scheduled', function () { return (0, exports.getLeadStatusIcon)('calendar-green-500'); })["with"]('Contract Signed', function () { return (0, exports.getLeadStatusIcon)('signature-purple-500'); })["with"]('Existing Customer', function () { return (0, exports.getLeadStatusIcon)('check-blue-500'); })["with"]('Not Qualified', function () { return (0, exports.getLeadStatusIcon)('thumbs-down-gray-400'); })["with"]('Do Not Contact', function () { return (0, exports.getLeadStatusIcon)('x-mark-gray-900'); })
        .exhaustive();
};
exports.getDefaultLeadStatusIcon = getDefaultLeadStatusIcon;
var getDefaultLeadStatusType = function (status) {
    return (0, ts_pattern_1.match)(status)["with"]('Appointment Scheduled', function () { return LeadStatusType.APPOINTMENT; })
        .otherwise(function () { return null; });
};
exports.getDefaultLeadStatusType = getDefaultLeadStatusType;
