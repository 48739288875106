import { CalendarIcon, UserPlusIcon, PlayIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { Constants } from '@platform/app-config';
import { Portal } from '@platform/ui-helpers';
import { Link } from 'react-router-dom';
import download_from_appstore from '../../assets/apple_appstore_badge.png';
import google_play_badge from '../../assets/google_play_badge.png';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';

export const GettingStarted = withAuthenticatedPageLayout(() => (
  <div className="flex flex-col max-w-6xl mx-auto px-4 lg:px-8 pt-5">
    <div className="flex justify-center m-2 bg-blue-700  md:shadow md:bg-white rounded-lg px-6 py-12 sm:p-12 bg-gradient-to-br from-blue-500 to-purple-500">
      <div className="w-full items-center text-center">
        <h1 className="text-center font-black text-3xl md:text-4xl text-white">Let's get started!</h1>
        <p className="text-center font-normal text-md md:text-lg my-4 text-indigo-100">
          Here are the top 3 things you can do right now to get started the right way.
        </p>
        <div className="mt-12 flex flex-col gap-2 lg:gap-4">
          <div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6 text-left flex flex-col md:flex-row gap-4">
            <div className="text-sm text-gray-500 text-left flex flex-col space-y-2 w-full xl:w-2/3 md:w-1/2">
              <div className="text-left flex flex-col xl:flex-row xl:space-x-2 space-y-1 xl:space-y-0 items-start">
                <h3 className="text-lg font-medium leading-6 text-gray-900 ">Download the Mobile App</h3>
              </div>
              <p>Add the mobile app on your smart phone to start adding prospects and building out your lead map.</p>
              <div className="text-left flex flex-col xl:flex-row xl:space-x-2 space-y-1 xl:space-y-0 items-start">
                <a
                  className="text-xs font-semibold py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200 last:mr-0 mr-1 flex flex-row"
                  href={Constants.DOWNLOAD_APP_HELP_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <QuestionMarkCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                  View Guide
                </a>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 w-full xl:w-1/3 md:w-1/2 md:justify-end flex gap-1">
              <a href={Constants.GUIDED_APP_IOS_URL} target="_blank" rel="noreferrer">
                <img
                  src={download_from_appstore}
                  width={150}
                  className="rounded-md border border-transparent hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 sm:text-sm text-left"
                  alt="Download from App Store"
                />
              </a>
              <a href={Constants.GUIDED_APP_ANDROID_URL} target="_blank" rel="noreferrer">
                <img
                  src={google_play_badge}
                  width={150}
                  className="rounded-md border border-transparent hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 sm:text-sm text-left"
                  alt="Get it on Google Play"
                />
              </a>
            </div>
          </div>
          <div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6 text-left flex flex-col md:flex-row gap-4">
            <div className="text-sm text-gray-500 text-left flex flex-col space-y-2 w-full xl:w-2/3 md:w-1/2">
              <div className="text-left flex flex-col xl:flex-row xl:space-x-2 space-y-1 xl:space-y-0 items-start">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Invite other team members</h3>
              </div>
              <p>Invite everyone on your team so they can start adding prospects and leads to your map!</p>
              <div className="text-left flex flex-col xl:flex-row xl:space-x-2 space-y-1 xl:space-y-0 items-start">
                <a
                  className="text-xs font-semibold py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200 last:mr-0 mr-1 flex flex-row"
                  href={Constants.INVITE_TEAM_HELP_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PlayIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                  Video Tutorial
                </a>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 w-full xl:w-1/3 md:w-1/2 md:justify-end flex">
              <Link to={Portal.getRouteName('/team')}>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm text-left"
                >
                  <UserPlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  Invite a Team Member
                </button>
              </Link>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6 text-left flex flex-col md:flex-row gap-4">
            <div className="text-sm text-gray-500 text-left flex flex-col space-y-2 w-full xl:w-2/3 md:w-1/2">
              <div className="text-left flex flex-col xl:flex-row xl:space-x-2 space-y-1 xl:space-y-0 items-start">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Scout your first prospect!</h3>
              </div>
              <p>
                Scouting new prospects in Lead Scout is as easy as just firmly tapping on the map. If you don't have a
                strategy for your field sales, reach out to us about coaching!
              </p>
              <div className="text-left flex flex-col xl:flex-row xl:space-x-2 space-y-1 xl:space-y-0 items-start">
                <a
                  className="text-xs font-semibold py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200 last:mr-0 mr-1 flex flex-row"
                  href={Constants.SCOUT_FIRST_PROSPECT_HELP_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PlayIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                  Video Tutorial
                </a>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 w-full xl:w-1/3 md:w-1/2 md:justify-end flex">
              <span className="cursor-pointer h-10 inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm text-left">
                <CalendarIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                Ask about Coaching
              </span>
            </div>
          </div>
        </div>

        <div className="mt-12">
          <span className="text-center font-normal text-md my-2 text-indigo-100">
            Need more help getting started? Visit the&nbsp;
            <a
              href={Constants.GETTING_STARTED_HELP}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center text-md font-bold text-white hover:underline"
            >
              Help Center
            </a>
            <span className="text-center font-normal text-md my-2 text-indigo-100"> or watch the </span>
            <a
              href={Constants.DEMO_URL}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center text-md font-bold text-white hover:underline"
            >
              Getting Started Video Series
            </a>
            .
          </span>
        </div>
      </div>
    </div>
  </div>
));
