import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TagIcon } from '@heroicons/react/24/solid';
import { Utils as UtilsHelper } from '@platform/ui-helpers';
import { TagsTable, TagItemEvent, TagsGroup, breakpoints, TagsTableProps, Spinner } from '@platform/ui';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import { PageHeader } from '../Layout/PageHeader';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { AppActions } from '../../redux/actions/app-actions';
import { createTag, loadAllTags, updateTag } from '../../redux/thunks';
import { Selectors } from '../../redux/selectors';
import { useAuth0 } from '@auth0/auth0-react';
import { formatDistanceToNow } from 'date-fns';
import * as uuid from 'uuid';
import useMediaQuery from '../../hooks/useMediaQuery';
import { orderBy } from 'lodash';
import { match } from 'ts-pattern';
import { withPermissionsRequired } from '../../utils/with-permissions-required';

export const Tags = withPermissionsRequired(['canManageTags'])(
  withAuthenticatedPageLayout(() => {
    const dispatch = useTypedDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const [sort, setSort] = useState<TagsTableProps['sortField']>(null);
    const { isLoading, isLoaded } = useSelector(Selectors.getTagsState);
    const tags = useSelector(Selectors.getTags);
    const isDesktop = useMediaQuery(breakpoints.lg);

    useEffect(() => {
      if (!isLoading && !isLoaded) {
        dispatch(loadAllTags(getAccessTokenSilently));
      }
    }, [dispatch, getAccessTokenSilently, isLoading, isLoaded]);

    const onEdit = useCallback(
      (tag: TagItemEvent) => {
        dispatch(AppActions.setEditTagModalOpen(tag));
      },
      [dispatch]
    );

    const onOpenCreateTagModal = useCallback(() => {
      dispatch(AppActions.setCreateTagModalOpen(true));
    }, [dispatch]);

    const onDelete = useCallback(
      (tag: TagItemEvent) => {
        dispatch(AppActions.setDeleteTagModalOpen(tag));
      },
      [dispatch]
    );

    const onConfirmHide = useCallback(
      (tag: TagItemEvent) => {
        dispatch(AppActions.setHideTagModalOpen(tag));
      },
      [dispatch]
    );

    const onShow = useCallback(
      async ({ externalId }: TagItemEvent) => {
        await dispatch(
          updateTag(getAccessTokenSilently, {
            id: externalId,
            isHidden: false,
          })
        );
      },
      [dispatch, getAccessTokenSilently]
    );

    const tableData: TagsTableProps['data'] = useMemo(() => {
      if (isLoading) return [];

      return match(sort)
        .with(null, () => tags)
        .with(
          { field: 'time' },
          ({ order }) => orderBy(tags, (tag) => tag.lastUsedAt ?? '', order === 'ASC' ? 'desc' : 'asc') // This time is absolute but in the UI it's relative, so it should be reversed
        )
        .with({ field: 'prospectCount' }, ({ order }) =>
          orderBy(tags, (tag) => tag.prospectCount, order.toLowerCase() as Lowercase<typeof order>)
        )
        .with({ field: 'value' }, ({ order }) =>
          orderBy(tags, (tag) => tag.value.toLowerCase(), order.toLowerCase() as Lowercase<typeof order>)
        )
        .exhaustive()
        .map(({ externalId, lastUsedAt, value, prospectCount, hiddenAt }) => ({
          externalId,
          time: lastUsedAt ? formatDistanceToNow(new Date(lastUsedAt), { addSuffix: true }) : null,
          prospectCount: prospectCount ?? 0,
          isHidden: !!hiddenAt,
          value,
          onEdit,
          onHide: (item) => (!!hiddenAt ? onShow(item) : onConfirmHide(item)),
          onDelete,
        }));
    }, [isLoading, tags, sort, onEdit, onDelete, onShow, onConfirmHide]);

    const getEmptyStateProps = useMemo(() => {
      const options = UtilsHelper.SuggestedTags.map((value) => ({
        externalId: uuid.v4(),
        value,
        onClick: async () => {
          await dispatch(createTag(getAccessTokenSilently, { value }));
        },
      }));

      return {
        tags: [],
        suggestions: {
          classNames: 'flex flex-col max-w-2xl mx-auto',
          title: 'Create your first tag',
          description: 'Use tags to help organize your prospects when scouting.',
          show: true,
          showIllustration: true,
          action: {
            text: 'Add a Tag',
            onClick: onOpenCreateTagModal,
          },
          options,
        },
        onClickAdd: onOpenCreateTagModal,
      };
    }, [onOpenCreateTagModal, getAccessTokenSilently, dispatch]);

    const onRenderContent = useCallback(() => {
      if (!isLoaded) {
        return (
          <div className="flex flex-col items-center">
            <Spinner variant="primary" size="large" />
          </div>
        );
      }

      if (tableData.length === 0 && isLoaded) {
        return (
          <div className="flex flex-col p-6 shadow bg-white rounded-lg">
            <div className="flex flex-col p-6 bg-gray-50 rounded-lg">
              <TagsGroup {...getEmptyStateProps} />
            </div>
          </div>
        );
      }

      return (
        <>
          <TagsTable data={tableData} onSort={setSort} sortField={sort} isLoading={isLoading} isMobile={!isDesktop} />
        </>
      );
    }, [isLoaded, isLoading, tableData, sort, getEmptyStateProps, isDesktop]);

    return (
      <div className="flex flex-col">
        <PageHeader
          title="Manage Tags"
          icon={TagIcon}
          iconHeading="Tags"
          newAction={{ visible: true, onClick: onOpenCreateTagModal, text: 'Add Tag' }}
        />
        <div className="flex flex-col w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-5">{onRenderContent()}</div>
      </div>
    );
  })
);
