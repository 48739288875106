"use strict";
/*
 * The code for getValue is a typed safe alternative for the get from lodash
 */
exports.__esModule = true;
exports.getValue = void 0;
function getValue(data, path, defaultValue) {
    var value = path
        .split(/[.[\]]/)
        .filter(Boolean)
        .reduce(function (value, key) { return value === null || value === void 0 ? void 0 : value[key]; }, data);
    return value !== undefined ? value : defaultValue;
}
exports.getValue = getValue;
