"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.ProgramPrivateAPI = void 0;
var helpers_1 = require("@platform/helpers");
var ProgramPrivateAPI;
(function (ProgramPrivateAPI) {
    ProgramPrivateAPI["GET /programs"] = "GET /programs";
    ProgramPrivateAPI["GET /programs/:externalId"] = "GET /programs/:externalId";
    ProgramPrivateAPI["POST /programs/:externalId/samples"] = "POST /programs/:externalId/samples";
})(ProgramPrivateAPI = exports.ProgramPrivateAPI || (exports.ProgramPrivateAPI = {}));
exports.RouteDefinitions = __assign(__assign(__assign({}, (0, helpers_1.authenticatedRoute)(ProgramPrivateAPI['GET /programs'])), (0, helpers_1.authenticatedRoute)(ProgramPrivateAPI['GET /programs/:externalId'])), (0, helpers_1.authenticatedRoute)(ProgramPrivateAPI['POST /programs/:externalId/samples']));
