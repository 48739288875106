"use strict";
exports.__esModule = true;
exports.isDeactivated = exports.isActivated = void 0;
var isActivated = function (activatedAt, deactivatedAt) {
    return !!activatedAt && (!deactivatedAt || activatedAt > deactivatedAt);
};
exports.isActivated = isActivated;
var isDeactivated = function (activatedAt, deactivatedAt) {
    return !!deactivatedAt && (!activatedAt || deactivatedAt > activatedAt);
};
exports.isDeactivated = isDeactivated;
