import { User } from '@auth0/auth0-spa-js';
import { PortalAPI } from '@platform/api';
import { AxiosResponse } from 'axios';
import { SignupComplete as SignupCompleteUI } from '@platform/ui';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Utils } from '@platform/ui-helpers';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { AppActions } from '../../redux/actions/app-actions';
import { NotificationType } from '../../types/notification';
import { useQuery } from '../../hooks/use-query';
import { withUnauthenticatedPageLayout } from '../Layout/unauthenticated-page';

export const SignupComplete = withUnauthenticatedPageLayout(() => {
  const query = useQuery();
  const { boot } = useIntercom();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const token = query.get('token');
  const state = query.get('state');
  const intercomUserHash = query.get('userHash');
  const payload = useMemo(() => jwt_decode(token!) as (JwtPayload & User) | null, [token]);

  if (!payload) {
    return <div />;
  }

  const { email, given_name, family_name } = payload;

  useEffect(() => {
    if (intercomUserHash) {
      boot({
        userId: payload.sub,
        userHash: intercomUserHash,
        email: payload.email,
        createdAt: Math.floor(
          (payload.updated_at ? new Date(payload.updated_at) : new Date()).getTime() / 1000
        ).toString(),
      });
    }
  }, [boot, payload, intercomUserHash]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<PortalAPI.AuthAPI.SignupPayload>({
    defaultValues: { email, firstName: given_name, lastName: family_name, company: { billingEmail: email } },
  });

  const onSubmit = useCallback(
    async (values: PortalAPI.AuthAPI.SignupPayload) => {
      let ret: AxiosResponse<PortalAPI.AuthAPI.Responses['POST /signup']>;

      setSubmitting(true);
      try {
        ret = await PortalAPI.Client.getUnauthenticatedApiClient().request(
          {
            route: 'POST /signup',
            queryParams: { state: state! },
            headers: { Authorization: token! },
          },
          { ...values, email: email! }
        );

        if (ret.data.redirectUri) {
          window.location.href = ret.data.redirectUri;
        }
      } catch (e: any) {
        const res = e.response as typeof ret;
        if (res.data && res.data.errors) {
          if (typeof res.data.errors !== 'string') {
            Utils.addServerErrors<PortalAPI.AuthAPI.SignupPayload>(res.data.errors, setError);
          } else {
            dispatch(
              AppActions.notification({
                type: NotificationType.error,
                title: res.data.errors,
                description: 'Please contact support@leadscoutapp.com for support',
              })
            );
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
    [dispatch, email, state, setError, token]
  );

  return (
    <div className="flex flex-col px-6 py-12">
      <SignupCompleteUI
        submitting={submitting}
        errors={errors}
        handleSubmit={handleSubmit(onSubmit)}
        register={register}
        control={control}
        hasBillingEmail
      />
    </div>
  );
});
