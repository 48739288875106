import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';

export const companyReducer: AppReducer<'Company'> = (state = initialState.Company, action): typeof state => {
  switch (action.type) {
    case 'Company/loaded':
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        isLoading: false,
      };
    case 'Company/loading':
      return {
        ...state,
        isLoading: true,
      };
    case 'Company/update': {
      const { prospectDataSubscription, ...company } = action.payload;
      return {
        ...state,
        ...company,
        prospectDataSubscription: prospectDataSubscription ?? 'none',
      };
    }
    case 'Company/allLoading':
      return {
        ...state,
        all: {
          ...state.all,
          loaded: false,
          loading: true,
        },
      };
    case 'Company/allLoaded':
      return {
        ...state,
        all: {
          list: action.payload,
          loaded: true,
          loading: false,
        },
      };
    case 'Company/loadedAssociations':
      return {
        ...state,
        children: action.payload,
      };
    case 'Company/addAssociation':
      return {
        ...state,
        children: [
          {
            id: action.payload.companyAssociation.id,
            companyName: action.payload.company.companyName,
            companyId: action.payload.company.companyId,
            userEmail: action.payload.invite.email,
            userFirstName: action.payload.invite.firstName,
            userLastName: action.payload.invite.lastName,
            acceptedAt: null,
            invitedAt: new Date(),
            sponsorship: action.payload.companyAssociation.sponsorship,
            inviteId: action.payload.invite.id,
            userId: null,
          },
          ...(state.children ?? []),
        ],
        all: {
          ...state.all,
          list: [
            { externalId: action.payload.company.companyId, name: action.payload.company.companyName },
            ...state.all.list,
          ],
        },
      };
    case 'Company/removeAssociation':
      return {
        ...state,
        children: (state.children ?? []).filter((c) => c.id !== action.id),
      };
    default:
      return state;
  }
};
