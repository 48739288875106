"use strict";
exports.__esModule = true;
exports.ApiAuthorizationType = exports.unauthenticatedRoute = exports.authenticatedRoute = exports.parseRoute = void 0;
var parseRoute = function (route) {
    var _a = route.split(' /'), action = _a[0], path = _a[1];
    return {
        action: action.trim().toLowerCase(),
        resource: "/".concat(path)
    };
};
exports.parseRoute = parseRoute;
var authenticatedRoute = function (r) {
    var _a;
    return _a = {},
        _a[r] = { authorizationType: ApiAuthorizationType.JWT },
        _a;
};
exports.authenticatedRoute = authenticatedRoute;
var unauthenticatedRoute = function (r) {
    var _a;
    return _a = {},
        _a[r] = { authorizationType: ApiAuthorizationType.NONE },
        _a;
};
exports.unauthenticatedRoute = unauthenticatedRoute;
var ApiAuthorizationType;
(function (ApiAuthorizationType) {
    ApiAuthorizationType["NONE"] = "NONE";
    ApiAuthorizationType["JWT"] = "JWT";
})(ApiAuthorizationType = exports.ApiAuthorizationType || (exports.ApiAuthorizationType = {}));
