import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { breakpoints } from '@platform/ui';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Selectors } from '../../../redux/selectors';
import { useTypedDispatch } from '../../../redux/state';
import { loadCampaignDetails, loadCampaigns } from '../../../redux/thunks';
import { CampaignUIProps } from './campaigns-list.ui';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { CampaignActions } from '../../../redux/actions/campaign-actions';
import { delayMs } from '@platform/helpers';

export type CampaignListDataProps = {};

type InjectedProps = CampaignUIProps;

export const withCampaignList = <T extends InjectedProps & CampaignListDataProps>(
  Component: React.ComponentType<T>
) => {
  const Page: FunctionComponent<Omit<T, keyof InjectedProps>> = (props) => {
    const isDesktop = useMediaQuery(breakpoints.lg);
    const { getAccessTokenSilently } = useAuth0();
    const { isLoading, campaigns, totalRecords, isLoaded } = useSelector(Selectors.allCampaigns);
    const watchedCampaigns = useSelector(Selectors.watchedCampaigns);
    const dispatch = useTypedDispatch();
    const { user: loadedUser } = useSelector(Selectors.user);
    const [pollTick, setPollTick] = useState(0);

    const pollCampaignWatchList = useCallback(async () => {
      for (const c of watchedCampaigns) {
        try {
          const campaign = await dispatch(loadCampaignDetails(c.toString(), getAccessTokenSilently));
          if (campaign?.isBuilt) {
            dispatch(CampaignActions.unwatch(c));
          }
        } catch {}
      }
    }, [dispatch, getAccessTokenSilently, watchedCampaigns]);

    useEffect(() => {
      if (!isLoading && !isLoaded) {
        dispatch(loadCampaigns(0, getAccessTokenSilently));
      }
    }, [dispatch, getAccessTokenSilently, isLoaded, isLoading]);

    useEffect(() => {
      pollCampaignWatchList()
        .then(async () => await delayMs(5000))
        .finally(() => setPollTick(new Date().getTime()));
    }, [pollTick, pollCampaignWatchList]);

    const loadMore = useCallback(
      () => dispatch(loadCampaigns(campaigns.length, getAccessTokenSilently)),
      [campaigns.length, dispatch, getAccessTokenSilently]
    );

    return (
      <Component
        {...(props as T)}
        loading={isLoading}
        loaded={isLoaded}
        campaigns={campaigns}
        showLoadMore={campaigns.length > 0 && campaigns.length < totalRecords}
        loadMore={loadMore}
        isSuperAdmin={loadedUser && loadedUser.isSuperAdmin}
        isDesktop={isDesktop}
      />
    );
  };

  return Page;
};
