"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.findBestPrimaryForSegment = exports.findPrimaryRecord = exports.getAddressFromColeFetchResponse = void 0;
var lodash_1 = require("lodash");
var fetch_api_1 = require("./fetch-api");
var getAddressFromColeFetchResponse = function (data) {
    var _a;
    var address = {
        address1: "".concat(data.HouseNumber, " ").concat(data.StreetName),
        city: data.City,
        state: data.State,
        zip: data.ZipCode
    };
    if (data.ApartmentNumber) {
        return __assign(__assign({}, address), { address2: "".concat((_a = data.ApartmentBuildingType) !== null && _a !== void 0 ? _a : 'Apt', " ").concat(data.ApartmentNumber) });
    }
    return address;
};
exports.getAddressFromColeFetchResponse = getAddressFromColeFetchResponse;
var findPrimaryRecord = function (data) {
    var primary;
    var scores = (0, lodash_1.groupBy)(data.Results, function (r) { return r.Score || 0; });
    var highestScoreResults = scores[Math.max.apply(Math, Object.keys(scores).map(function (s) { return Number(s); }))];
    // If there is only one record with the highest score, use it
    if (highestScoreResults.length === 1) {
        primary = highestScoreResults[0];
    }
    else {
        var headsOfHousehold = highestScoreResults.filter(function (r) { return (0, fetch_api_1.isTruthy)(r.HeadHousehold); });
        // If there's only 1 head of household, then use it
        if (headsOfHousehold.length === 1) {
            primary = headsOfHousehold[0];
        }
        else {
            // If there's no heads of household, then find the most relevant homeowner
            if (headsOfHousehold.length === 0) {
                var homeowners = highestScoreResults.filter(function (r) { return (0, fetch_api_1.isTruthy)(r.HomeownerConfirmed); });
                if (homeowners.length === 1) {
                    primary = homeowners[0];
                }
                else if (homeowners.length === 0) {
                    // No head of household and no homeowner
                    primary = (0, exports.findBestPrimaryForSegment)(highestScoreResults, data.Results);
                }
                else {
                    console.warn("Multiple non-HeadOfHousehold Homeowner records found for ".concat(homeowners[0].AddressHash));
                    primary = (0, exports.findBestPrimaryForSegment)(homeowners, data.Results);
                }
            }
            else {
                // Multiple heads of households
                var headOfHouseholdHomeowners = headsOfHousehold.filter(function (r) { return (0, fetch_api_1.isTruthy)(r.HomeownerConfirmed); });
                if (headOfHouseholdHomeowners.length === 1) {
                    primary = headOfHouseholdHomeowners[0];
                }
                else if (headOfHouseholdHomeowners.length === 0) {
                    // No head of household and no homeowner
                    primary = (0, exports.findBestPrimaryForSegment)(headsOfHousehold, data.Results);
                }
                else {
                    console.warn("Multiple HeadOfHousehold Homeowner records found for ".concat(headOfHouseholdHomeowners[0].AddressHash));
                    primary = (0, exports.findBestPrimaryForSegment)(headOfHouseholdHomeowners, data.Results);
                }
            }
        }
    }
    return primary;
};
exports.findPrimaryRecord = findPrimaryRecord;
var findBestPrimaryForSegment = function (segment, allResults) {
    var primary = null;
    var _loop_1 = function (record) {
        if (record.HouseholdMembers.length &&
            record.HouseholdMembers[0].FirstName &&
            allResults.find(function (r) { return r.FirstName === record.HouseholdMembers[0].FirstName; })) {
            primary = record;
            return "break";
        }
    };
    for (var _i = 0, segment_1 = segment; _i < segment_1.length; _i++) {
        var record = segment_1[_i];
        var state_1 = _loop_1(record);
        if (state_1 === "break")
            break;
    }
    if (!primary) {
        var saleDates = segment.filter(function (h) { return !!h.Property_SaleDate; });
        if (saleDates.length === 1) {
            primary = saleDates[0];
        }
        else if (saleDates.length > 1) {
            // Take the latest sale date
            primary = (0, lodash_1.orderBy)(saleDates, function (s) { return s.Property_SaleDate; }, 'desc')[0];
        }
        else {
            var lor = segment.filter(function (h) { return !Number.isNaN(parseInt(h.LengthOfResidence)); });
            // If every record has a LoR, then use the one with the shortest LoR
            if (lor.length === segment.length) {
                primary = (0, lodash_1.orderBy)(lor, function (h) { return parseInt(h.LengthOfResidence); }, 'asc')[0];
            }
        }
    }
    return primary !== null && primary !== void 0 ? primary : segment[0];
};
exports.findBestPrimaryForSegment = findBestPrimaryForSegment;
