import React from 'react';
// Store
import { useSelector } from 'react-redux';
import { CampaignCreatorSelectors, Selectors } from '../../../../redux/selectors';
import { useTypedDispatch } from '../../../../redux/state';
import { createNewCampaign } from '../../../../redux/thunks';
import { Actions } from '../../../../redux/actions';
// Navigation
import { useNavigate } from 'react-router-dom';
// Components
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { Tag } from '@platform/ui';
import { StartCampaignButton } from '../../../StartCampaignButton';
// Types
import { GetAccessTokenFunction } from '../../../../types/auth0';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';

export interface Props {
  getAccessToken: GetAccessTokenFunction;
}

const CampaignsCreatorNavigation = ({ getAccessToken }: Props) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const enableCampaignCreation = useSelector(Selectors.getEnableCampaignCreation);
  const isSubmitting = useSelector(CampaignCreatorSelectors.getIsSubmitting);

  const onCreateCampaign = () => {
    dispatch(createNewCampaign(getAccessToken, navigate));
  };

  const onExit = () => {
    dispatch(Actions.CampaignCreator.setClearForm());
    navigate('/campaigns');
  };

  return (
    <div className="sticky top-0 z-[1000] flex flex-col bg-white shadow px-4 py-3">
      <div className="flex justify-between">
        <div className="flex items-center mr-2">
          <button
            onClick={onExit}
            type="button"
            className="inline-flex text-gray-500 hover:text-gray-600 active:text-gray-500 items-center rounded bg-white py-1.5 focus:outline-none mr-6"
          >
            <ArrowSmallLeftIcon className="h-6 w-6" aria-hidden="true" />
            <span className="text-sm leading-5 font-medium ml-2">Exit</span>
          </button>
          <div className="hidden md:inline-flex">
            <Tag
              icon={
                <PaperAirplaneIcon
                  className="text-blue-500 transform -rotate-45 flex-shrink-0 h-5 w-5 -mt-1"
                  aria-hidden="true"
                />
              }
              text="New Campaign"
            />
          </div>
        </div>
        <div className="flex">
          <StartCampaignButton
            onClick={onCreateCampaign}
            size="normal"
            isDisabled={!enableCampaignCreation}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignsCreatorNavigation;
