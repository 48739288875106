"use strict";
exports.__esModule = true;
exports.s3Url = exports.buildFilePath = void 0;
var buildFilePath = function (filename, path) {
    if (path === void 0) { path = ''; }
    var parsedPath = path.trim();
    if (parsedPath.startsWith('/')) {
        parsedPath = parsedPath.slice(1);
    }
    if (parsedPath !== '') {
        return [parsedPath, filename.trim()].join('/');
    }
    return filename.trim();
};
exports.buildFilePath = buildFilePath;
var s3Url = function (bucket, filename, path, region) {
    if (path === void 0) { path = ''; }
    var fullPath = (0, exports.buildFilePath)(filename, path);
    if (region) {
        return "https://".concat(bucket, ".s3.").concat(region, ".amazonaws.com/").concat(fullPath);
    }
    else {
        return "https://".concat(bucket, ".s3.amazonaws.com/").concat(fullPath);
    }
};
exports.s3Url = s3Url;
