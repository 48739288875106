import { format } from 'date-fns';
import { ProspectCreatedDetail } from '@platform/ui';
import { PortalAPI } from '@platform/api';
import { DetailModal } from './modal';
import { DetailModalProps } from './types';
import { ensureDate } from '@platform/helpers';

export const ProspectCreatedActivityDetailsModal: React.FC<
  DetailModalProps<PortalAPI.ProspectAPI.ActivityDataType.PROSPECT_CREATED>
> = ({ activity, onClose, safeAreaTop }) => (
  <DetailModal
    isOpen
    headerProps={{
      safeAreaTop,
      onBack: onClose,
      title: 'Prospect Created',
      isDisabled: false,
      isLoading: false,
    }}
    onClose={onClose}
  >
    <ProspectCreatedDetail
      {...activity.details}
      timestamp={format(ensureDate(activity.timestamp), "MMM d, yyyy 'at' h:mmaaa")}
    />
  </DetailModal>
);
