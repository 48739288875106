import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { PortalAPI } from '@platform/api';
import { omitBy } from 'lodash';

export const tagsReducer: AppReducer<'Tags'> = (state = initialState.Tags, action): typeof state => {
  switch (action.type) {
    case 'Tags/loaded': {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: {
          tags: action.payload.reduce<Record<string, PortalAPI.TagAPI.TagWithProspectCount>>((acc, tag) => {
            acc[tag.externalId] = tag;
            return acc;
          }, {}),
          list: action.payload.map((t) => t.externalId),
        },
      };
    }
    case 'Tags/loading': {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case 'Tags/create': {
      const { tag } = action.payload;

      return {
        ...state,
        data: {
          tags: {
            ...state.data.tags,
            [tag.externalId]: tag,
          },
          list: [tag.externalId, ...state.data.list],
        },
      };
    }
    case 'Tags/update': {
      const { value, externalId, isHidden } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          tags: {
            ...state.data.tags,
            [externalId]: {
              ...state.data.tags[externalId],
              ...(value !== undefined ? { value } : {}),
              ...(isHidden !== undefined ? { hiddenAt: isHidden ? new Date() : null } : {}),
            },
          },
        },
      };
    }
    case 'Tags/delete': {
      const { externalId } = action.payload;

      return {
        ...state,
        data: {
          tags: omitBy(state.data.tags, (t) => t.externalId === externalId),
          list: state.data.list.filter((id) => id !== externalId),
        },
      };
    }
    case 'Tags/clear': {
      return {
        ...state,
        data: {
          tags: {},
          list: [],
        },
      };
    }

    default:
      return state;
  }
};
