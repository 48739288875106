import { useCallback, useEffect, useState } from 'react';
import { useTypedDispatch } from '../../redux/state';
import { AppActions } from '../../redux/actions/app-actions';
import { useSelector } from 'react-redux';
import { Selectors } from '../../redux/selectors';
import { CampaignAssignmentUI } from './CampaignAssignment.ui';
import { getTeam, updateCampaignAssignment } from '../../redux/thunks';
import { DialogModal } from '@platform/ui';
import { GetAccessTokenFunction } from '../../types/auth0';

export const CampaignAssignmentModal = ({
  isAuthenticated,
  getAccessTokenSilently,
}: {
  isLoading: boolean;
  isAuthenticated: boolean;
  getAccessTokenSilently: GetAccessTokenFunction;
}) => {
  const dispatch = useTypedDispatch();
  const modalIsOpen = useSelector(Selectors.getCampaignAssignmentModalIsOpen);
  const [submitting, setSubmitting] = useState(false);

  const { isLoaded: isTeamLoaded, isLoading: isTeamLoading, users } = useSelector(Selectors.team);

  useEffect(() => {
    if (!isTeamLoaded && !isTeamLoading && isAuthenticated) {
      dispatch(getTeam(getAccessTokenSilently));
    }
  }, [dispatch, isTeamLoaded, isTeamLoading, getAccessTokenSilently, isAuthenticated]);

  const onClose = useCallback(() => {
    dispatch(AppActions.setCampaignAssignmentModal({ isOpen: false }));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (assignedToUser: { text: string; id: number }) => {
      if (modalIsOpen?.campaignId) {
        onClose();
        setSubmitting(true);
        dispatch(
          updateCampaignAssignment(
            modalIsOpen.campaignId,
            { id: assignedToUser.id, name: assignedToUser.text },
            getAccessTokenSilently
          )
        ).finally(() => setSubmitting(false));
      }
    },
    [dispatch, modalIsOpen?.campaignId, onClose, getAccessTokenSilently]
  );

  return (
    <DialogModal onClose={onClose} isOpen={!!modalIsOpen}>
      <div className="p-5 border-b border-solid border-slate-200 rounded-t">
        <h3 className="text-3xl font-semibold">Assign a Team Member</h3>
      </div>
      <CampaignAssignmentUI
        users={users ?? []}
        submitting={submitting}
        isTeamLoading={isTeamLoading}
        onCancel={onClose}
        onSelectUser={onSubmit}
        assignedUserId={modalIsOpen?.assignedUserId ?? null}
      />
    </DialogModal>
  );
};
