/* eslint-disable @typescript-eslint/no-redeclare */
import type { PortalAPI } from '@platform/api';
import { ActionType } from '@platform/helpers';

export type LoadedProgram = Pick<
  PortalAPI.ProgramAPI.GetProgramsResponse[0],
  | 'id'
  | 'activatedOn'
  | 'pausedOn'
  | 'updatedAt'
  | 'sendoutSpecification'
  | 'campaignStrategy'
  | 'externalTrackingId'
  | 'formSubmissionEmail'
  | 'createdAt'
  | 'trigger'
  | 'trackingPhoneNumber'
  | 'campaignSettings'
  | 'name'
  | 'isDemo'
  | 'isVisible'
>;

export const ProgramActions = {
  loaded: (programs: LoadedProgram[]) =>
    ({
      type: 'Programs/loaded',
      payload: { programs },
    } as const),
  loading: () =>
    ({
      type: 'Programs/loading',
      payload: { isLoading: true },
    } as const),
};

export type ProgramActions = ActionType<typeof ProgramActions>;
