import { CampaignMedium, getMediumDeliveryDays } from '@platform/helpers';
import { add } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Actions } from '../../../../redux/actions';
import { CampaignCreatorSelectors, Selectors } from '../../../../redux/selectors';
import { useTypedDispatch } from '../../../../redux/state';
import { MailDeliveryType } from '../../../../types/campaign';
import MailProgramUI from './mail-program.ui';

const MailProgram = () => {
  const dispatch = useTypedDispatch();
  const { programs } = useSelector(Selectors.activeN2nPrograms);
  const programId = useSelector(CampaignCreatorSelectors.getMailProgramId);
  const mailDeliveryType = useSelector(CampaignCreatorSelectors.getMailDeliveryType);
  const mailDeliveryDate = useSelector(CampaignCreatorSelectors.getMailDeliveryDate);
  const isMailProgramEnabled = useSelector(CampaignCreatorSelectors.getIsMailProgramEnabled);
  const prospectCount = useSelector(CampaignCreatorSelectors.getProspectCount);
  const totalCount = useSelector(CampaignCreatorSelectors.getProspectTotalCount);
  const onChangeProspectCount = useCallback(
    (value: number) => {
      dispatch(Actions.CampaignCreator.setProspectCount(Math.max(value, 0)));
    },
    [dispatch]
  );

  const slider = useMemo(
    () => ({
      value: prospectCount,
      total: totalCount,
      onChangeValue: onChangeProspectCount,
    }),
    [onChangeProspectCount, prospectCount, totalCount]
  );

  const maxDate = useMemo(() => {
    const now = new Date();
    // arbitrarily we don't want people scheduling past 4 months out
    now.setDate(new Date().getDate() + 120);
    return now;
  }, []);

  const currentProgram = useMemo(
    () => (!!programId ? programs.find(({ id }) => id === programId) : programs[0]),
    [programId, programs]
  );

  const disabledDates = useMemo(() => {
    const today = new Date();
    const mediumDeliveryDays = getMediumDeliveryDays(
      currentProgram?.sendoutSpecification?.length
        ? currentProgram.sendoutSpecification[0].medium
        : CampaignMedium.POSTCARD
    );

    return [
      {
        before: add(today, {
          days: 1,
        }),
        after: maxDate,
      },
      {
        before: add(today, {
          days: mediumDeliveryDays,
        }),
        after: today,
      },
    ];
  }, [currentProgram?.sendoutSpecification, maxDate]);

  const onSelectProgram = useCallback(
    (id: number) => {
      dispatch(Actions.CampaignCreator.setMailProgramId(id));
      dispatch(Actions.CampaignCreator.setMailDeliveryDate(disabledDates[1].before));
    },
    [disabledDates, dispatch]
  );

  useEffect(() => {
    if (currentProgram) {
      onSelectProgram(currentProgram.id);
    } else {
      // Default to a postcard delivery for the selected date
      dispatch(
        Actions.CampaignCreator.setMailDeliveryDate(
          add(new Date(), { days: getMediumDeliveryDays(CampaignMedium.POSTCARD) })
        )
      );
    }
  }, [currentProgram, dispatch, onSelectProgram]);

  const onSelectDate = useCallback(
    (date: Date) => {
      dispatch(Actions.CampaignCreator.setMailDeliveryDate(date));
    },
    [dispatch]
  );

  const onSelectMailDeliveryType = useCallback(
    (type: MailDeliveryType) => {
      dispatch(Actions.CampaignCreator.setMailDeliveryType(type));
    },
    [dispatch]
  );

  const onEnableMailProgram = useCallback(
    (enabled: boolean) => {
      dispatch(Actions.CampaignCreator.setIsMailProgramEnabled(enabled));
    },
    [dispatch]
  );

  return (
    <MailProgramUI
      onSelectProgram={onSelectProgram}
      programs={programs}
      selectedProgram={currentProgram ?? null}
      onSelectDate={onSelectDate}
      selectedDate={mailDeliveryDate}
      onChangeMailDeliveryType={onSelectMailDeliveryType}
      mailDeliveryType={mailDeliveryType}
      onEnableMailProgram={onEnableMailProgram}
      isMailProgramEnabled={isMailProgramEnabled}
      totalImpressions={prospectCount * (currentProgram?.sendoutSpecification?.length ?? 4)}
      disabledDates={disabledDates}
      slider={slider}
    />
  );
};

export default MailProgram;
