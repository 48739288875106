import { TrashIcon } from '@heroicons/react/24/outline';
import { PortalAPI } from '@platform/api';
import { Button, NoteDetail } from '@platform/ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTypedDispatch } from '../../redux/state';
import { deleteProspectNote, updateProspectNote } from '../../redux/thunks';
import { DetailModal } from './modal';
import { DetailModalProps } from './types';

export const NotesActivityDetailsModal: React.FC<DetailModalProps<PortalAPI.ProspectAPI.ActivityDataType.NOTES>> = ({
  activity,
  prospect,
  user,
  onClose,
  safeAreaTop,
  getAccessTokenSilently,
}) => {
  const dispatch = useTypedDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [content, setContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const allowEdit = useMemo(
    () => user?.id === activity.details?.createdByUserId || user?.role === 'owner' || user?.isSuperAdmin,
    [user, activity]
  );

  useEffect(() => {
    if (activity?.details) {
      setContent(activity.details.content ?? '');
    }
  }, [activity.details]);

  const onModalDidPresent = useCallback(() => {
    inputRef?.current?.focus();
  }, []);

  const onSave = useCallback(async () => {
    if (prospect?.externalTrackingId && activity?.details.noteId) {
      setIsSaving(true);
      return dispatch(
        updateProspectNote(getAccessTokenSilently, {
          content,
          noteId: activity.details.noteId,
          externalId: prospect.externalTrackingId,
        })
      ).finally(() => {
        setIsSaving(false);
        onClose();
      });
    }
  }, [prospect?.externalTrackingId, activity.details.noteId, dispatch, getAccessTokenSilently, content, onClose]);

  const onDelete = useCallback(async () => {
    if (prospect?.externalTrackingId && activity?.details.noteId) {
      setIsSaving(true);
      return dispatch(
        deleteProspectNote(getAccessTokenSilently, {
          noteId: activity.details.noteId,
          externalId: prospect.externalTrackingId,
        })
      ).finally(() => {
        setIsSaving(false);
        onClose();
      });
    }
  }, [prospect?.externalTrackingId, activity.details.noteId, dispatch, getAccessTokenSilently, onClose]);

  return (
    <DetailModal
      isOpen
      headerProps={{
        safeAreaTop,
        onBack: onClose,
        title: allowEdit ? 'Edit Note' : 'View Note',
        isDisabled: isSaving || !allowEdit || content === (activity.details.content ?? ''),
        saveButtonText: allowEdit ? 'Save' : '',
        isLoading: isSaving,
        onSave,
      }}
      onClose={onClose}
      onModalDidPresent={onModalDidPresent}
    >
      <div className="flex flex-col p-6 bg-white space-y-5">
        {allowEdit ? (
          <textarea
            ref={inputRef}
            name="activity-notes-textarea"
            rows={3}
            autoCapitalize="on"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            disabled={!allowEdit || isSaving}
            className="text-sm leading-5 text-gray-900 border border-gray-300 placeholder-gray-400 focus:border-blue-500 block w-full px-3 py-4 rounded-md focus:outline-none disabled:bg-gray-100 disabled:text-gray-900 disabled:border-gray-300"
          />
        ) : (
          <div className="border border-gray-200 rounded-md bg-gray-100 whitespace-pre-wrap text-sm p-[10px]">
            {content}
          </div>
        )}
        <NoteDetail
          userName={activity.details.userName}
          createdAt={activity.timestamp}
          updatedAt={activity.details.updatedAt}
        />
        {allowEdit && (
          <Button
            onClick={onDelete}
            icon={<TrashIcon className="h-5 w-5" aria-hidden="true" />}
            text="Delete Note"
            variant="dangerOutlined"
            isDisabled={isSaving}
          />
        )}
      </div>
    </DetailModal>
  );
};
