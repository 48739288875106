import { MapIcon, Bars3Icon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

type Icons = 'map' | 'menu';

export const getIconByName = (icon: Icons, isSelected: boolean, isDesktop = true) => {
  const icons = {
    map: MapIcon,
    menu: Bars3Icon,
  };
  const Component = icons[icon];

  return (
    <Component
      className={classNames(
        {
          'text-white': isSelected && isDesktop,
          'text-gray-900': !isSelected && isDesktop,
          'text-black': isSelected && !isDesktop,
          'text-gray-500': !isSelected && !isDesktop,
        },
        'flex-shrink-0 h-5 w-5'
      )}
      aria-hidden="true"
    />
  );
};
