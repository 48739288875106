/* eslint-disable @typescript-eslint/no-redeclare */
import { ActionType } from '@platform/helpers';
import { PortalAPI } from '@platform/api';

export type LoadedCampaign = PortalAPI.CampaignAPI.CampaignListResponse | PortalAPI.CampaignAPI.CampaignItemResponse;

export const CampaignActions = {
  loaded: (campaigns: LoadedCampaign[], totalCount: number, startingIndex: number) =>
    ({
      type: 'Campaigns/loaded',
      payload: {
        totalCount,
        campaigns,
        startingIndex,
      },
    } as const),
  loading: () =>
    ({
      type: 'Campaigns/loading',
    } as const),
  clear: () =>
    ({
      type: 'Campaigns/clear',
    } as const),
  set: (payload: LoadedCampaign) =>
    ({
      type: 'Campaigns/set',
      payload,
    } as const),
  update: (payload: {
    externalId: string;
    update: Pick<LoadedCampaign, 'title'> | Pick<LoadedCampaign, 'assignedToUserName' | 'assignedToUserId'>;
  }) =>
    ({
      type: 'Campaigns/update',
      payload,
    } as const),
  add: (payload: LoadedCampaign) =>
    ({
      type: 'Campaigns/add',
      payload,
    } as const),
  watch: (payload: string) =>
    ({
      type: 'Campaigns/watch',
      payload,
    } as const),
  unwatch: (payload: string) =>
    ({
      type: 'Campaigns/unwatch',
      payload,
    } as const),
  updateCampaignLeadStatus: (payload: {
    campaignId: string;
    prospectId: string;
    previousLeadStatus: number | null;
    newLeadStatus: { id: number; value: string };
  }) =>
    ({
      type: 'Campaigns/updateCampaignLeadStatus',
      payload,
    } as const),
  removeProspect: (payload: { campaignId: string; prospectId: string; leadStatusId: number | null }) =>
    ({
      type: 'Campaigns/removeProspect',
      payload,
    } as const),
};

export type CampaignActions = ActionType<typeof CampaignActions>;
