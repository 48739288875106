import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { uniqBy } from 'lodash';

export const teamReducer: AppReducer<'Team'> = (state = initialState.Team, action): typeof state => {
  switch (action.type) {
    case 'Team/loaded':
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        isLoading: false,
      };
    case 'Team/loading':
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case 'Team/invited':
      return {
        ...state,
        invites: uniqBy([action.payload, ...state.invites], (i) => i.id),
      };
    case 'Team/transfer-ownership':
      return {
        ...state,
        users: state.users.map((u) =>
          u.id === action.payload.toId
            ? { ...u, role: 'owner' }
            : u.id === action.payload.fromId
            ? { ...u, role: 'admin' }
            : u
        ),
      };
    case 'Team/invite-removed':
      return {
        ...state,
        invites: state.invites.filter((i) => i.id !== action.id),
      };
    case 'Team/user-removed':
      return {
        ...state,
        users: state.users.filter((i) => i.id !== action.id),
      };
    default:
      return state;
  }
};
