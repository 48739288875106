import { LoadedCampaign } from '../actions/campaign-actions';

export type CampaignsState = {
  campaigns: Record<string, LoadedCampaign>;
  campaignList: string[];
  isLoading: boolean;
  totalRecords: number;
  isLoaded: boolean;
  watchCampaigns: string[];
};

export const initialCampaignState: CampaignsState = {
  isLoading: false,
  campaigns: {},
  campaignList: [],
  totalRecords: 0,
  isLoaded: false,
  watchCampaigns: [],
};
