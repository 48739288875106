import { useCallback, useState, useEffect, useMemo } from 'react';
import { Drawer, ModalHeaderToolbar, ButtonList, AddToCalendarModalContent } from '@platform/ui';
import { Utils } from '@platform/ui-helpers';
// import { Drawer, ModalHeaderToolbar, Switch, ButtonList } from '@platform/ui';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
//import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { startOfToday, set, isValid, parse, format } from 'date-fns';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { createProspectAppointment, deleteProspectAppointment } from '../../redux/thunks';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppActions } from '../../redux/actions/app-actions';
import Calendar from '../../components/Calendar';
import './styles.css';

interface NewAppointmentModalProps {
  getAccessTokenSilently: GetAccessTokenFunction;
}

export const parseAndValidateDate = (date: Date | null, fallback: Date): Date =>
  date && isValid(date) ? date : fallback;

export const NewAppointmentModal = ({ getAccessTokenSilently }: NewAppointmentModalProps) => {
  const modalProps = useSelector(Selectors.getNewAppointmentModal);
  const dispatch = useTypedDispatch();
  // const [isReminderOn, setIsReminderOn] = useState(false);
  const [isAddToCalendarModalOpen, setIsAddToCalendarModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(null);

  const timeValue = useMemo(() => {
    const dateToFormat = selectedStartTime ?? new Date();
    return format(dateToFormat, 'HH:mm');
  }, [selectedStartTime]);

  useEffect(() => {
    if (modalProps?.appointmentTime) {
      const date: Date =
        typeof modalProps?.appointmentTime === 'string'
          ? new Date(modalProps?.appointmentTime)
          : modalProps?.appointmentTime;

      if (isValid(date)) {
        setSelectedDay(date);
        setSelectedStartTime(date);
      }
    }
  }, [modalProps?.appointmentTime]);

  const onClose = useCallback(() => {
    setTimeout(() => {
      setSelectedDay(null);
      setSelectedStartTime(null);
    }, 0);

    dispatch(AppActions.setNewAppointmentModal(null));
  }, [dispatch]);

  const onSave = useCallback(() => {
    if (modalProps?.prospectId) {
      const dayToUse = parseAndValidateDate(selectedDay, startOfToday());
      const timeToUse = parseAndValidateDate(selectedStartTime, new Date());

      dispatch(
        createProspectAppointment(getAccessTokenSilently, {
          externalId: modalProps?.prospectId,
          appointmentTime: set(dayToUse, {
            hours: timeToUse.getHours(),
            minutes: timeToUse.getMinutes(),
            seconds: 0,
            milliseconds: 0,
          }),
        })
      );

      onClose();
    }
  }, [modalProps?.prospectId, onClose, dispatch, selectedStartTime, selectedDay, getAccessTokenSilently]);

  const onDelete = useCallback(() => {
    if (modalProps?.prospectId) {
      dispatch(
        deleteProspectAppointment(getAccessTokenSilently, {
          externalId: modalProps?.prospectId,
        })
      );

      onClose();
    }
  }, [modalProps?.prospectId, onClose, getAccessTokenSilently, dispatch]);

  const onTimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value) {
      return setSelectedStartTime(null);
    }

    const time = parse(value, 'HH:mm', new Date());
    setSelectedStartTime(isValid(time) ? time : null);
  }, []);

  return (
    <>
      <Drawer isOpen={!!modalProps} onClose={onClose}>
        <ModalHeaderToolbar
          onBack={onClose}
          onSave={onSave}
          title={`${!!modalProps?.appointmentTime ? 'Update' : 'New'} Appointment`}
          subtitle={Utils.formatAppointmentSubTitle(modalProps?.appointmentTime)}
          isDisabled={!selectedDay}
          isLoading={false}
          hasShadow
        />
        <div className="flex flex-col py-6 bg-white">
          <div className="flex flex-col px-[14px]">
            <Calendar
              variant="inline"
              selectedDate={selectedDay}
              onSelectDate={setSelectedDay}
              disabled={[{ before: startOfToday() }]}
            />
          </div>

          <div className="flex flex-col gap-4 mt-6 px-6">
            <div className="flex items-center">
              <p className="text-md font-semibold text-gray-900 leading-6 mr-auto">Start Time</p>
              <input
                type="time"
                id="apptime"
                name="apptime"
                onChange={onTimeChange}
                value={timeValue}
                className="py-1 px-3.5 font-normal border-none text-gray-900 bg-gray-100 enabled:hover:bg-gray-200 rounded-lg text-base leading-7"
              />
            </div>

            {/*<div className="flex items-center justify-between gap-4">
            <p className="text-md font-semibold text-gray-900 leading-6">Reminder Alerts</p>
            <Switch isChecked={isReminderOn} onChange={() => setIsReminderOn((prev) => !prev)} />
          </div>*/}
          </div>

          {!!modalProps?.appointmentTime && (
            <div className="flex flex-col mt-7 px-6">
              <ButtonList
                data={[
                  /* {
                    variant: 'primary',
                    text: 'Add to Calendar',
                    icon: <PlusIcon className="h-6 w-6 pointer-events-none text-blue-500" aria-hidden="true" />,
                    onClick: onAddToCalendar,
                    },*/
                  {
                    variant: 'danger',
                    text: 'Delete Appointment',
                    icon: <TrashIcon className="h-6 w-6 pointer-events-none text-red-500" aria-hidden="true" />,
                    onClick: onDelete,
                  },
                ]}
              />
            </div>
          )}
        </div>
      </Drawer>

      <Dialog
        open={isAddToCalendarModalOpen}
        onClose={() => setIsAddToCalendarModalOpen(false)}
        className="relative z-20"
      >
        <div className="fixed bg-gray-500 bg-opacity-75 top-0 right-0 bottom-0 transition-opacity w-screen max-w-md" />
        <div className="flex flex-col items-center justify-center fixed z-20 top-0 right-0 bottom-0 w-screen max-w-md p-6">
          <div className="overflow-hidden rounded-lg bg-white shadow-xl w-full">
            <AddToCalendarModalContent onAdd={() => setIsAddToCalendarModalOpen(false)} onBack={onClose} />
          </div>
        </div>
      </Dialog>
    </>
  );
};
