import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { getTeam, deleteInvite, resendInvite, deleteUser, transferOwnership } from '../../redux/thunks';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import { TeamPageUI } from './team-page.ui';

export const TeamPage = withAuthenticatedPageLayout((props) => {
  const { isLoaded, isLoading, ...team } = useSelector(Selectors.team);
  const { user } = useSelector(Selectors.user);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(getTeam(props.auth0.getAccessTokenSilently));
    }
  }, [dispatch, isLoaded, isLoading, props.auth0.getAccessTokenSilently]);

  const onDeleteInvitation = useCallback(
    (id: number) => {
      dispatch(deleteInvite(props.auth0.getAccessTokenSilently, id));
    },
    [dispatch, props.auth0.getAccessTokenSilently]
  );

  const onDeleteUser = useCallback(
    (id: number) => {
      dispatch(deleteUser(props.auth0.getAccessTokenSilently, id));
    },
    [dispatch, props.auth0.getAccessTokenSilently]
  );

  const onResendInvitation = useCallback(
    (id: number) => {
      dispatch(resendInvite(props.auth0.getAccessTokenSilently, id, user?.email));
    },
    [dispatch, props.auth0.getAccessTokenSilently, user?.email]
  );

  const onTransferOwnership = useCallback(
    (id: number) => {
      if (user?.id) {
        dispatch(transferOwnership(props.auth0.getAccessTokenSilently, id, user?.id));
      }
    },
    [dispatch, props.auth0.getAccessTokenSilently, user?.id]
  );

  const onClickInviteNewMember = useCallback(() => dispatch(AppActions.setTeamInviteModal(true)), [dispatch]);

  return !user ? (
    <></>
  ) : (
    <TeamPageUI
      {...props}
      user={user}
      team={team}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onDeleteInvitation={onDeleteInvitation}
      onResendInvitation={onResendInvitation}
      onClickInviteNewMember={onClickInviteNewMember}
      onDeleteUser={onDeleteUser}
      onTransferOwnership={onTransferOwnership}
    />
  );
});
