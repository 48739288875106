import classNames from 'classnames';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const TooltipText = (props: { content: string | { text: string; url?: string }; className?: string }) => {
  const navigate = useNavigate();
  const goToUrl = useCallback(() => {
    if (typeof props.content !== 'string' && 'url' in props.content) {
      navigate(props.content.url!);
    }
  }, [navigate, props.content]);

  if (typeof props.content === 'string') {
    return (
      <>
        {props.content.split('|').map((t, i) => (
          <span
            key={i}
            className={classNames(props.className, 'max-w-xs text-xs font-medium text-gray-500 block my-1')}
          >
            {t}
          </span>
        ))}
      </>
    );
  } else {
    if (props.content.url) {
      return (
        <div onClick={goToUrl} className="cursor-pointer">
          <TooltipText content={props.content.text} className="text-blue-500" />
        </div>
      );
    } else {
      return <TooltipText content={props.content.text} />;
    }
  }
};
