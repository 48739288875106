import { FullUIMenuRouteDefinition, FullUIRouteDefinition, MenuVisibility, UIRouteDefinition } from './types';
import { Portal } from '@platform/ui-helpers';
import { createSelector } from 'reselect';

const allRoutes = createSelector(
  (r: Record<Portal.UIRoutes, UIRouteDefinition>) => r,
  (routes) =>
    (Object.keys(routes) as Portal.UIRoutes[]).map((r) => ({
      route: r,
      ...routes[r],
    }))
);

export const getAllRoutes: (routes: Record<Portal.UIRoutes, UIRouteDefinition>) => FullUIRouteDefinition[] = (routes) =>
  allRoutes(routes);

export const getAllPrimaryRoutes: (
  routes: Record<Portal.UIRoutes, UIRouteDefinition>
) => FullUIMenuRouteDefinition[] = (routes) =>
  createSelector(
    (r: Record<Portal.UIRoutes, UIRouteDefinition>) => allRoutes(r),
    (r) => r.filter(({ menu }) => menu?.visible === MenuVisibility.VISIBLE_PRIMARY)
  )(routes) as FullUIMenuRouteDefinition[];

export const getAllSecondaryRoutes: (
  routes: Record<Portal.UIRoutes, UIRouteDefinition>
) => FullUIMenuRouteDefinition[] = (routes) =>
  createSelector(
    (r: Record<Portal.UIRoutes, UIRouteDefinition>) => allRoutes(r),
    (r) => r.filter(({ menu }) => menu?.visible === MenuVisibility.VISIBLE_SECONDARY)
  )(routes) as FullUIMenuRouteDefinition[];
