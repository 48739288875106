import { Utils } from '@platform/ui-helpers';
import { Point } from 'geojson';
import L from 'leaflet';
import { FeatureGroup } from 'react-leaflet';
import { MarkerClusterGroup } from '../Map/marker-cluster-group';
import { PinMarker, Circle } from '../Map/Markers';

export interface AreasProps {
  markers: { center: Point; radius: number; tooltip?: Utils.TooltipContent }[];
}

const getClusterCount = (markers: L.Marker[]) => markers.filter((m) => !(m.options as any).radius).length;

export const CampaignAreas = ({ markers }: AreasProps) => {
  return (
    <MarkerClusterGroup getClusterCount={getClusterCount}>
      {markers &&
        markers.map(({ center, radius, tooltip }, i) => (
          <FeatureGroup key={`feature-group-${i}`}>
            <PinMarker key={`marker-${i}`} position={center} tooltipData={tooltip} />
            <Circle key={`circle-${i}`} center={center} radius={radius} />
          </FeatureGroup>
        ))}
    </MarkerClusterGroup>
  );
};
