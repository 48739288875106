export var MailRemovedReasons;
(function (MailRemovedReasons) {
    MailRemovedReasons["BOUNCED"] = "bounced";
    MailRemovedReasons["CONVERTED"] = "converted";
    MailRemovedReasons["OPT_OUT"] = "opt_out";
    MailRemovedReasons["DISQUALIFIED"] = "disqualified";
    MailRemovedReasons["STOPPED"] = "stopped";
    MailRemovedReasons["FAILED_VERIFICATION"] = "failed_verification";
    MailRemovedReasons["ORDER_FAILED"] = "order_failed";
})(MailRemovedReasons || (MailRemovedReasons = {}));
export var QualificationStatus;
(function (QualificationStatus) {
    QualificationStatus["QUALIFIED"] = "qualified";
    QualificationStatus["DISQUALIFIED"] = "disqualified";
    QualificationStatus["UNQUALIFIED"] = "unqualified";
})(QualificationStatus || (QualificationStatus = {}));
