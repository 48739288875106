"use strict";
exports.__esModule = true;
exports.getPoint = exports.getLongitude = exports.getLatitude = void 0;
var getLatitude = function (value) {
    if (!value) {
        return null;
    }
    var latitude = typeof value === 'string' ? parseFloat(value) : value;
    return !Number.isNaN(latitude) && Number.isFinite(latitude) && latitude >= -90 && latitude <= 90 ? latitude : null;
};
exports.getLatitude = getLatitude;
var getLongitude = function (value) {
    if (!value) {
        return null;
    }
    var longitude = typeof value === 'string' ? parseFloat(value) : value;
    return !Number.isNaN(longitude) && Number.isFinite(longitude) && longitude >= -180 && longitude <= 180
        ? longitude
        : null;
};
exports.getLongitude = getLongitude;
var getPoint = function (value) {
    if (!value.latitude || !value.longitude) {
        return null;
    }
    var latitude = (0, exports.getLatitude)(value.latitude);
    var longitude = (0, exports.getLongitude)(value.longitude);
    return latitude && longitude
        ? {
            type: 'Point',
            coordinates: [longitude, latitude]
        }
        : null;
};
exports.getPoint = getPoint;
