import { Point } from 'geojson';
import { Circle as CircleMarker } from 'react-leaflet';

const fromPoint: (p: Point) => L.LatLngExpression = (p: Point) => ({
  lat: p.coordinates[1],
  lng: p.coordinates[0],
});

export const Circle = (props: { center: Point; radius: number }) => (
  <CircleMarker center={fromPoint(props.center)} radius={props.radius} />
);
