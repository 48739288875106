import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { MapInstructionsStepType, ProspectCountStepType } from '../../types/campaign';

export const campaignCreatorReducer: AppReducer<'CampaignCreator'> = (
  state = initialState.CampaignCreator,
  action
): typeof state => {
  switch (action.type) {
    case 'CampaignCreator/setPolygon':
      return {
        ...state,
        polygon: action.payload,
      };
    case 'CampaignCreator/setResetProspectCount':
      return {
        ...state,
        prospectCount: null,
        prospectCountStep: ProspectCountStepType.CALCULATING,
        mapInstructionStep: MapInstructionsStepType.SELECT,
      };
    case 'CampaignCreator/setProspectTotalCount':
      return {
        ...state,
        prospectTotalCount: action.payload,
      };
    case 'CampaignCreator/setProspectCount':
      return {
        ...state,
        prospectCount: action.payload,
      };
    case 'CampaignCreator/setProspectCountStep':
      return {
        ...state,
        prospectCountStep: action.payload,
      };
    case 'CampaignCreator/setMailProgramId':
      return {
        ...state,
        mailProgramId: action.payload,
      };
    case 'CampaignCreator/setIsMailProgramEnabled':
      return {
        ...state,
        isMailProgramEnabled: action.payload,
      };
    case 'CampaignCreator/setSalesRepId':
      return {
        ...state,
        salesRepId: action.payload,
      };
    case 'CampaignCreator/setIsSubmitting':
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case 'CampaignCreator/setMapInstructionStep':
      return {
        ...state,
        mapInstructionStep: action.payload,
      };

    case 'CampaignCreator/setOnResetShape':
      return {
        ...state,
        onResetShape: action.payload,
      };
    case 'CampaignCreator/setCampaignTitle':
      return {
        ...state,
        campaignTitle: action.payload,
      };
    case 'CampaignCreator/setMailDeliveryType':
      return {
        ...state,
        mailDeliveryType: action.payload,
      };
    case 'CampaignCreator/setMailDeliveryDate':
      return {
        ...state,
        mailDeliveryDate: action.payload,
      };
    case 'CampaignCreator/setClearForm':
      return {
        ...initialState.CampaignCreator,
      };
    default:
      return state;
  }
};
