import formatter from 'phone-formatter';
import { Menu } from '@headlessui/react';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { LoadedTeamResponse } from '../../redux/actions/team-actions';
import { Dropdown } from '../Dropdown';
import { WithAuthenticatedPageInjectedProps } from '../Layout/authenticated-page';
import { PageHeader } from '../Layout/PageHeader';
import { assertUnreachableSafe } from '@platform/helpers';
import { LoadedUser } from '../../redux/actions/user-actions';

interface TeamPageUIProps extends WithAuthenticatedPageInjectedProps {
  team: LoadedTeamResponse;
  user: LoadedUser;
  isLoaded: boolean;
  isLoading: boolean;
  onResendInvitation: (id: number) => void;
  onDeleteInvitation: (id: number) => void;
  onDeleteUser: (id: number) => void;
  onTransferOwnership: (id: number) => void;
  onClickInviteNewMember: () => void;
  canInviteNewMember?: boolean;
}

enum UserType {
  INVITE = 'invite',
  USER = 'user',
}
type UserTypes = {
  [UserType.INVITE]: LoadedTeamResponse['invites'][0];
  [UserType.USER]: LoadedTeamResponse['users'][0];
};

export const TeamPageUI = (props: TeamPageUIProps) => {
  const rows = useMemo(
    () =>
      orderBy(
        [
          ...props.team.invites.map((user) => ({
            ...user,
            status: UserType.INVITE,
            isMe: false,
            canDelete: user.sentByUserId === props.user.id || props.user.role === 'owner' || props.user.isSuperAdmin,
            canTransfer: false,
          })),
          ...props.team.users.map((user) => ({
            ...user,
            status: UserType.USER,
            canDelete: user.role !== 'owner' && (props.user.role === 'owner' || props.user.isSuperAdmin),
            canTransfer: user.role !== 'owner' && (props.user.role === 'owner' || props.user.isSuperAdmin),
            isMe: user.id === props.user.id,
          })),
        ],
        (a) => a.createdAt,
        'desc'
      ).map(({ status, isMe, canDelete, canTransfer, ...user }, i) => (
        <TeamListRow
          key={i}
          status={status}
          user={user}
          isSuperAdmin={!!props.user.isSuperAdmin}
          onDeleteInvitation={props.onDeleteInvitation}
          onResendInvitation={props.onResendInvitation}
          onDeleteUser={props.onDeleteUser}
          onTransferOwnership={props.onTransferOwnership}
          canDelete={!!canDelete}
          canTransfer={!!canTransfer}
        />
      )),
    [props]
  );

  return (
    <div>
      <PageHeader
        icon={UserGroupIcon}
        iconHeading="Team"
        title="Team Members"
        newAction={{
          visible: props.canInviteNewMember ?? true,
          text: <>Invite</>,
          onClick: props.onClickInviteNewMember,
        }}
      />
      <div className="flex flex-col max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="flex items-center justify-center md:shadow md:bg-white">
            <div className="w-full">
              <div className="w-full">
                <div
                  className={`hidden md:grid ${
                    props.user.isSuperAdmin ? 'grid-cols-12' : 'grid-cols-9'
                  } text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 md:rounded-t-md`}
                >
                  <div className={`p-3 border-b border-gray-200 col-span-3`}>Name</div>
                  <div className={`p-3 border-b border-gray-200 col-span-3`}> Email</div>
                  {props.user.isSuperAdmin && <div className={`p-3 border-b border-gray-200 col-span-3`}> Phone</div>}
                  <div className="p-3 border-b border-gray-200 col-span-2">Status</div>
                  <div className="p-3 border-b border-gray-200 col-span-1">
                    <span className="hidden">Action</span>
                  </div>
                </div>

                <div className={classNames({ 'animate-pulse': props.isLoading }, 'flex-1 sm:flex-none w-full')}>
                  {props.isLoading ? [1, 2, 3].map((i) => <TeamListEmptyRow key={i} />) : rows}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col text-center my-16">
        <h2 className="mt-2 text-3xl font-extrabold text-gray-900">Invite a team member</h2>
        <p className="mt-1 text-sm text-gray-500">
          Lead Scout is better with a team, invite your team members to start collaborating!
        </p>
        <div className="mt-6">
          <button
            className="border border-gray-300 text-gray-700 hover:bg-gray-200 hover:border-gray-300 transition-colors hover:shadow-sm active:shadow-none active:bg-gray-300 sm:text-base text-sm leading-4 font-semibold h-[40px] sm:h-[52px] inline-flex justify-center items-center rounded-lg  px-3 sm:px-6 focus:outline-none mt-4"
            onClick={props.onClickInviteNewMember}
          >
            Invite a Team Member
          </button>
        </div>
      </div>
    </div>
  );
};

const TeamListEmptyRow = () => (
  <div className="flex flex-1 md:grid flex-wrap md:grid-cols-9 items-center shadow border-b border-gray-200 bg-white md:shadow-none md:bg-transparent mb-2 md:mb-0 rounded-md md:rounded-none">
    <div className={`p-3 order-1 w-1/4 md:w-auto md:col-span-3`}>
      <div className="flex flex-grow items-center">
        <div className="h-4 bg-gray-100 rounded w-full block"></div>
      </div>
    </div>
    <div className={`p-3 order-2 w-3/4 md:w-auto md:col-span-3`}>
      <div className="flex flex-grow items-center">
        <div className="h-4 bg-gray-100 rounded w-3/4 block"></div>
      </div>
    </div>
    <div className={`p-3 order-3 w-3/4 md:w-auto md:col-span-2`}>
      <div className="flex flex-grow items-center">
        <div className="w-full">
          <div className="h-4 bg-gray-100 rounded w-4/6 block"></div>
        </div>
      </div>
    </div>
    <div className="p-3 md:pr-6 order-4 w-1/4 md:w-auto text-right md:col-span-1 text-sm text-gray-900 self-start md:self-auto">
      <button
        type="button"
        className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400 pointer-events-none" aria-hidden="true" />
      </button>
    </div>
  </div>
);

const TeamListRow = <T extends keyof UserTypes>(props: {
  status: T;
  user: UserTypes[T];
  canDelete: boolean;
  canTransfer: boolean;
  onDeleteInvitation: (id: number) => void;
  onResendInvitation: (id: number) => void;
  onDeleteUser: (id: number) => void;
  onTransferOwnership: (id: number) => void;
  isSuperAdmin: boolean;
}) => {
  const onDeleteInvite = useCallback((id: number) => () => props.onDeleteInvitation(id), [props]);
  const onDeleteUser = useCallback((id: number) => () => props.onDeleteUser(id), [props]);
  const onTransferOwnership = useCallback((id: number) => () => props.onTransferOwnership(id), [props]);

  const onResend = useCallback(() => {
    if (props.status === 'invite') {
      props.onResendInvitation(props.user.id);
    }
  }, [props]);

  return (
    <div
      className={`flex md:grid flex-wrap ${
        props.isSuperAdmin ? 'md:grid-cols-12' : 'md:grid-cols-9'
      } items-center shadow border-b border-gray-200 bg-white md:shadow-none md:bg-transparent mb-2 md:mb-0 rounded-md md:rounded-none`}
    >
      <div className={`p-3 order-1 md:col-span-3 justify-center`}>
        {props.user.firstName} {props.user.lastName}
        {props.user.role === 'owner' && <div className="text-gray-500 text-sm">Account Owner</div>}
      </div>
      <div className={`p-3 md:w-auto order-3 md:col-span-3`}>
        <div className="text-sm font-medium text-gray-900">{props.user.email}</div>
      </div>
      {props.isSuperAdmin && (
        <div className={`p-3 md:w-auto order-3 md:col-span-3`}>
          <div className="text-sm font-medium text-gray-900">
            {props.user.phoneNumber ? formatter.format(props.user.phoneNumber, '(NNN) NNN-NNNN') : ''}
          </div>
        </div>
      )}
      <div className={`p-3 order-4 md:col-span-2 w-3/4 md:w-auto`}>
        <div className="flex flex-grow items-center">
          <div className="">
            <div className="text-sm font-medium text-gray-900">
              {props.status === 'invite' ? 'Invited' : 'Joined'} {moment(props.user.createdAt).format('L')}
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 md:pr-6 order-4 text-right  md:col-span-1 text-sm text-gray-900 self-start md:self-auto w-1/4 md:w-auto">
        <Dropdown
          data={
            props.status === 'invite'
              ? [
                  {
                    text: 'Resend Invitation',
                    onClick: onResend,
                    showDivider: true,
                  },
                  {
                    text: 'Delete Invitation',
                    onClick: onDeleteInvite(props.user.id),
                    isDisabled: !props.canDelete,
                  },
                ]
              : props.status === 'user'
              ? [
                  {
                    text: 'Delete User',
                    onClick: onDeleteUser(props.user.id),
                    isDisabled: !props.canDelete,
                    showDivider: props.canTransfer,
                  },
                  ...(props.canTransfer
                    ? [
                        {
                          text: 'Transfer Ownership',
                          onClick: onTransferOwnership(props.user.id),
                        },
                      ]
                    : []),
                ]
              : assertUnreachableSafe(props.status)
          }
        >
          <Menu.Button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400 pointer-events-none" aria-hidden="true" />
          </Menu.Button>
        </Dropdown>
      </div>
    </div>
  );
};
