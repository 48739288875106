import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

export const SignupRedirect: React.FunctionComponent<{}> = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ screen_hint: 'signup', prompt: 'login' });
    }
  }, [loginWithRedirect, isAuthenticated, isLoading]);

  return <></>;
};
