"use strict";
exports.__esModule = true;
exports.calculateMailStatus = void 0;
var helpers_1 = require("@platform/helpers");
var campaign_1 = require("@platform/model/dist/types/campaign");
var calculateMailStatus = function (currentMailStatus, action) {
    var _a;
    if (action === 'stop') {
        return currentMailStatus === campaign_1.ProspectMailStatus.ACTIVE
            ? campaign_1.ProspectMailStatus.STOPPED
            : currentMailStatus === campaign_1.ProspectMailStatus.SCHEDULED
                ? campaign_1.ProspectMailStatus.OFF
                : currentMailStatus;
    }
    else if (action === 'start') {
        return currentMailStatus === campaign_1.ProspectMailStatus.OFF || currentMailStatus === campaign_1.ProspectMailStatus.STOPPED
            ? campaign_1.ProspectMailStatus.ACTIVE
            : currentMailStatus;
    }
    else {
        return (_a = (0, helpers_1.assertUnreachableSafe)(action)) !== null && _a !== void 0 ? _a : campaign_1.ProspectMailStatus.NONE;
    }
};
exports.calculateMailStatus = calculateMailStatus;
