import { Fragment, useMemo } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { WithAuth0Props } from '@auth0/auth0-react';
import { getPrimaryRoutes, getSecondaryRoutes } from '../../routes';
import { Nav } from './Nav';
import { UserPermissions } from '@platform/model/dist/types/user';
import { CompanySelectSearch } from './company-select-search';
import { CompanySelectList } from './company-select-list';

export interface MobileMenuProps extends WithAuth0Props {
  sidebarOpen: boolean;
  closeMenu: () => void;
  companyName?: string;
  externalId?: string;
  name: string;
  isSuperAdmin: boolean;
  isImpersonating: boolean;
  isSubscribed: boolean | null;
  hasCampaigns: boolean;
  hasPrograms: boolean;
  logout: () => void;
  stopImpersonating: () => void;
  permissions?: UserPermissions;
}

export function MobileMenu({
  isSuperAdmin,
  isImpersonating,
  isSubscribed,
  hasPrograms,
  hasCampaigns,
  name,
  auth0,
  sidebarOpen,
  closeMenu,
  companyName,
  externalId,
  logout,
  stopImpersonating,
  permissions,
}: MobileMenuProps) {
  const Select = useMemo(
    () => (!externalId || !companyName ? null : isSuperAdmin ? CompanySelectSearch : CompanySelectList),
    [companyName, externalId, isSuperAdmin]
  );
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={closeMenu}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600/75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-auto flex flex-col max-w-xs w-full pt-5 px-2 bg-blue-700">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={closeMenu}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            {!!Select && (
              <Select companyName={companyName} externalId={externalId} getAccessToken={auth0.getAccessTokenSilently} />
            )}
            <Nav
              email={auth0.user?.email}
              userName={name}
              isSuperAdmin={isSuperAdmin}
              isImpersonating={isImpersonating}
              isSubscribed={isSubscribed}
              hasCampaigns={hasCampaigns}
              hasPrograms={hasPrograms}
              primaryRoutes={getPrimaryRoutes()}
              secondaryRoutes={getSecondaryRoutes()}
              logout={logout}
              stopImpersonating={stopImpersonating}
              permissions={permissions}
            />
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
