import React, { useEffect } from 'react';
import { MapContainer, TileLayer, LayersControl, useMap } from 'react-leaflet';
import classNames from 'classnames';
import L from 'leaflet';
import { Constants } from '@platform/app-config';
import { Polygon } from 'geojson';
import { Spinner } from '@platform/ui';

// dirty hack (🙈) in order to make in order to make marker icons work
delete L.Icon.Default.prototype['_getIconUrl' as any as keyof L.Icon.Default];
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});
// end dirty hack

const mapAttribution =
  'Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>';

export interface MapProps {
  center?: L.LatLngExpression;
  zoom?: number;
  classes?: string;
  boundingBox?: Polygon;
  fullHeight?: boolean;
}

const MapBounds = ({ boundingBox }: MapProps) => {
  const map = useMap();
  useEffect(() => {
    if (boundingBox && boundingBox.coordinates) {
      map.fitBounds(L.geoJSON(boundingBox).getBounds());
    }
  }, [boundingBox, map]);
  return <></>;
};

export const MapUI: React.FC<React.PropsWithChildren<MapProps>> = ({
  children,
  center,
  zoom,
  classes,
  boundingBox,
  fullHeight,
}) => (
  <MapContainer
    zoom={zoom ?? 8}
    center={center}
    className={classNames({ 'w-full flex-shrink-0': true, 'h-500': !fullHeight, 'h-full': fullHeight }, classes)}
  >
    <LayersControl position="topright">
      <LayersControl.BaseLayer checked name="Satellite">
        <TileLayer
          url={`${Constants.MAPBOX_TILES_SATELLITE_URL}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!}`}
          attribution={mapAttribution}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Streets">
        <TileLayer
          url={`${Constants.MAPBOX_TILES_MAP_URL}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!}`}
          attribution={mapAttribution}
        />
      </LayersControl.BaseLayer>
    </LayersControl>
    <MapBounds boundingBox={boundingBox} />
    {children}
  </MapContainer>
);

export const MapUILoading: React.FC<React.PropsWithChildren<{ classes?: string; fullHeight: boolean }>> = ({
  classes,
  fullHeight,
}) => (
  <div
    className={classNames(
      { 'h-500': !fullHeight, 'h-full': fullHeight },
      'animate-pulse',
      'flex',
      'w-full',
      'bg-white',
      'justify-center',
      'items-center',
      classes
    )}
  >
    <Spinner size="xl" variant="neutral" />
  </div>
);
