"use strict";
exports.__esModule = true;
exports.addServerErrors = void 0;
function addServerErrors(errors, setError) {
    return Object.keys(errors).forEach(function (key) {
        setError(key, {
            type: 'server',
            message: errors[key].join('. ')
        });
    });
}
exports.addServerErrors = addServerErrors;
