import type { LoadedProgram } from '../actions/program-actions';

export type ProgramsState = {
  isLoading: boolean;
  isLoaded: boolean;
  programs: Record<number, LoadedProgram>;
};

export const initialProgramsState: ProgramsState = {
  isLoaded: false,
  isLoading: false,
  programs: {},
};
