/* eslint-disable @typescript-eslint/no-redeclare */
import { ActionType, DashboardDateFilter } from '@platform/helpers';
import type { PortalAPI } from '@platform/api';

export type LeadStatusActivityResponse = PortalAPI.DashboardAPI.Responses['GET /dashboard/lead-status-activity'];
export type ImpressionsResponse = PortalAPI.DashboardAPI.Responses['GET /dashboard/impressions'];
export type TagActivityResponse = PortalAPI.DashboardAPI.Responses['GET /dashboard/tag-activity'];

export const DashboardActions = {
  setDateFilter: (payload: DashboardDateFilter) =>
    ({
      type: 'Dashboard/set-date-filter',
      payload,
    } as const),
  leadStatusActivity: (payload: { data: LeadStatusActivityResponse; filter: DashboardDateFilter }) =>
    ({
      type: 'Dashboard/prospect-activity',
      payload,
    } as const),
  prospectActivityLoading: (payload: { filter: DashboardDateFilter['id'] }) =>
    ({
      type: 'Dashboard/prospect-activity-loading',
      payload,
    } as const),
  impressions: (payload: { data: ImpressionsResponse; filter: DashboardDateFilter }) =>
    ({
      type: 'Dashboard/impressions',
      payload,
    } as const),
  impressionsLoading: (payload: { filter: DashboardDateFilter['id'] }) =>
    ({
      type: 'Dashboard/impressions-loading',
      payload,
    } as const),
  tags: (payload: { data: TagActivityResponse; filter: DashboardDateFilter }) =>
    ({
      type: 'Dashboard/tag-activity',
      payload,
    } as const),
  tagsLoading: (payload: { filter: DashboardDateFilter['id'] }) =>
    ({
      type: 'Dashboard/tags-loading',
      payload,
    } as const),
};

export type DashboardActions = ActionType<typeof DashboardActions>;
