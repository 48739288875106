import React from 'react';
import { BreadcrumbsLoading, MailCardLoading, CircularProgressLoading, PillsLoading, TabsLoading } from '@platform/ui';

const Card = () => (
  <div className="flex w-1/4 items-center p-4 border border-solid border-gray-200 rounded-lg mr-2.5">
    <div className="flex flex-col grow">
      <div className="h-4 bg-gray-100 rounded w-1/3 block mb-1"></div>
      <div className="h-4 bg-gray-100 rounded w-1/2 block"></div>
    </div>
    <div className="h-9 w-[77px] bg-gray-100 rounded-full"></div>
  </div>
);

const DesktopLoading = () => (
  <div className="flex flex-col bg-white shadow">
    <div className="flex flex-col px-4 lg:px-10 lg:pt-8 pb-6 lg:pb-8 animate-pulse">
      <div className="flex mb-8">
        <BreadcrumbsLoading animate={false} />
      </div>
      <div className="flex h-[72px] w-full items-center justify-between mb-8">
        <div className="flex w-full items-center">
          <CircularProgressLoading animate={false} />
          <div className="flex w-full flex-col ml-4">
            <div className="h-9 bg-gray-100 rounded w-40 block animate-pulse mb-1"></div>
            <div className="h-5 bg-gray-100 rounded w-32 block animate-pulse"></div>
          </div>
        </div>
        <PillsLoading animate={false} />
      </div>
      <div className="flex w-full min-h-[98px]">
        <Card />
        <div className="flex flex-col w-1/4">
          <MailCardLoading animate={false} className="h-full" />
        </div>
      </div>
    </div>
  </div>
);

const MobileLoading = () => (
  <div className="flex flex-col bg-white shadow">
    <div className="flex flex-col px-4 pb-6 animate-pulse">
      <div className="flex mb-4">
        <div className="h-9 w-32 bg-gray-100 rounded-xl block"></div>
      </div>
      <div className="flex h-[69px] w-full items-center justify-between mb-4">
        <div className="flex w-full items-center">
          <div className="flex w-full flex-col">
            <div className="h-9 bg-gray-100 rounded w-1/4 block animate-pulse mb-1"></div>
            <div className="h-5 bg-gray-100 rounded w-1/6 block animate-pulse"></div>
          </div>
        </div>
        <div className="h-9 w-[77px] bg-gray-100 rounded-full"></div>
      </div>
      <div className="flex h-[72px] justify-between mb-4">
        <div className="flex items-center mr-4">
          <div className="h-8 w-8 rounded-full bg-gray-200 shrink-0"></div>
          <div className="h-5 ml-2 bg-gray-100 rounded w-32 block"></div>
        </div>
        <CircularProgressLoading animate={false} />
      </div>
      <div className="flex flex-col w-full">
        <MailCardLoading animate={false} className="h-full min-h-[90px]" />
      </div>
    </div>
    <TabsLoading animate />
  </div>
);

const Loading = ({ isDesktop = true }) => (isDesktop ? <DesktopLoading /> : <MobileLoading />);

export default Loading;
