import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import { parsePhoneNumber } from 'react-phone-number-input/input';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { NotificationType } from '../../types/notification';
import { updateCompany } from '../../redux/thunks';
import { useTypedDispatch } from '../../redux/state';
import { useSelector } from 'react-redux';
import { PortalAPI } from '@platform/api';
import { CompanyUI } from './company-page.ui';
import { useLocation } from 'react-router-dom';
import { CompanyTab, CompanyTabId } from './types';

const getChanges = (
  defaultValues: PortalAPI.CompanyAPI.Requests['PATCH /company'],
  newValues: PortalAPI.CompanyAPI.Requests['PATCH /company']
) =>
  (Object.keys(newValues) as (keyof typeof newValues)[]).reduce<typeof newValues>((acc, key) => {
    if (
      newValues[key] !== defaultValues[key] &&
      // newValues[key] !== undefined &&
      !(newValues[key] === '' && defaultValues[key] === null)
    ) {
      acc[key] = newValues[key] || null;
    }
    return acc;
  }, {});

export const CompanyPage = () => {
  const { hash } = useLocation();
  const dispatch = useTypedDispatch();
  const [isSameFormContent, setIsSameFormContent] = useState(true);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const isImpersonatingState = useSelector(Selectors.isImpersonating);
  const { isLoading, user } = useSelector(Selectors.user);
  const company = useSelector(Selectors.company);

  useEffect(() => {
    // Grab the initial value and don't update.
    // This prevents a flicker of the 'create company' button
    setIsImpersonating(isImpersonatingState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs: CompanyTab[] = useMemo(
    () =>
      user?.permissions.canManageMultiple
        ? [
            { id: 'my-company', name: 'My Company' },
            { id: 'all-companies', name: 'Invited Companies' },
          ]
        : [],
    [user?.permissions.canManageMultiple]
  );
  const currentTab = useMemo(
    () =>
      hash && tabs.map((t) => t.id).includes(hash.substring(1) as CompanyTabId)
        ? (hash.substring(1) as CompanyTabId)
        : 'my-company',
    [hash, tabs]
  );

  const defaultValues: PortalAPI.CompanyAPI.Requests['PATCH /company'] = useMemo(
    () => ({
      // we allow for comma-separated emails but let's just show the first one. This is a legacy concept anyway.
      email: company.formSubmissionEmail?.split(',')[0]?.trim() ?? null,
      name: company.name ?? null,
      phoneNumber: company.phoneNumber ? parsePhoneNumber(company.phoneNumber, 'US')?.number.toString() : null,
      website: company.website ?? null,
    }),
    [company.formSubmissionEmail, company.phoneNumber, company.website, company.name]
  );

  const {
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors, isSubmitting },
    control,
  } = useForm<PortalAPI.CompanyAPI.Requests['PATCH /company']>({
    defaultValues,
  });

  useEffect(() => {
    if (isLoading) return;

    // Only enable the save button if the information is different
    const subscription = watch((values) => {
      const isEqual = Object.keys(getChanges(defaultValues, values)).length === 0;
      setIsSameFormContent(isEqual);
    });

    reset(defaultValues);

    return () => subscription.unsubscribe();
  }, [isLoading, watch, reset, defaultValues]);

  const onUpdateCompanyInformation = useCallback(async () => {
    try {
      const diff = getChanges(defaultValues, getValues());
      await dispatch(updateCompany(getAccessTokenSilently, diff));

      dispatch(
        AppActions.notification({
          type: NotificationType.success,
          title: 'Company updated!',
          description: 'Company information successfully updated',
        })
      );
    } catch {
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Error updating!',
          description: 'Something went wrong when updating your company information',
        })
      );
    }
  }, [getValues, dispatch, getAccessTokenSilently, defaultValues]);

  const onClickNewCompany = useCallback(() => {
    dispatch(AppActions.setCompanyInviteModal({ isOpen: true }));
  }, [dispatch]);

  return (
    <CompanyUI
      control={control}
      handleSubmit={handleSubmit(onUpdateCompanyInformation)}
      errors={errors}
      submitting={isSubmitting}
      isSameContent={isSameFormContent}
      initialValues={defaultValues}
      onClickNewCompany={onClickNewCompany}
      currentTab={currentTab}
      tabs={tabs}
      isImpersonating={isImpersonating}
      user={user}
    />
  );
};
