import * as React from 'react';
import { UseFormRegister, FieldErrors, FieldPath, Control } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import type { PortalAPI } from '@platform/api';
import { Spinner } from '../../components/Spinner';

export type TeamInviteUIProps = {
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  register: UseFormRegister<PortalAPI.UserAPI.InviteUserRequest>;
  errors?: FieldErrors<PortalAPI.UserAPI.InviteUserRequest>;
  submitting: boolean;
  control: Control<PortalAPI.UserAPI.InviteUserRequest>;
  onCancel: () => void;
};

const InputError = (props: {
  errors?: FieldErrors<PortalAPI.AuthAPI.SignupPayload>;
  name: FieldPath<PortalAPI.AuthAPI.SignupPayload>;
}) => (
  <ErrorMessage
    errors={props.errors}
    name={props.name}
    render={({ message }) => <span className="text-sm text-red-500">{message}</span>}
  />
);

const inputClasses =
  'block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm disabled:bg-slate-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none';

export const TeamInviteUI: React.FunctionComponent<TeamInviteUIProps> = (props) => {
  return (
    <div className="min-h-full flex flex-col justify-center p-5">
      <div className="sm:bg-white sm:mx-auto sm:w-full sm:max-w-md">
        <form onSubmit={props.handleSubmit} className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                className={inputClasses}
                {...props.register('firstName', { required: 'First Name is required' })}
              />
              <InputError errors={props.errors} name="firstName" />
            </div>
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                className={inputClasses}
                {...props.register('lastName', { required: 'Last Name is required' })}
              />
              <InputError errors={props.errors} name="lastName" />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1">
              <input
                type="email"
                className={inputClasses}
                {...props.register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
              <InputError errors={props.errors} name="email" />
            </div>
          </div>
          <div className="flex flex-row py-4 justify-end">
            <button
              className="sm:text-base leading-4 mr-8 text-red-500 background-transparent font-bold text-sm outline-none focus:outline-none hover:text-red-600 hover:underline"
              type="button"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              disabled={props.submitting}
              className="sm:text-base text-sm leading-4 font-semibold text-white h-[40px] sm:h-[52px] bg-blue-500 hover:bg-blue-600 active:bg-blue-500 inline-flex justify-center items-center rounded-lg border border-transparent px-3 sm:px-6 shadow-sm focus:outline-none"
            >
              {props.submitting ? <Spinner className="h-5 w-5" /> : 'Send Invite'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
