import React, { useEffect, useMemo, useCallback } from 'react';
// Components
import CampaignCreateDetailsUI from './create-details.ui';
// Store
import { useSelector } from 'react-redux';
import { AddressSelectors, CampaignCreatorSelectors, Selectors } from '../../../../redux/selectors';
import { getTeam } from '../../../../redux/thunks';
import { useTypedDispatch } from '../../../../redux/state';
import { Actions } from '../../../../redux/actions';
// Types
import { Item } from '@platform/ui/src/SelectMenu';
import { GetAccessTokenFunction } from '../../../../types/auth0';

export interface Props {
  getAccessToken: GetAccessTokenFunction;
}

const SalesRep = ({ getAccessToken }: Props) => {
  const dispatch = useTypedDispatch();
  const { isLoaded, isLoading, users } = useSelector(Selectors.team);
  const { user } = useSelector(Selectors.user);
  const salesRepId = useSelector(CampaignCreatorSelectors.getSalesRepId);
  const addressSearched = useSelector(AddressSelectors.addressLookup);
  const campaignTitle = useSelector(CampaignCreatorSelectors.getCampaignTitle);

  const defaultTitle = useMemo(() => {
    return addressSearched.isLoaded && addressSearched.address && !('error' in addressSearched.address)
      ? `Neighbors to ${addressSearched.address.address1}`
      : null;
  }, [addressSearched.address, addressSearched.isLoaded]);

  const onTitleChange = useCallback(
    (title: string) => dispatch(Actions.CampaignCreator.setCampaignTitle(title)),
    [dispatch]
  );

  const members = useMemo(() => {
    return !isLoading && users
      ? users.map(({ id, firstName, lastName }) => ({
          id,
          text: `${firstName} ${lastName}`,
          avatar: '',
        }))
      : [];
  }, [isLoading, users]);

  const currentMember = useMemo(() => {
    if (members && !!members.length) {
      return !!salesRepId
        ? members.find(({ id }) => id === salesRepId)
        : (user ? members.find((m) => m.id === user.id) : members[0]) ?? members[0];
    } else {
      return { id: 0, text: 'Unassigned', avatar: '' };
    }
  }, [members, salesRepId, user]) as Item;

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(getTeam(getAccessToken));
    }
  }, [dispatch, getAccessToken, isLoaded, isLoading]);

  const onSelectMember = useCallback(
    (member: Item) => {
      dispatch(Actions.CampaignCreator.setSalesRepId(member.id));
    },
    [dispatch]
  );

  useEffect(() => {
    onSelectMember(currentMember);
  }, [currentMember, onSelectMember]);

  return (
    <CampaignCreateDetailsUI
      members={members}
      onSelectMember={onSelectMember}
      isLoading={isLoading}
      selectedMember={currentMember}
      defaultTitle={defaultTitle}
      title={campaignTitle}
      onTitleChange={onTitleChange}
    />
  );
};

export default SalesRep;
