import { combineReducers } from 'redux';
import { addressLookupReducer } from './address-lookup-reducer';
import { appReducer } from './app-reducer';
import { campaignCreatorReducer } from './campaign-creator-reducer';
import { prospectsReducer } from './prospects-reducer';
import { campaignsMapReducer } from './campaigns-map-reducer';
import { campaignsReducer } from './campaigns-reducer';
import { companyReducer } from './company-reducer';
import { dashboardReducer } from './dashboard-reducer';
import { leadStatusReducer } from './lead-status-reducer';
import { programsReducer } from './programs-reducer';
import { teamReducer } from './team-reducer';
import { userReducer } from './user-reducer';
import { tagsReducer } from './tags-reducer';

export const Reducer = combineReducers({
  AddressLookups: addressLookupReducer,
  App: appReducer,
  CampaignCreator: campaignCreatorReducer,
  Prospects: prospectsReducer,
  Campaigns: campaignsReducer,
  CampaignsMap: campaignsMapReducer,
  Company: companyReducer,
  Dashboard: dashboardReducer,
  LeadStatus: leadStatusReducer,
  Programs: programsReducer,
  Team: teamReducer,
  User: userReducer,
  Tags: tagsReducer,
});
