export enum NotificationType {
  error = 'ERROR',
  warning = 'WARNING',
  success = 'SUCCESS',
  info = 'INFO',
}

export interface Notification {
  type: NotificationType;
  title: string;
  description?: string;
  placement: 'upper-right' | 'lower-right';
}

export interface NotificationAction extends Notification {
  id: string;
}
