import { PortalAPI } from '@platform/api';
import { DialogModal } from '@platform/ui';
import { Portal } from '@platform/ui-helpers';
import { useCallback, useState } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CompanyTabId } from '../../components/Company/types';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { createCompanyInvite } from '../../redux/thunks';
import { GetAccessTokenFunction } from '../../types/auth0';
import { CompanyInviteUI } from './CompanyInvite.ui';

function addServerErrors<T>(
  errors: { [P in FieldPath<PortalAPI.CompanyAPI.CreateCompanyRequest>]?: string[] },
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: 'server',
      message: errors[key as keyof typeof errors]!.join('. '),
    });
  });
}

export const CompanyInviteModal = ({
  getAccessTokenSilently,
}: {
  isLoading: boolean;
  isAuthenticated: boolean;
  getAccessTokenSilently: GetAccessTokenFunction;
}) => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const modalIsOpen = useSelector(Selectors.getCompanyInviteModalIsOpen);
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
  } = useForm<PortalAPI.CompanyAPI.CreateCompanyRequest>({});

  const onClose = useCallback(() => {
    reset({ companyName: '', userEmail: '', userPhoneNumber: '', userFirstName: '', userLastName: '' });
    dispatch(AppActions.setCompanyInviteModal({ isOpen: false }));
  }, [dispatch, reset]);

  const onSubmit = useCallback(
    async (values: PortalAPI.CompanyAPI.CreateCompanyRequest) => {
      setSubmitting(true);
      dispatch(createCompanyInvite(getAccessTokenSilently, values))
        .then((e) => {
          if ('errors' in e && typeof e.errors !== 'string') {
            addServerErrors<PortalAPI.CompanyAPI.CreateCompanyRequest>(e.errors, setError);
          } else if (!('errors' in e)) {
            onClose();
            const hash: CompanyTabId = 'all-companies';
            navigate({ pathname: Portal.getRouteName('/company'), hash });
          }
        })
        .finally(() => setSubmitting(false));
    },
    [dispatch, getAccessTokenSilently, navigate, onClose, setError]
  );

  return (
    <DialogModal onClose={onClose} isOpen={modalIsOpen}>
      <div className="p-5 border-b border-solid border-slate-200 rounded-t">
        <h3 className="text-2xl font-semibold">Create a New Company</h3>
      </div>
      <CompanyInviteUI
        submitting={submitting}
        errors={errors}
        handleSubmit={handleSubmit(onSubmit)}
        register={register}
        control={control}
        onCancel={onClose}
      />
    </DialogModal>
  );
};
