import React, { useEffect } from 'react';
import { getRoutes } from '../../routes';
import { Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';
import { useTypedDispatch } from '../../redux/state';
import { AppActions } from '../../redux/actions/app-actions';
import { Portal } from '@platform/ui-helpers';
import { Constants } from '@platform/app-config';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Selectors } from '../../redux/selectors';
import { loadLeadStatuses, loadUserProfile, loginCheck } from '../../redux/thunks';

interface AppProps {}

const subscriptionCheckWhitelist: Portal.UIRoutes[] = ['/billing', '/error', '/survey/company-onboarding', '/plan'];
const shouldCheckSubscriptionForRoute = (path: string) => subscriptionCheckWhitelist.every((r) => !path.startsWith(r));

export const App: React.FC<AppProps> = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const loadedUser = useSelector(Selectors.user);
  const leadStatuses = useSelector(Selectors.leadStatuses);
  const auth0 = useAuth0();

  useEffect(() => {
    const impersonationCompany = params.get(Constants.IMPERSONATE_COMPANY_ID_HEADER);
    if (impersonationCompany) {
      dispatch(AppActions.setCompanyImpersonation({ companyId: impersonationCompany }));
      params.delete(Constants.IMPERSONATE_COMPANY_ID_HEADER);
      setParams(params);
    }
  }, [dispatch, params, setParams]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loginCheck(getAccessTokenSilently, navigate, shouldCheckSubscriptionForRoute(location.pathname)));
    }
  }, [getAccessTokenSilently, dispatch, location, isAuthenticated, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!loadedUser.isLoaded && !loadedUser.isLoading) {
        dispatch(loadUserProfile(auth0.getAccessTokenSilently));
      }

      if (!leadStatuses.isLoaded && !leadStatuses.isLoading) {
        dispatch(loadLeadStatuses(auth0.getAccessTokenSilently));
      }
    }
  }, [
    dispatch,
    auth0.getAccessTokenSilently,
    loadedUser.isLoaded,
    loadedUser.isLoading,
    leadStatuses.isLoaded,
    leadStatuses.isLoading,
    isAuthenticated,
  ]);

  return (
    <Routes>
      {getRoutes().map(({ route, component: Component }, i) => {
        return <Route key={`route-${i}`} path={route} element={<Component />} />;
      })}
    </Routes>
  );
};
