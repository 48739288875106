"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.GetUserResponseVersion = exports.API = void 0;
var helpers_1 = require("@platform/helpers");
var API;
(function (API) {
    API["GET /user"] = "GET /user";
    API["PATCH /user"] = "PATCH /user";
    API["POST /user/password-reset"] = "POST /user/password-reset";
    API["GET /team"] = "GET /team";
    API["GET /invite"] = "GET /invite";
    API["POST /invite/accept"] = "POST /invite/accept";
    API["POST /invite"] = "POST /invite";
    API["DELETE /invite/:id"] = "DELETE /invite/:id";
    API["POST /invite/:id/resend"] = "POST /invite/:id/resend";
    API["DELETE /user/:id"] = "DELETE /user/:id";
    API["POST /user/:id/ownership"] = "POST /user/:id/ownership";
})(API = exports.API || (exports.API = {}));
exports.GetUserResponseVersion = 'v1';
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.authenticatedRoute)(API['GET /user'])), (0, helpers_1.authenticatedRoute)(API['PATCH /user'])), (0, helpers_1.authenticatedRoute)(API['POST /user/password-reset'])), (0, helpers_1.authenticatedRoute)(API['POST /user/:id/ownership'])), (0, helpers_1.authenticatedRoute)(API['GET /team'])), (0, helpers_1.authenticatedRoute)(API['POST /invite'])), (0, helpers_1.authenticatedRoute)(API['DELETE /invite/:id'])), (0, helpers_1.authenticatedRoute)(API['POST /invite/:id/resend'])), (0, helpers_1.authenticatedRoute)(API['DELETE /user/:id'])), (0, helpers_1.unauthenticatedRoute)(API['POST /invite/accept'])), (0, helpers_1.unauthenticatedRoute)(API['GET /invite']));
