import { AppReducer } from '../types/reducer-types';
import { omit } from 'lodash';
import { initialState } from '../state';

export const dashboardReducer: AppReducer<'Dashboard'> = (state = initialState.Dashboard, action): typeof state => {
  switch (action.type) {
    case 'Dashboard/set-date-filter': {
      return {
        ...state,
        selectedFilter: action.payload,
      };
    }
    case 'Dashboard/prospect-activity': {
      const {
        filter,
        data: { leadStatuses, ...activity },
      } = action.payload;
      return {
        ...state,
        leadStatusActivity: {
          isLoading: false,
          isLoaded: true,
          filter: filter.id,
          leadStatuses,
          data: activity,
        },
      };
    }
    case 'Dashboard/prospect-activity-loading': {
      // Clear out the data if the filter has changed
      const data =
        state.leadStatusActivity.filter === action.payload.filter
          ? omit(state.leadStatusActivity.data, 'leadStatuses')
          : null;

      return {
        ...state,
        leadStatusActivity: {
          ...state.leadStatusActivity,
          ...action.payload,
          isLoading: true,
          isLoaded: false,
          data,
        },
      };
    }
    case 'Dashboard/impressions': {
      return {
        ...state,
        impressions: {
          isLoading: false,
          isLoaded: true,
          data: action.payload.data,
          filter: action.payload.filter.id,
        },
      };
    }
    case 'Dashboard/impressions-loading': {
      // Clear out the data if the filter has changed
      const data = state.impressions.filter === action.payload.filter ? state.impressions.data : null;
      return {
        ...state,
        impressions: {
          ...state.impressions,
          ...action.payload,
          isLoading: true,
          isLoaded: false,
          data,
        },
      };
    }
    case 'Dashboard/tag-activity': {
      return {
        ...state,
        tagActivity: {
          isLoading: false,
          isLoaded: true,
          data: action.payload.data,
          filter: action.payload.filter.id,
        },
      };
    }
    case 'Dashboard/tags-loading': {
      // Clear out the data if the filter has changed
      const data = state.tagActivity.filter === action.payload.filter ? state.tagActivity.data : null;
      return {
        ...state,
        tagActivity: {
          ...state.tagActivity,
          ...action.payload,
          isLoading: true,
          isLoaded: false,
          data,
        },
      };
    }
    default:
      return state;
  }
};
