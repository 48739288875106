import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { AppActions } from '../../redux/actions/app-actions';
import { BoostingPopupContent, Drawer, Iterator, ProspectDetailProps } from '@platform/ui';
import Content from './Content';
import { deleteProspect, updateMailStatus } from '../../redux/thunks';
import { GetAccessTokenFunction } from '../../types/auth0';
import { ProspectInfoModal } from './ProspectInfo';
import { ProspectContactModal } from './ContactForm';
import { AddressFormModal } from './AddressForm';

const ProspectsDetailsModal = ({ getAccessTokenSilently }: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const dispatch = useTypedDispatch();
  const modalProps = useSelector(Selectors.getProspectsDetailsModal);
  const { prospects, totalRecords } = useSelector(Selectors.prospectsByCampaignId(modalProps?.campaignId ?? null));
  const { user } = useSelector(Selectors.user);
  const { prospectDataSubscription } = useSelector(Selectors.company);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const onClose = useCallback(() => {
    dispatch(AppActions.setProspectsDetailsModal({ isOpen: false, campaignId: null, prospectId: null }));
  }, [dispatch]);

  const dismissMailModal = useCallback(() => {
    dispatch(AppActions.setBoostIndicatorModal({ isOpen: false }));
  }, [dispatch]);

  const selectedProspect = useMemo(
    () => (modalProps?.prospectId ? prospects[modalProps.prospectId] : null),
    [modalProps?.prospectId, prospects]
  );

  const onNext = useCallback(() => {
    if (selectedProspect && modalProps?.campaignId) {
      dispatch(
        AppActions.setProspectsDetailsModal({
          isOpen: true,
          campaignId: modalProps.campaignId,
          prospectId: selectedProspect.nextId,
        })
      );
    }
  }, [dispatch, modalProps?.campaignId, selectedProspect]);

  const onPrevious = useCallback(() => {
    if (selectedProspect && modalProps?.campaignId) {
      dispatch(
        AppActions.setProspectsDetailsModal({
          isOpen: true,
          campaignId: modalProps.campaignId,
          prospectId: selectedProspect.prevId,
        })
      );
    }
  }, [dispatch, modalProps?.campaignId, selectedProspect]);

  const formatDeliveryWindow = useMemo(() => {
    if (selectedProspect && selectedProspect.deliveryWindow) {
      // Create an interval for more than one drips
      if ((selectedProspect.deliveryDrips?.total ?? 0) > 1) {
        return `${selectedProspect.deliveryWindow.start} - ${selectedProspect.deliveryWindow.end}`;
      }

      return selectedProspect.deliveryWindow.start;
    }

    return '';
  }, [selectedProspect]);

  const handleStartMail = useCallback(() => {
    if (selectedProspect) {
      dispatch(updateMailStatus(getAccessTokenSilently, 'start', selectedProspect.externalTrackingId));
      dismissMailModal();
    }
  }, [dismissMailModal, dispatch, getAccessTokenSilently, selectedProspect]);

  const handleStopMail = useCallback(() => {
    if (selectedProspect) {
      dispatch(updateMailStatus(getAccessTokenSilently, 'stop', selectedProspect.externalTrackingId));
      dismissMailModal();
    }
  }, [dismissMailModal, dispatch, getAccessTokenSilently, selectedProspect]);

  const handleDelete: ProspectDetailProps['handleDelete'] = useCallback(
    async (id) => {
      if (modalProps?.campaignId) {
        setDeleteInProgress(true);
        await dispatch(deleteProspect(getAccessTokenSilently, id, modalProps.campaignId));
        setDeleteInProgress(false);
      }
    },
    [dispatch, getAccessTokenSilently, modalProps?.campaignId]
  );

  return (
    <Drawer isOpen={!!modalProps} onClose={onClose}>
      {modalProps?.boostIsOpen ? (
        <BoostingPopupContent
          currentDrip={selectedProspect?.deliveryDrips?.progress ?? 0}
          totalDrips={selectedProspect?.deliveryDrips?.total ?? 0}
          description={selectedProspect?.program?.name ?? ''}
          date={formatDeliveryWindow}
          onClickStop={handleStopMail}
          onClickStart={handleStartMail}
          onClickBack={dismissMailModal}
          mailStatus={selectedProspect?.mailStatus}
        />
      ) : modalProps?.contactIsOpen ? (
        <ProspectContactModal getAccessTokenSilently={getAccessTokenSilently} prospectId={modalProps.prospectId} />
      ) : modalProps?.addressIsOpen ? (
        <AddressFormModal getAccessTokenSilently={getAccessTokenSilently} prospectId={modalProps.prospectId} />
      ) : modalProps?.infoIsOpen ? (
        <ProspectInfoModal getAccessTokenSilently={getAccessTokenSilently} prospectId={modalProps.prospectId} />
      ) : (
        !!modalProps &&
        !!selectedProspect && (
          <>
            <Iterator
              onClickNext={onNext}
              onClickPrev={onPrevious}
              onClickExit={onClose}
              currentPage={selectedProspect?.idx ?? 0}
              totalPages={totalRecords ?? 0}
              showDownButton={false}
              isDisabled={!modalProps.campaignId || !prospects}
            />
            <Content
              prospect={selectedProspect}
              campaignId={modalProps.campaignId}
              prospectDataSubscription={prospectDataSubscription ?? 'none'}
              getAccessToken={getAccessTokenSilently}
              onDelete={handleDelete}
              onExit={onClose}
              deleteInProgress={deleteInProgress}
              userId={user?.id}
              email={user?.email}
            />
          </>
        )
      )}
    </Drawer>
  );
};

export default ProspectsDetailsModal;
