import { useCallback, useMemo } from 'react';
import { PortalAPI } from '@platform/api';
import Loading from './loading';
import ProspectsListRow from './prospects-list-row';

export interface Props {
  prospects: { prospects: PortalAPI.ProspectAPI.ProspectResponse_v3[]; isLoaded: boolean } | null;
  onClick: (id: string) => void;
  isDesktop: boolean;
}

const ProspectsList = ({ prospects, onClick, isDesktop }: Props) => {
  const onSelect = useCallback((id: string) => () => onClick(id), [onClick]);

  const rows = useMemo(
    () =>
      prospects?.prospects.map(
        ({
          externalTrackingId,
          deliveryDrips,
          name,
          occupancyData,
          address,
          leadStatus,
          mailStatus,
          notes,
          prospectTags,
        }) => {
          const { address1, city, zip, state } = address;
          return {
            id: externalTrackingId,
            name: name ?? (occupancyData ? `${occupancyData.firstName} ${occupancyData.lastName}` : null),
            address: address1,
            addressLine2: `${city}, ${state} ${zip}`,
            leadStatus,
            leadStatusUpdatedAt: leadStatus?.createdAt ?? null,
            mailStatus,
            currentDrip: deliveryDrips?.progress,
            note: notes ?? null,
            tags: prospectTags,
          };
        }
      ),
    [prospects]
  );

  return !prospects?.isLoaded ? (
    <Loading />
  ) : rows?.length === 0 ? (
    <div className="p-6 font-medium text-gray-900 text-lg">
      No prospects found for this campaign. Use the mobile app to add prospects.
    </div>
  ) : (
    <div className="flex flex-col max-w-6xl mx-auto px-4 lg:px-10 py-5 lg:py-8">
      <div className="grid grid-cols-1 gap-5">
        {rows?.map(({ id, ...row }) => {
          return <ProspectsListRow key={id} {...row} onClick={onSelect(id)} isDesktop={isDesktop} />;
        })}
      </div>
    </div>
  );
};

export default ProspectsList;
