"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.PcmWebhookAPI = void 0;
var helpers_1 = require("@platform/helpers");
var PcmWebhookAPI;
(function (PcmWebhookAPI) {
    PcmWebhookAPI["POST /pcm/webhook"] = "POST /pcm/webhook";
})(PcmWebhookAPI = exports.PcmWebhookAPI || (exports.PcmWebhookAPI = {}));
exports.RouteDefinitions = __assign({}, (0, helpers_1.unauthenticatedRoute)('POST /pcm/webhook'));
