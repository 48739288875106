import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { initialAddressLookupState } from '../state/address-lookup-state';

export const addressLookupReducer: AppReducer<'AddressLookups'> = (
  state = initialState.AddressLookups,
  action
): typeof state => {
  switch (action.type) {
    case 'AddressLookup/search-completed': {
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          isLoaded: true,
          suggestions: {
            ...state.search.suggestions,
            [state.search.currentSearch]: action.payload.suggestions,
          },
        },
      };
    }
    case 'AddressLookup/search-started':
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true,
          isLoaded: false,
          currentSearch: action.payload,
          suggestions: {
            ...state.search.suggestions,
            // [action.payload]: []
          },
        },
      };
    case 'AddressLookup/clear-search':
      return {
        ...state,
        search: {
          ...state.search,
          isLoaded: false,
          isLoading: false,
          currentSearch: initialState.AddressLookups.search.currentSearch,
        },
      };
    case 'AddressLookup/lookup-started':
      return {
        ...state,
        lookup: {
          ...state.lookup,
          isLoading: true,
          isLoaded: false,
        },
      };
    case 'AddressLookup/lookup-completed':
      return {
        ...state,
        lookup: {
          ...state.lookup,
          address: action.payload.address,
          isLoading: false,
          isLoaded: true,
        },
      };
    case 'AddressLookup/clear-lookup':
      return {
        ...state,
        lookup: {
          ...state.lookup,
          ...initialAddressLookupState.lookup,
        },
      };
    default:
      return state;
  }
};
