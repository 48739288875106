import { breakpoints, MapBox, MapBoxProps } from '@platform/ui';
import { MapUI } from '@platform/ui-helpers';
import type { Map as MapboxMapType } from 'mapbox-gl';
import React, { useCallback, useRef } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Actions } from '../../redux/actions';
import { useTypedDispatch } from '../../redux/state';
import useDraw from './hooks/useDraw';
import bbox from '@turf/bbox';

export interface Props
  extends Omit<MapBoxProps, 'locationPermission' | 'userCoordinates' | 'safeAreaBottom' | 'isTablet' | 'isPortrait'> {
  defaultBoxKm?: number;
  drawSpec?: {
    enabled: boolean;
    center?: [number, number];
  };
}

export const Map = ({ drawSpec, defaultBoxKm, children, ...props }: React.PropsWithChildren<Props>) => {
  const dispatch = useTypedDispatch();
  const isDesktop = useMediaQuery(breakpoints.md);
  const mapRef = useRef() as React.MutableRefObject<MapboxMapType>;

  const draw = useDraw({
    map: mapRef,
    initialPoint: drawSpec?.center ?? [],
  });

  const onLoad = useCallback(
    (map: MapboxMapType) => {
      mapRef.current = map;

      if (draw && drawSpec?.enabled) {
        draw.onInit();
        dispatch(Actions.CampaignCreator.setOnResetShape(() => draw.onCreatePolygon(defaultBoxKm ?? 0.5)));
        const polygon = draw.onCreatePolygon(defaultBoxKm ?? 0.5);

        if (polygon) {
          const bounds = bbox(polygon);
          map.fitBounds([bounds[0], bounds[1], bounds[2], bounds[3]], {
            padding: { top: 85, bottom: 85, left: 40, right: 40 },
          });
        }
      }

      map.on('mouseenter', MapUI.Layers.PROSPECTS, () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', MapUI.Layers.PROSPECTS, () => {
        map.getCanvas().style.cursor = '';
      });

      if (props.onMapLoaded) {
        props.onMapLoaded(map);
      }
    },
    [draw, drawSpec?.enabled, props, dispatch, defaultBoxKm]
  );

  return (
    <MapBox
      {...props}
      onMapLoaded={onLoad}
      locationPermission={'denied'}
      userCoordinates={null}
      safeAreaBottom={0}
      tabBarHeight={0}
      isPortrait={false}
      isTablet={isDesktop}
    >
      {children}
    </MapBox>
  );
};
