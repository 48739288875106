import { useAuth0 } from '@auth0/auth0-react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { PortalAPI } from '@platform/api';
import { Constants, PlanName } from '@platform/app-config';
import { Avatar } from '@platform/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Selectors } from '../../redux/selectors';
import Content from './Main/Content';
import { stripeCheckout } from '../../redux/thunks';
import { useTypedDispatch } from '../../redux/state';

type SubscriptionPlans = Extract<PlanName, 'STARTER_SCOUT' | 'PRO_SCOUT' | 'ENTERPRISE'>;

const openDemoUrl = () => {
  window.open(Constants.DEMO_URL, '_blank');
};

const Plan = () => {
  const { boot } = useIntercom();
  const { getAccessTokenSilently, logout } = useAuth0();
  const { user } = useSelector(Selectors.user);
  const company = useSelector(Selectors.company);
  const [loading, setLoading] = useState<SubscriptionPlans | null>(null);
  const location = useLocation();
  const dispatch = useTypedDispatch();

  const state = useMemo(() => location.state as PortalAPI.AuthAPI.LocalRedirect<'/plan'>['state'], [location.state]);

  useEffect(() => {
    if (user && user.intercomUserHash) {
      boot({
        userId: user.externalId,
        email: user.email,
        userHash: user.intercomUserHash,
      });
    }
  }, [boot, user]);

  const onSelectSubscribe = useCallback(
    async (product: SubscriptionPlans) => {
      if (!user || !company) {
        window.alert(`User not signed in. Please try again.`);
      } else {
        setLoading(product);
        await dispatch(
          stripeCheckout(getAccessTokenSilently, {
            product,
            associationId: state?.associationId,
            freeTrial: state?.freeTrial,
          })
        );
        setLoading(null);
      }
    },
    [company, dispatch, getAccessTokenSilently, state?.associationId, state?.freeTrial, user]
  );

  const handleBack = useCallback(() => logout(), [logout]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 py-6 px-5">
      <div className="max-w-6xl mx-auto">
        {!!user && (
          <div className="sm:flex justify-end hidden">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-1">
                <Avatar size="xs" avatar={{ name: `${user.firstName} ${user.lastName}` }} />
                <div className="text-xs">
                  Signed in as&nbsp;
                  <span className="font-semibold">
                    {user.firstName} {user.lastName}
                  </span>
                </div>
              </div>
              <div onClick={handleBack} className="text-xs font-semibold flex items-center cursor-pointer">
                <ChevronRightIcon className="h-3 w-3 mr-1" />
                SIGN OUT
              </div>
            </div>
          </div>
        )}
        <Content
          onSelect={onSelectSubscribe}
          onClickContact={openDemoUrl}
          loading={loading}
          freeTrial={state?.freeTrial ?? true}
        />
      </div>
    </div>
  );
};

export default Plan;
