/* eslint-disable @typescript-eslint/no-redeclare */
import type { Smarty } from '@platform/integrations';
import type { PortalAPI } from '@platform/api';
import { ActionType } from '@platform/helpers';

export type AddressSuggestion = Smarty.SmartyAutocompleteSuggestion;
export type AddressResponse = PortalAPI.AddressAPI.AddressLookupResponse;

export const AddressLookupActions = {
  searchCompleted: (suggestions: AddressSuggestion[]) =>
    ({
      type: 'AddressLookup/search-completed',
      payload: {
        suggestions,
      },
    } as const),
  clearSearchSuggestions: () =>
    ({
      type: 'AddressLookup/clear-search',
    } as const),
  searchStarted: (text: string) =>
    ({
      type: 'AddressLookup/search-started',
      payload: text,
    } as const),
  lookupCompleted: (address: AddressResponse | null) =>
    ({
      type: 'AddressLookup/lookup-completed',
      payload: {
        address,
      },
    } as const),
  lookupStarted: () =>
    ({
      type: 'AddressLookup/lookup-started',
    } as const),
  clearLookup: () =>
    ({
      type: 'AddressLookup/clear-lookup',
    } as const),
};

export type AddressLookupActions = ActionType<typeof AddressLookupActions>;
