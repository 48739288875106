import { displayForStrategy, TriggerTypes } from '@platform/helpers';
import { sum } from 'lodash';
import { CampaignCard, CampaignCardLoading, iconForStrategy, Spinner } from '@platform/ui';
import { Portal, Utils } from '@platform/ui-helpers';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadedCampaign } from '../../../redux/actions/campaign-actions';
import { getCampaignNameSubtext } from '../../../utils/campaign';

export type PaginatedUIProps = {
  loading: boolean;
  loaded: boolean;
  loadMore: () => void;
  isSuperAdmin: boolean;
  showLoadMore: boolean;
  isDesktop: boolean;
};

export type CampaignUIProps = PaginatedUIProps & {
  campaigns: LoadedCampaign[];
};

export const CampaignsListUI = ({ campaigns, loading, loadMore, showLoadMore, isDesktop }: CampaignUIProps) => {
  const navigate = useNavigate();

  const onClickCampaign = useCallback(
    (campaign: LoadedCampaign) => {
      navigate(
        Utils.buildUIUrl(null, {
          route: Portal.getRouteName('/campaigns/:id'),
          params: { id: campaign.externalTrackingId },
        })
      );
    },
    [navigate]
  );

  return (
    <>
      {loading && !campaigns.length ? (
        Array.from(Array(3).keys()).map((i) => <CampaignCardLoading key={i} isDesktop={isDesktop} />)
      ) : (
        <ul className={classNames({ 'space-y-3': !isDesktop, 'space-y-5': isDesktop }, 'flex flex-col')}>
          {campaigns.map((campaign, idx) => {
            const {
              triggerDetails,
              deliveryWindow,
              program,
              impressionCount,
              leadStatusSummary,
              mailStatus,
              triggerType,
              title: campaignTitle,
              isBuilt,
            } = campaign;
            const Icon = iconForStrategy(campaign.strategy);
            let title,
              address,
              addressLine2: string = '';
            if (
              (triggerType === TriggerTypes.jobSold || triggerType === TriggerTypes.lead) &&
              (triggerDetails || campaignTitle)
            ) {
              const { homeownerName, address1, city, state, zip } = triggerDetails;
              const addressP2 = `${city}, ${state} ${zip}`;
              title = campaignTitle ?? homeownerName ?? address1;
              address = campaignTitle ?? homeownerName ? address1 : addressP2;
              addressLine2 = campaignTitle ?? homeownerName ? addressP2 : '';
            } else if (triggerDetails || campaignTitle) {
              title = campaignTitle ?? Utils.getCampaignName(campaign);
              address = getCampaignNameSubtext(campaign);
            }

            return (
              <li key={`campaign-list-${idx}`}>
                <CampaignCard
                  isDisabled={!isBuilt}
                  isDesktop={isDesktop}
                  onClickCampaign={() => onClickCampaign(campaign)}
                  title={title || ''}
                  address={address}
                  addressLine2={addressLine2}
                  createdAt={campaign.createdAt}
                  tag={{
                    text: displayForStrategy[campaign.strategy],
                    icon: (
                      <span className={classNames({ 'mr-2': !isDesktop }, 'inline-flex')}>
                        <Icon />
                      </span>
                    ),
                  }}
                  mailCard={{
                    status: mailStatus,
                    description: program?.name,
                    date:
                      deliveryWindow?.start && deliveryWindow.start === deliveryWindow?.end
                        ? `${deliveryWindow?.start} (Estimated)`
                        : `${deliveryWindow?.start} - ${deliveryWindow?.end}`,
                    impressions: impressionCount,
                  }}
                  representative={{
                    name: campaign.assignedToUserName,
                  }}
                  progress={{
                    total: campaign.targets ?? 0,
                    current: leadStatusSummary ? sum(Object.values(leadStatusSummary).map((s) => s.count)) : 0,
                  }}
                />
              </li>
            );
          })}
        </ul>
      )}
      {showLoadMore && (
        <div className="flex flex-col items-center mt-4 lg:mt-5">
          <button
            onClick={loadMore}
            disabled={loading}
            type="button"
            className="
            inline-flex items-center px-4 h-10 border border-gray-300 text-sm font-medium rounded-full text-gray-700 hover:bg-gray-200 hover:border-gray-300 transition-all hover:shadow-sm active:shadow-none active:bg-gray-300"
          >
            Load more
            {loading && (
              <span className="ml-3">
                <Spinner variant="primary" animate />
              </span>
            )}
          </button>
        </div>
      )}
    </>
  );
};
