import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { Portal } from '@platform/ui-helpers';
import { startCase, toLower, upperFirst } from 'lodash';
import React, { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import lsi_logo from '../../assets/LSLogo_red_500w.png';
import landing_bg from '../../assets/landing_bg.jpg';
import how_to from '../../assets/how_it_works.png';
import { useQuery } from '../../hooks/use-query';
import { withUnauthenticatedPageLayout, WithUnauthenticatedPageLayoutProps } from '../Layout/unauthenticated-page';

export type LandingProps = {};

export function withUnauthenticationRequired<T extends WithUnauthenticatedPageLayoutProps>(
  Component: React.ComponentType<T>,
  redirectOnAuthenticated: Portal.UIRoutes
) {
  const Landing: React.FunctionComponent<T & LandingProps> = (props) => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (!isAuthenticated && !isLoading) {
      return <Component {...props} />;
    } else if (isAuthenticated) {
      return <Navigate to={redirectOnAuthenticated} />;
    } else {
      return <div />;
    }
  };
  return Landing;
}

export const LandingUI = withUnauthenticatedPageLayout(() => {
  const params = useQuery();
  const { loginWithRedirect: login } = useAuth0();
  const loginError = startCase(toLower(params.get('error') ?? ''));
  const errorDescription = upperFirst(toLower(params.get('error_description') ?? ''));

  const onLogin = useCallback(async () => {
    login({ prompt: 'login' });
  }, [login]);

  const onSignup = useCallback(async () => {
    login({ screen_hint: 'signup', prompt: 'login' });
  }, [login]);

  return (
    <div className="min-h-full flex overflow-hidden">
      <div className="z-30 flex-1 flex flex-col mt-8 py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-gray-100 relative">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="mb-4">
            <img className=" h-10 w-auto" src={lsi_logo} alt="Lead Scout Logo" />
            <h2 className="mt-6 text-4xl font-extrabold text-gray-900">
              Get Started with
              <br />
              Lead Scout
            </h2>
            <p className="mt-2 text-lg text-gray-600">Create a new account or log in below.</p>
          </div>
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 mb-4 mt-8 w-full rounded-md border border-transparent shadow-md text-lg font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onSignup}
          >
            Continue to Sign Up
            <ArrowRightIcon className="ml-2 mt-1.5 h-4 w-4" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="flex flex-row mt-6 mb-4 border rounded-lg p-4 w-full hover:border-gray-300 active:border-blue-300 active:bg-gray-100"
            onClick={onLogin}
          >
            <p className="w-4/5 text-gray-600 text-left">Already have an account?</p>
            <p className="w-1/5 text-right font-medium text-blue-600 hover:text-blue-500">Sign in</p>
          </button>
          <div className="flex flex-col mt-6 mb-4 border rounded-lg p-4 hover:border-gray-300 active:border-gray-200 active:bg-gray-300">
            <a
              href="https://youtu.be/AROnbzYKrKc&utm_source=leadscout&utm_medium=signup_page&utm_id=1"
              className="inline-flex space-x-4"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-full rounded-md hover:shadow-lg" src={how_to} alt="Lead Scout How To Video" />
            </a>
            <a className="flex mt-4 py-2" href="https://www.leadscoutapp.com/demo" target="_blank" rel="noreferrer">
              <p className="w-full text-center font-medium text-blue-600 hover:text-blue-500">
                Watch "Getting Started" series
              </p>
            </a>
          </div>
          {loginError && (
            <div className="pt-8 text-med text-red-500 text-center">
              <div>
                {loginError}: {errorDescription}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="min-h-screen hidden lg:block relative w-0 flex-1">
        <img
          className="absolute bottom-0 min-h-screen w-full object-cover"
          src={landing_bg}
          alt="Lead Scout prospects in a neighborhood aerial view"
        />
      </div>
    </div>
  );
});
