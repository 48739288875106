"use strict";
exports.__esModule = true;
exports.toTurfUnits = void 0;
var assert_unreachable_1 = require("./assert-unreachable");
var toTurfUnits = function (unit) {
    switch (unit) {
        case 'ft':
            return 'feet';
        case 'm':
            return 'meters';
        case 'mi':
            return 'miles';
        case 'km':
            return 'kilometers';
        default:
            (0, assert_unreachable_1.assertUnreachableSafe)(unit);
    }
};
exports.toTurfUnits = toTurfUnits;
