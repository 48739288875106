"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.IngestAPI = void 0;
var helpers_1 = require("@platform/helpers");
var IngestAPI;
(function (IngestAPI) {
    IngestAPI["POST /zapier/v1/campaign"] = "POST /zapier/v1/campaign";
    IngestAPI["POST /zapier/v1/company"] = "POST /zapier/v1/company";
    IngestAPI["POST /zapier/v1/prospect"] = "POST /zapier/v1/prospect";
    IngestAPI["GET /zapier/v1/custom_field_meta_data/program"] = "GET /zapier/v1/custom_field_meta_data/program";
    IngestAPI["POST /zapier/v1/subscription/prospect_qualified"] = "POST /zapier/v1/subscription/prospect_qualified";
    IngestAPI["POST /zapier/v1/subscription/prospect_created"] = "POST /zapier/v1/subscription/prospect_created";
    IngestAPI["DELETE /zapier/v1/subscription/:id"] = "DELETE /zapier/v1/subscription/:id";
    IngestAPI["GET /zapier/v1/list/prospect_qualified"] = "GET /zapier/v1/list/prospect_qualified";
    IngestAPI["POST /zapier/v1/list/prospect_created"] = "POST /zapier/v1/list/prospect_created";
    IngestAPI["GET /zapier/v1/tags"] = "GET /zapier/v1/tags";
    IngestAPI["GET /zapier/v1/lead_statuses"] = "GET /zapier/v1/lead_statuses";
    IngestAPI["GET /zapier/v1/users"] = "GET /zapier/v1/users";
    IngestAPI["GET /zapier/v1/prospect_programs"] = "GET /zapier/v1/prospect_programs";
    // Deprecated 4/10/24
    IngestAPI["DELETE /zapier/v1/subscription/:id/prospect_qualified"] = "DELETE /zapier/v1/subscription/:id/prospect_qualified";
})(IngestAPI = exports.IngestAPI || (exports.IngestAPI = {}));
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.unauthenticatedRoute)('POST /zapier/v1/campaign')), (0, helpers_1.unauthenticatedRoute)('POST /zapier/v1/prospect')), (0, helpers_1.unauthenticatedRoute)('POST /zapier/v1/company')), (0, helpers_1.unauthenticatedRoute)('GET /zapier/v1/custom_field_meta_data/program')), (0, helpers_1.unauthenticatedRoute)('POST /zapier/v1/subscription/prospect_qualified')), (0, helpers_1.unauthenticatedRoute)('POST /zapier/v1/subscription/prospect_created')), (0, helpers_1.unauthenticatedRoute)('DELETE /zapier/v1/subscription/:id')), (0, helpers_1.unauthenticatedRoute)('GET /zapier/v1/list/prospect_qualified')), (0, helpers_1.unauthenticatedRoute)('POST /zapier/v1/list/prospect_created')), (0, helpers_1.unauthenticatedRoute)('GET /zapier/v1/tags')), (0, helpers_1.unauthenticatedRoute)('GET /zapier/v1/lead_statuses')), (0, helpers_1.unauthenticatedRoute)('GET /zapier/v1/users')), (0, helpers_1.unauthenticatedRoute)('GET /zapier/v1/prospect_programs')), (0, helpers_1.unauthenticatedRoute)('DELETE /zapier/v1/subscription/:id/prospect_qualified'));
