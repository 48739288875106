import { useCallback, useState } from 'react';
import { PortalAPI } from '@platform/api';
import { AppActions } from '../redux/actions/app-actions';
import { NotificationType } from '../types/notification';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

// A different alternative to window.open to work on iOS Safari browser
const createAnchorElement = (url: string) =>
  new Promise((resolve, reject) => {
    if (url) {
      const element = document.createElement('a');
      document.body.appendChild(element);
      element.setAttribute('style', 'display: none');
      element.setAttribute('href', url);
      element.click();
      document.body.removeChild(element);
      resolve('Report exported successfully');
    } else {
      reject('The Url is required to be able to download the report');
    }
  });

const useExportCampaign = () => {
  const dispatch = useDispatch();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const onDownloadReport = useCallback(
    async (externalId: string) => {
      try {
        setIsLoadingReport(true);

        const { status, data } = await PortalAPI.Client.getApiClient(getAccessTokenSilently).request<
          typeof PortalAPI.CampaignAPI.API['GET /campaigns/:id/report'],
          PortalAPI.CampaignAPI.QueryParams['GET /campaigns/:id/report']
        >({
          route: PortalAPI.CampaignAPI.API['GET /campaigns/:id/report'],
          params: { id: externalId },
          queryParams: { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
        });

        if (status === 200 && data) {
          await createAnchorElement(data.url);
        }
      } catch {
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Something went wrong!',
            description: 'Unable to get report',
          })
        );
      } finally {
        setIsLoadingReport(false);
      }
    },
    [getAccessTokenSilently, dispatch]
  );

  return { onDownloadReport, isLoadingReport } as const;
};

export default useExportCampaign;
