import { Constants } from '@platform/app-config';
import { LoadedMapCampaign } from '../actions/campaign-map-actions';

export type CampaignsMapState = {
  boundingBox?: GeoJSON.Polygon;
  campaigns: { [id: string]: LoadedMapCampaign };
  isLoaded: boolean;
  isLoading: boolean;
};

export const initialCampaignMapState: CampaignsMapState = {
  boundingBox: JSON.parse(localStorage.getItem(Constants.LAST_BOUNDING_BOX_STORAGE_KEY) || 'false') || undefined,
  isLoaded: false,
  isLoading: false,
  campaigns: {},
};
