"use strict";
var _a, _b, _c;
exports.__esModule = true;
exports.displayForTrigger = exports.displayForMedium = exports.displayForStrategy = exports.CampaignMedium = exports.CampaignStrategy = exports.TriggerTypes = void 0;
var TriggerTypes;
(function (TriggerTypes) {
    TriggerTypes["jobSold"] = "jobSold";
    TriggerTypes["lead"] = "lead";
    TriggerTypes["targetsList"] = "targetsList";
})(TriggerTypes = exports.TriggerTypes || (exports.TriggerTypes = {}));
var CampaignStrategy;
(function (CampaignStrategy) {
    CampaignStrategy["JOBSITE"] = "n2n";
    CampaignStrategy["LEAD_MULTIPLIER"] = "lead_multiplier";
    CampaignStrategy["STORM_SWATH"] = "storm_swath";
    CampaignStrategy["REHASH"] = "rehash";
})(CampaignStrategy = exports.CampaignStrategy || (exports.CampaignStrategy = {}));
var CampaignMedium;
(function (CampaignMedium) {
    CampaignMedium["NOTE"] = "note";
    CampaignMedium["POSTCARD"] = "postcard";
    CampaignMedium["SURVEY"] = "survey";
})(CampaignMedium = exports.CampaignMedium || (exports.CampaignMedium = {}));
exports.displayForStrategy = (_a = {},
    _a[CampaignStrategy.JOBSITE] = 'Jobsite',
    _a[CampaignStrategy.LEAD_MULTIPLIER] = 'Lead Multiplier',
    _a[CampaignStrategy.STORM_SWATH] = 'Storm Swath',
    _a[CampaignStrategy.REHASH] = 'Lead Rehash',
    _a);
exports.displayForMedium = (_b = {},
    _b[CampaignMedium.NOTE] = 'Note',
    _b[CampaignMedium.POSTCARD] = 'Postcard',
    _b[CampaignMedium.SURVEY] = 'Survey',
    _b);
exports.displayForTrigger = (_c = {},
    _c[TriggerTypes.jobSold] = 'Job Sold',
    _c[TriggerTypes.lead] = 'Lead',
    _c[TriggerTypes.targetsList] = 'Target List',
    _c);
