import { useCallback, useState } from 'react';
import { useTypedDispatch } from '../../redux/state';
import { AppActions } from '../../redux/actions/app-actions';
import { useSelector } from 'react-redux';
import { Selectors } from '../../redux/selectors';
import { TeamInviteUI } from './TeamInvite.ui';
import { PortalAPI } from '@platform/api';
import { useForm } from 'react-hook-form';
import { inviteUser } from '../../redux/thunks';
import { DialogModal } from '@platform/ui';
import { GetAccessTokenFunction } from '../../types/auth0';

export const TeamInviteModal = ({
  getAccessTokenSilently,
}: {
  isLoading: boolean;
  isAuthenticated: boolean;
  getAccessTokenSilently: GetAccessTokenFunction;
}) => {
  const dispatch = useTypedDispatch();
  const modalIsOpen = useSelector(Selectors.getTeamInviteModalIsOpen);
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<PortalAPI.UserAPI.InviteUserRequest>({});

  const onClose = useCallback(() => {
    reset();
    dispatch(AppActions.setTeamInviteModal(false));
  }, [dispatch, reset]);

  const onSubmit = useCallback(
    async (values: Omit<PortalAPI.UserAPI.InviteUserRequest, 'role'>) => {
      setSubmitting(true);
      dispatch(inviteUser(getAccessTokenSilently, { role: 'admin', ...values }))
        .then(() => onClose())
        .finally(() => setSubmitting(false));
    },
    [dispatch, getAccessTokenSilently, onClose]
  );

  return (
    <DialogModal onClose={onClose} isOpen={modalIsOpen}>
      <div className="p-5 border-b border-solid border-slate-200 rounded-t">
        <h3 className="text-2xl font-semibold">Invite a Team Member</h3>
      </div>
      <TeamInviteUI
        submitting={submitting}
        errors={errors}
        handleSubmit={handleSubmit(onSubmit)}
        register={register}
        control={control}
        onCancel={onClose}
      />
    </DialogModal>
  );
};
