import { PortalAPI } from '@platform/api';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { match } from 'ts-pattern';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppointmentActivityDetailsModal } from './appointment-activity-modal';
import { LeadStatusActivityDetailsModal } from './lead-status-activity-modal';
import { MailActivityDetailsModal } from './mail-activity-modal';
import { NotesActivityDetailsModal } from './notes-activity-modal';
import { ProspectCreatedActivityDetailsModal } from './prospect-created-activity-modal';
import { DetailModalProps } from './types';

const isValidActivity = <T extends PortalAPI.ProspectAPI.ActivityDataType>(
  modalProps: any,
  type: T
): modalProps is DetailModalProps<T>['activity'] => {
  return modalProps.type === type; // Check if the type matches
};

export const ActivityDetailsModal = (props: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const dispatch = useTypedDispatch();
  const modalProps = useSelector(Selectors.getActivityDetailsModal);
  const prospect = useSelector(Selectors.prospectById(modalProps?.prospectId));
  const user = useSelector(Selectors.user);

  const onClose = useCallback(() => {
    dispatch(AppActions.setActivityDetailsModal(null));
  }, [dispatch]);

  const getProps: <T extends PortalAPI.ProspectAPI.ActivityDataType>(type: T) => DetailModalProps<T> = useCallback(
    (type) => {
      if (!isValidActivity(modalProps, type)) {
        throw new Error();
      }
      return {
        type,
        activity: modalProps,
        onClose,
        prospect,
        user: user?.user,
        getAccessTokenSilently: props.getAccessTokenSilently,
      };
    },
    [modalProps, onClose, props.getAccessTokenSilently, prospect, user?.user]
  );

  return !modalProps ? (
    <></>
  ) : (
    match(modalProps.type)
      .with(PortalAPI.ProspectAPI.ActivityDataType.APPOINTMENT, (type) => (
        <AppointmentActivityDetailsModal {...getProps(type)} />
      ))
      .with(PortalAPI.ProspectAPI.ActivityDataType.LEAD_STATUS, (type) => (
        <LeadStatusActivityDetailsModal {...getProps(type)} />
      ))
      .with(
        PortalAPI.ProspectAPI.ActivityDataType.MAIL_DELIVERY,
        PortalAPI.ProspectAPI.ActivityDataType.MAIL_STARTED,
        PortalAPI.ProspectAPI.ActivityDataType.MAIL_STOPPED,
        PortalAPI.ProspectAPI.ActivityDataType.MAIL_RESTARTED,
        (type) => <MailActivityDetailsModal {...getProps(type)} />
      )
      .with(PortalAPI.ProspectAPI.ActivityDataType.NOTES, (type) => <NotesActivityDetailsModal {...getProps(type)} />)
      .with(PortalAPI.ProspectAPI.ActivityDataType.PROSPECT_CREATED, (type) => (
        <ProspectCreatedActivityDetailsModal {...getProps(type)} />
      ))
      .exhaustive() ?? <></>
  );
};
