import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Selectors } from '../../../redux/selectors';
import { deleteCompanyAssociation, loadCompanyAssociations, resendInvite } from '../../../redux/thunks';
import { useTypedDispatch } from '../../../redux/state';
import { CompanyListUI } from './company-list.ui';

export const CompanyList = () => {
  const dispatch = useTypedDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isLoaded, isLoading, children } = useSelector(Selectors.company);
  const { user } = useSelector(Selectors.user);

  useEffect(() => {
    dispatch(loadCompanyAssociations(getAccessTokenSilently));
  }, [dispatch, getAccessTokenSilently]);

  const onDeleteAssociation = useCallback(
    (id: number) => {
      dispatch(deleteCompanyAssociation(getAccessTokenSilently, id));
    },
    [dispatch, getAccessTokenSilently]
  );

  const onResendInvitation = useCallback(
    async (id: number) => {
      dispatch(resendInvite(getAccessTokenSilently, id, user?.email));
    },
    [dispatch, getAccessTokenSilently, user?.email]
  );

  return (
    <CompanyListUI
      companies={children ?? []}
      isLoaded={isLoaded}
      isLoading={isLoading}
      onDeleteAssociation={onDeleteAssociation}
      onResendInvitation={onResendInvitation}
    />
  );
};
